import React from 'react';
import { Redirect } from 'react-router-dom';
import { Form,Input,Tooltip,Cascader,Select,Row,Col,Checkbox,Button,AutoComplete, notification } from 'antd';
import { CloseSquareOutlined, SmileOutlined, FrownOutlined } from '@ant-design/icons';

import { permisos, registrar_personal } from '../../../utils/Jwt';

const { Option } = Select;

let opciones_permisos = [];

class RegistrarUsuario extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            confirmDirty: false, login: false, lista_usuarios:false
        };

        console.log(this.props);

            this.listaPermisos();

        
    }

    listaPermisos(){
        permisos().then(data => {
            switch(data.status){
                case 0:
                    opciones_permisos = data.data.map(permiso => {
                        return(
                          <Option value={permiso.id_permiso} label={permiso.tipo}>
                            <span role="img" aria-label={permiso.tipo} style={{backgroundColor:permiso.color}}>
                              🇨&nbsp;
                            </span>
                            {permiso.tipo}
                          </Option>
                        );
                      });
                      this.setState({login:false});
                break;
                case 5:
                    this.openNotification(<FrownOutlined style={{color:'red'}} />, 'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    this.openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
            }
        });
    }

    openNotification = (icono, titulo,mensaje) => {
        notification.open({
          message: titulo,
          description:
            mensaje,
          icon: icono,
        });
    };

    handleSubmit = values => {

        console.log(values);

            registrar_personal(values).then(data => {
                switch(data.status){
                    case 0:
                        this.openNotification(<FrownOutlined style={{color:'orange'}} />,'Usuario duplicado','Este usuario ya se encuentra registrado');
                    break;
                    case 1:
                        this.openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Usuario registrado','');
                        this.props.onResponse();
                    break;
                    case 5:
                        this.openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    break;
                    default:
                        this.openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
                    break;
                }
            });
      };
    
      handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
      };

    render(){

    return (

        

        <Row>
           <Col xs={{span:22, offset:1}} md={{span:20, offset:2}} >
               <p><strong>REGISTRO</strong></p>
                <Form layout='vertical' onFinish={this.handleSubmit}>
                    <Form.Item label="Correo" 
                    name={`email`}
                    rules={[{ required: true, message: 'Ingresa el correo' }]}
                    >
                    <Input />
                    </Form.Item>
                    <Form.Item label="Contraseña" 
                    name={`password`}
                    rules={[{ required: true, message: 'Ingresa una contraseña' }]}
                    hasFeedback>
                    <Input.Password />
                    </Form.Item>
                    <Form.Item 
                    label={
                        <span>
                        Nombre
                        </span>
                    }
                    name={`nombre`}
                    rules={[{ required: true, message: 'Ingresa el nombre' }]}
                    >
                    <Input />
                    </Form.Item>
                    <Form.Item 
                    label={
                        <span>
                        Apellidos
                        </span>
                    }
                    name={`apellidos`}
                    rules={[{ required: true, message: 'Ingresa el apellido' }]}
                    >
                    <Input />
                    </Form.Item>
                    <Form.Item 
                    label={
                        <span>
                        Área
                        </span>
                    }
                    name={`area`}
                    rules={[{ required: true, message: 'Ingresa el area' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Selecciona tu área"
                            allowClear={true}
                            autoClearSearchValue={true}
                        >
                            <Option value="Administración y Finanzas">Administración y Finanzas</Option>
                            <Option value="Contrataciones Públicas y Auditoría">Contrataciones Públicas y Auditoría</Option>
                            <Option value="Temas de Seguridad, Prevención y CapacitaciónCapacitación y Seguridad">Temas de Seguridad, Prevención y CapacitaciónCapacitación y Seguridad</Option>
                            <Option value="Asuntos Corporativos">Asuntos Corporativos</Option>
                            <Option value="Dirección General">Dirección General</Option>
                            <Option value="Género y Derechos Humanos">Género y Derechos Humanos</Option>
                            <Option value="Relaciones Públicas">Relaciones Públicas</Option>
                            <Option value="Asuntos  Jurídicos y Legislativos">Asuntos  Jurídicos y Legislativos</Option>
                            <Option value="Asuntos Sociales y Políticos">Asuntos Sociales y Políticos</Option>
                            <Option value="Consultor">Consultor</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item 
                    label={
                        <span>
                        Cargo
                        </span>
                    }
                    name={`cargo`}
                    rules={[{ required: true, message: 'cargo' }]}
                    ><Input />
                    </Form.Item>
                    <Form.Item  label="Permisos"
                    name={`permisos`}
                    rules={[{ required: false, message: 'Selecciona por lo menos un permiso para este usuario' }]}
                    >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Selecciona los permisos"
                            allowClear={true}
                            autoClearSearchValue={true}
                        >
                            {opciones_permisos}
                        </Select>
                    </Form.Item>
                    <Form.Item
                    label="¿Coordinador?"
                        name="encargado_area"
                        initialValue={0}
                    >
                        <Select>
                            <Option value={0}>No</Option>
                            <Option value={1}>Si</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item style={{textAlign:'right'}}>
                    <Button type="primary" htmlType="submit">
                        Registrar
                    </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>

     
    );
  
    }
}

//const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrarUsuario);
export default RegistrarUsuario;
