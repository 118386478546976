import React, { useEffect, useState } from "react";
import {Table, Popconfirm, Button, Modal, Typography, Input, Space, Drawer, Select, notification, Tooltip} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, PrinterOutlined, SmileOutlined, FrownOutlined } from '@ant-design/icons';
import RegistrarMovimientoN from '../proyectos/RegistrarMovimientoN';
import { aprobar_folio, datos_imprimir, eliminar_movimiento, eliminar_folio } from '../../../utils/Jwt';
import Solicitud from '../proceso_movimiento/Solicitud';
import Reembolso from '../proceso_movimiento/Reembolso';
import Comprobacion from '../proceso_movimiento/Comprobacion';

let img_src = '';

const { Text } = Typography;
const { Option } = Select;

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

  const infoImg = (src) =>{

    img_src = src;
    Modal.info({
      title: '',
      content: (
        <div>
          <img src={URL.createObjectURL(img_src)} style={{width:'100%'}} />
        </div>
      ),
      onOk() {},
      width:"500px"
    });
  }

const openNotification = (icono,titulo,mensaje) => {
    notification.open({
    message: titulo,
    description:
        mensaje,
    icon: icono,
    });
};


const MovimientosN = (props) => {

    const [search_text, setSearchText] = useState('');
    const [searched_column, setSearchedColumn] = useState('');
    const [columns, setColumns] = useState([]);
    const [actualizar,setActualizar] = useState(false);
    const [comprobar, setComprobar] = useState(false);
    const [imprimir, setImprimir] = useState(false);
    const [info_imprimir, setInfoImprimir] = useState(null);
    const [tipo_operacion, setTipoOperacion] = useState(0);
    const [id_actualizar, setIdActualizar] = useState(0);
    const [id_actualizar_comprobar, setIdActualizarComprobar] = useState(0);
    const [depositar, setDepositar] = useState(false);
    const [id_depositar, setIdDepositar] = useState(0);

    const {lista_movimientos, lista_submovimientos, tipo_vista_movimientos, id_proyecto, aprobar, onResponse, habilitar_depositos, editar_movimientos } = props;

    useEffect(()=>{

        switch(tipo_vista_movimientos){
            case 'Ingreso':
                setColumns([
                    {
                        title: 'Proyecto',
                        dataIndex: 'proyecto',
                        key:'proyecto',
                        className:'texto_table',
                        ...getColumnSearchProps('proyecto'),
                    },
                    {
                        title: 'Empresa',
                        dataIndex: 'empresa',
                        key: 'empresa',
                        className:'texto_table',
                        ...getColumnSearchProps('empresa'),
                    },
                    {
                        title: 'Cliente',
                        dataIndex: 'cliente',
                        key: 'cliente',
                        className:'texto_table',
                        ...getColumnSearchProps('cliente'),
                    },
                    {
                        title: 'Importe Proyecto',
                        dataIndex: 'importe_proyecto',
                        key:'importe_proyecto',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{formatter.format(text)}</span>
                            );
                        }
                    },
                    {
                        title: 'Mes',
                        dataIndex: 'mes',
                        key: 'mes',
                        className:'texto_table',
                        ...getColumnSearchProps('mes'),
                    },
                    {
                        title: 'Fecha factura',
                        dataIndex: 'fecha_factura',
                        key: 'fecha_factura',
                        className:'texto_table',
                        ...getColumnSearchProps('fecha_factura'),
                    },
                    {
                        title: '#Factura',
                        dataIndex: 'numero_factura',
                        key: 'numero_factura',
                        className:'texto_table',
                    },
                    {
                        title: 'Importe Factura',
                        dataIndex: 'cantidad',
                        key:'cantidad',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{formatter.format(text)}</span>
                            );
                        }
                    },
                    {
                        title: 'Concepto Factura',
                        dataIndex: 'concepto_factura',
                        key: 'concepto_factura',
                        className:'texto_table',
                    },
                    {
                        title: 'Fecha envio cliente',
                        dataIndex: 'fecha_envio_cliente',
                        key: 'fecha_envio_cliente',
                        className:'texto_table',
                        ...getColumnSearchProps('fecha_envio_cliente'),
                    },
                    {
                        title: 'Fecha pago',
                        dataIndex: 'fecha_pago',
                        key: 'fecha_pago',
                        className:'texto_table',
                        ...getColumnSearchProps('fecha_pago'),
                    },
                    {
                        title: 'Factura',
                        dataIndex: 'factura',
                        key: 'factura',
                        className:'texto_table',
                        ...getColumnSearchProps('factura'),
                        /*render: (text, record) => {
                            return (
                            <div>
                                {text === 'Si' ?
                                (
                                    <Button type="primary" onClick={() => infoImg(record.url_factura)}>Sí</Button>
                                )
                                :
                                <span>No</span>
                                }
                            </div>
                                
                            );
                        },*/
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        className:'texto_table',
                        ...getColumnSearchProps('status'),
                        render: (text, record) => {
                            return (
                            <div>
                                {(record.status === 'Aprobado') ? <Text type="primary">{text}</Text>
                                : <Text type="danger">{text}</Text> }
                            </div>
                                
                            );
                        },
                    },
                    {
                        title: '...',
                        dataIndex: 'operation',
                        className:'texto_table',
                        render: (text, record) => {
                        
                        return (
                            <span>
                                <a onClick={()=>{setTipoOperacion(1); setIdActualizar(record.id_movimiento);}}>
                                    Editar
                                </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <Popconfirm title="¿Quieres eliminar éste ingreso?" onConfirm={() => eliminarMovimiento('movimiento',record.id_movimiento)} okText="Sí" cancelText="No" oktype="danger">
                                <a>
                                    Eliminar
                                </a>
                                </Popconfirm>
                            </span>
                        );
                    },
                },
                ]);
                break;
            case 'Folio':
                setColumns([
                    {
                        title: 'Folio',
                        dataIndex: 'id_nota',
                        key:'id_nota',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                                <span>{100000+record.id_nota}</span>
                            );
                        }
                    },
                    {
                        title: 'Tipo',
                        dataIndex: 'tipo_nota',
                        key:'tipo_nota',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: 'F. inicio',
                        dataIndex: 'fecha_inicio',
                        key:'fecha_inicio',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: 'F. fin',
                        dataIndex: 'fecha_fin',
                        key:'fecha_fin',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                                <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: 'Solicitado',
                        dataIndex: 'costo',
                        key:'costo',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>
                                {record.tipo_nota === 'Reembolso' ? '' : formatter.format(text)}
                            </span>
                            );
                        }
                    },
                    {
                        title: 'Depositado',
                        dataIndex: 'depositado',
                        key:'depositado',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>
                                {formatter.format(text)}
                            </span>
                            );
                        }
                    },
                    {
                        title: 'Comprobado',
                        dataIndex: 'comprobado',
                        key:'comprobado',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{formatter.format(text)}</span>
                            );
                        }
                    },
                    {
                        title: 'Rei/Dev',
                        dataIndex: 'tipo_resto',
                        key:'tipo_resto',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                                <>
                                {formatter.format(record.resto)}
                                <br />
                                {record.status_nota !== 'Cancelado' &&
                                    (record.resto !== 0 && (record.status_nota === 'Aprobado' ? <Text>{text}</Text> : text === 'Reintegro'  ? <Text type="danger" style={{color:'red'}}>{text}</Text> : <Text type="success">{text}</Text>)
                                )}
                                </>
                            );
                        }
                    },
                    {
                        title: 'Empresa',
                        dataIndex: 'empresa',
                        key:'empresa',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: 'Proyecto',
                        dataIndex: 'proyecto',
                        key:'proyecto',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: 'Personal',
                        dataIndex: 'personal',
                        key:'personal',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{record.nombre+' '+record.apellidos}</span>
                            );
                        }
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status_nota',
                        key:'status_nota',
                        className:'texto_table',
                        render: (text, record) => {
                            return (
                                
                                <div >
                                    {(text === 'Aprobado') ? <Text type="primary">{text}</Text>
                                    : (text === 'Comprobado') ? <Text type="warning">{text}</Text>
                                    : <Text type="danger">{text}</Text>}
                                </div>
                                );
                        }
                    },
                    {
                        title: '...',
                        dataIndex: 'operation',
                        className:'texto_table',
                        render: (text, record) => {
                        
                            return (
                                <div style={{zIndex:99, cursor:'pointer'}}>{
                                ((record.status_nota !== 'Aprobado' && record.status_nota !== 'Cancelado')) &&
                                <span>
                                    {(((record.tipo_nota === 'Solicitud' && record.status_nota === 'Comprobado') ||
                                        record.tipo_nota === 'Reembolso')) && aprobar &&
                                        <>
                                            <Popconfirm
                                                title="¿Quieres aprobar éste folio?"
                                                onConfirm={()=>{cambioStatus(record.id_nota)}}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                            <a>
                                                Aprobar
                                            </a>
                                            </Popconfirm>&nbsp;&nbsp;|&nbsp;&nbsp;
                                        </>

                                    }
                                    {record.status_nota === 'Pendiente' &&
                                        <>
                                        <a alt="" onClick={()=>{setTipoOperacion(record.tipo_nota === 'Solicitud' ? 2 : 3); setIdActualizar(record.id_nota);}}>
                                            Editar
                                        </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                        </>
                                    }
                                    {record.tipo_nota === 'Solicitud' &&
                                        <>
                                            <a alt="" onClick={()=>{setTipoOperacion(2); setIdActualizarComprobar(record.id_nota);}}>
                                                {record.status === 'Comprobado' ? 'Editar' : 'Comprobar'}
                                            </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                        </>
                                    }
                                    {habilitar_depositos &&
                                        <>
                                            <a alt="" onClick={()=>{setTipoOperacion(record.tipo_nota === 'Solicitud' ? 2 : 3); setIdDepositar(record.id_nota);}}>
                                                Depositar
                                            </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                        </>
                                    }
                                    {editar_movimientos &&
                                        <Popconfirm title="¿Quieres cancelar este folio?" onConfirm={() => eliminarMovimiento('folio',record.id_nota)} okText="Sí" cancelText="No" oktype="danger">
                                        <a>
                                            Cancelar
                                        </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                        </Popconfirm>
                                    }
                                    
                                    
                                </span>
                                }

                                {record.status_nota === 'Cancelado' && editar_movimientos &&(
                                    <Popconfirm title="¿Quieres habilitar este folio?" onConfirm={() => eliminarMovimiento('folio',record.id_nota)} okText="Sí" cancelText="No" oktype="danger">
                                        <a>
                                            Habilitar
                                        </a>
                                    </Popconfirm>
                                    )
                                }

                                {record.status_nota !== 'Cancelado' &&
                                        <Tooltip title="Imprimir folio">
                                        <PrinterOutlined style={{color:'blue', cursor:'pointer'}} onClick={()=>{imprimirDocumento(record.id_nota)}} />
                                    </Tooltip>
                                    }
                                
                                </div>
                            );
                        },
                    },
                ]);
                break;
            case 'Proyectado':
                setColumns([
                    {
                        title: 'Proyecto',
                        dataIndex: 'proyecto',
                        className:'texto_table',
                        render: (text, record) => {
                        
                            return (
                                <span>
                                    {text}
                                </span>
                            );
                        },
                    },
                    {
                        title: 'Objetivo',
                        dataIndex: 'objetivo_especifico',
                        key:'objetivo_especifico',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: 'Acción',
                        dataIndex: 'accion',
                        key:'accion',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: 'Importe',
                        dataIndex: 'cantidad',
                        key:'cantidad',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{formatter.format(text)}</span>
                            );
                        }
                    },
                    {
                        title: 'Concepto',
                        dataIndex: 'concepto',
                        key:'concepto',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: 'Observaciones',
                        dataIndex: 'observaciones',
                        key:'observaciones',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: '...',
                        dataIndex: 'operation',
                        className:'texto_table',
                        render: (text, record) => {
                        
                        return (
                            <span>
                                <a alt="" onClick={()=>{setTipoOperacion(4); setIdActualizar(record.id_movimiento);}}>
                                    Editar
                                </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <Popconfirm title="¿Quieres eliminar esta proyección?" onConfirm={() => eliminarMovimiento('movimiento',record.id_movimiento)} okText="Sí" cancelText="No" oktype="danger">
                                <a>
                                    Eliminar
                                </a>
                                </Popconfirm>
                            </span>
                        );
                    },
                }
                    
                ]);
            break;
            default:
                setColumns([
                    {
                        title: 'Folio',
                        dataIndex: 'id_nota',
                        key:'id_nota',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{text}</span>
                            );
                        }
                    },
                    {
                        title: 'Importe',
                        dataIndex: 'cantidad',
                        key:'cantidad',
                        className:'texto_table',
                        render: (text, record) => {
                            return(
                            <span>{formatter.format(text)}</span>
                            );
                        }
                    },
                    {
                        title: 'Factura',
                        dataIndex: 'factura',
                        key: 'factura',
                        className:'texto_table',
                        ...getColumnSearchProps('factura'),
                        render: (text, record) => {
                            return (
                            <div>
                                {text === 'Si' ?
                                (
                                    <Button type="primary" onClick={() => infoImg(record.url_factura)}>Sí</Button>
                                )
                                :
                                <span>No</span>
                                }
                            </div>
                                
                            );
                        },
                    },
                    {
                        title: 'Ticket',
                        dataIndex: 'ticket',
                        key: 'ticket',
                        className:'texto_table',
                        ...getColumnSearchProps('ticket'),
                        render: (text, record) => {
                            return (
                            <div>
                                {text === 'Si' ?
                                (
                                    <Button type="primary" onClick={() => infoImg(record.url_ticket)}>Sí</Button>
                                )
                                :
                                <span>No</span>
                                }
                            </div>
                                
                            );
                        },
                    },
                    {
                        title: 'Operación',
                        dataIndex: 'especificar_tipo_operacion',
                        key: 'especificar_tipo_operacion',
                        className:'texto_table',
                        ...getColumnSearchProps('especificar_tipo_operacion'),
                    },
                    {
                        title: 'Concepto',
                        dataIndex: 'concepto',
                        key: 'concepto',
                        className:'texto_table',
                        ...getColumnSearchProps('concepto'),
                    },
                    {
                        title: 'F. de Pago',
                        dataIndex: 'forma_de_pago',
                        key: 'forma_de_pago',
                        className:'texto_table',
                        ...getColumnSearchProps('forma_de_pago'),
                    },
                    {
                        title: 'Empresa',
                        dataIndex: 'empresa',
                        key: 'empresa',
                        className:'texto_table',
                        ...getColumnSearchProps('empresa'),
                    },
                    {
                        title: 'Personal',
                        dataIndex: 'nombre',
                        key: 'nombre',
                        className:'texto_table',
                        ...getColumnSearchProps('nombre'),
                        render: (text, record) => {
                            return (
                            <div>
                                {record.nombre} {record.apellidos}
                            </div>
                                
                            );
                        },
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        className:'texto_table',
                        ...getColumnSearchProps('status'),
                        render: (text, record) => {
                            return (
                            <div >
                                {(record.status === 'Aprobado') ? <Text type="primary">{text}</Text>
                                : (record.status === 'Comprobar') ? <Text type="warning">{text}</Text>
                                : <Text type="danger">{text}</Text>}
                            </div>
                                
                            );
                        },
                    },
                    {
                        title: '...',
                        dataIndex: 'operation',
                        className:'texto_table',
                        render: (text, record) => {
                        
                        return (
                            <span>
                                <a alt="" onClick={()=>{setTipoOperacion(3); setIdActualizar(record.id_nota);}}>
                                    Editar
                                </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <Popconfirm title="¿Quieres cancelar éste movimiento?" onConfirm={() => eliminarMovimiento('movimiento',record.id_movimiento)} okText="Sí" cancelText="No" oktype="danger">
                                <a>
                                    Cancelar
                                </a>
                                </Popconfirm>
                            </span>
                        );
                    },
                },
                ]);
                break;
        }

    },[tipo_vista_movimientos]);

    useEffect(()=>{
        if(tipo_operacion !== 0 && id_actualizar !== 0){
            setActualizar(true);
        }else{
            setActualizar(false);
        }

        if(tipo_operacion !== 0 && id_actualizar_comprobar !== 0){
            setComprobar(true);
        }else{
            setComprobar(false);
        }

        if(tipo_operacion !== 0 && id_depositar !== 0){
            setDepositar(true);
        }else{
            setDepositar(false);
        }

    },[tipo_operacion,id_actualizar, id_actualizar_comprobar, id_depositar]);

    useEffect(()=>{

        if(!actualizar && !comprobar){
            if(!actualizar){
                setTipoOperacion(0);
                setIdActualizar(0);
            }
    
            if(!comprobar){
                setTipoOperacion(0);
                setIdActualizarComprobar(0);
            }
        }

    },[actualizar, comprobar]);

    useEffect(()=>{
        
        if(info_imprimir) setImprimir(true);
    },[info_imprimir]);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                //this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Limpiar
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                    setSearchText(selectedKeys[0]);
                    setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          searched_column === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[search_text]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
    });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const cambioStatus = (id) => {
        aprobar_folio({id_nota: id}).then(({status, data}) => {
            switch(status){
                case 0:
                    openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Folio aprobado','');
                    onResponse(tipo_vista_movimientos);
                break;
                default:
                    openNotification(<SmileOutlined style={{color:'red'}} />,'Error de conexión','Inténtalo más tarde');
                break;
            }
        });
    }

    const onResponseRegistro = (response)=>{
        setIdDepositar(0);
        setComprobar(false);
        setActualizar(false);
        setDepositar(false);
        onResponse(response);
    }

    const imprimirDocumento = (id) => {

        datos_imprimir({id:id}).then(({data, status}) => {
            switch(status){
                case 0:
                    const nota = data[0];
                    switch(nota.status_nota){
                        case 'Pendiente':
                            if(nota.tipo_nota === 'Solicitud'){
                                setInfoImprimir({
                                    tipo: 'Solicitud',
                                    nota: nota,
                                    solicitudes: data[1]
                                });
                            }else{
                                setInfoImprimir({
                                    tipo: 'Reembolso',
                                    nota: nota,
                                    reembolsos: data[2]
                                });
                            }
                        break;
                        case 'Comprobado': case 'Aprobado':
                            if(nota.tipo_nota === 'Solicitud'){
                                setInfoImprimir({
                                    tipo: 'Comprobacion',
                                    nota: nota,
                                    solicitudes: data[1],
                                    comprobaciones: data[3],
                                    depositos: data[4]
                                });
                            }else{
                                setInfoImprimir({
                                    tipo: 'Reembolso',
                                    nota: nota,
                                    reembolsos: data[2]
                                });
                            }
                        break;
                        default:
                        break;
                    }
                break;
                default:
                    break;
            }
        });
    }

    const eliminarMovimiento = (tipo, id)=>{
        if(tipo === 'movimiento'){
            eliminar_movimiento(id).then(({data,status}) => {
                switch(status){
                    case 0:
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Movimiento eliminado','');
                        onResponse(tipo_vista_movimientos);
                    break;
                    default:
                        openNotification(<SmileOutlined style={{color:'red'}} />,'Error de conexión','Inténtalo más tarde');
                    break;
                }
            });
        }else{
            eliminar_folio(id).then(({data,status}) => {
                switch(status){
                    case 0:
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Folio cancelado','');
                        onResponse(tipo_vista_movimientos);
                    break;
                    case 1:
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Folio habilitado','');
                        onResponse(tipo_vista_movimientos);
                    break;
                    default:
                        openNotification(<SmileOutlined style={{color:'red'}} />,'Error de conexión','Inténtalo más tarde');
                    break;
                }
            });
        }
    }

    const expandedRowRenderComprobaciones = (record) => {

        const columnas_comprobaciones = [
            { title: 'Fecha', dataIndex: 'fecha', key: 'fecha' },
            { title: 'Importe', dataIndex: 'cantidad', key: 'cantidad', render: (text, record) => {
                return(
                <span>
                    {formatter.format(text)}
                </span>
                );
            } },
            { title: 'Observaciones', dataIndex: 'observaciones', key: 'observaciones' }
        ];

        const movimientos_reembolsos = lista_submovimientos.filter(movimiento_reem => movimiento_reem.id_nota === record.id_nota && movimiento_reem.tipo_operacion === 'EGRESO' && movimiento_reem.concepto === record.concepto);

        return <Table columns={columnas_comprobaciones} dataSource={movimientos_reembolsos} pagination={false} />;
    }

    const expandedRowRenderComprobacionesSolicitudes = (record) => {

        const columnas_comprobaciones = [
            { title: 'Fecha', dataIndex: 'fecha', key: 'fecha' },
            { title: 'Importe', dataIndex: 'cantidad', key: 'cantidad', render: (text, record) => {
                return(
                <span>
                    {formatter.format(text)}
                </span>
                );
            } },
            { title: 'Observaciones', dataIndex: 'observaciones', key: 'observaciones' }
        ];

        const movimientos_reembolsos = lista_submovimientos.filter(movimiento_reem => movimiento_reem.movimiento_padre_id === record.id_movimiento);

        return <Table columns={columnas_comprobaciones} dataSource={movimientos_reembolsos} pagination={false} />;
    }

    const expandedRowRender = (record) => {

        let nuevos_conceptos = []; 

        if(record.tipo_nota === 'Reembolso'){

            const columnas = [
                { title: 'Concepto', dataIndex: 'concepto', key: 'concepto' },
                { title: 'Comprobado por concepto', dataIndex: 'total', key: 'total', render: (text, record) => {
                    return(
                    <span>
                        {formatter.format(text)}
                    </span>
                    );
                } },
            ];

            lista_submovimientos.filter(movimiento => movimiento.id_nota === record.id_nota && movimiento.tipo_operacion === 'EGRESO').map(movimiento => {
                let existe_concepto = false;    
                for(let i=0; i < nuevos_conceptos.length; i++){
                    if(nuevos_conceptos[i].concepto === movimiento.concepto){
                        existe_concepto = true;
                        break;
                    }
                }

                if(!existe_concepto && movimiento.concepto !== 'Depositos'){
                    let suma_total;

                    let submovimientos_sumar = lista_submovimientos.filter(movimiento_reem => 
                        movimiento_reem.id_nota === record.id_nota && 
                        movimiento_reem.concepto === movimiento.concepto && 
                        movimiento_reem.tipo_operacion === 'EGRESO');

                    if(submovimientos_sumar.length > 1){
                        suma_total = submovimientos_sumar.reduce((a, b) => a + b.cantidad,0);
                    }else{
                        suma_total = submovimientos_sumar[0].cantidad;
                    }
                    
                    nuevos_conceptos.push({key:nuevos_conceptos.length, concepto: movimiento.concepto, total: suma_total, id_nota: movimiento.id_nota });
                }

            });

            return(
                <Table size="middle" 
                    columns={columnas} dataSource={nuevos_conceptos} 
                    expandable={{
                        expandedRowRender: expandedRowRenderComprobaciones
                    }} />
            );

        }else{

            const columnas = [
                { title: 'Concepto', dataIndex: 'concepto', key: 'concepto' },
                { title: 'Solicitado por concepto', dataIndex: 'cantidad', key: 'cantidad', render: (text, record) => {
                    return(
                    <span>
                        {formatter.format(text)}
                    </span>
                    );
                } },
                { title: 'Comprobado por concepto', dataIndex: 'total', key: 'total', render: (text, record) => {
                    return(
                    <span>
                        {formatter.format(text)}
                    </span>
                    );
                } },
            ];
            
            nuevos_conceptos = lista_submovimientos.filter(movimiento => movimiento.id_nota === record.id_nota && movimiento.tipo_operacion === 'SOLICITUD').map(movimiento => {

                let total = lista_submovimientos.filter(movimiento_reem => 
                    movimiento_reem.id_nota === record.id_nota && 
                    movimiento_reem.movimiento_padre_id === movimiento.id_movimiento && 
                    (movimiento_reem.tipo_operacion === 'EGRESO' || (movimiento_reem.tipo_operacion === 'INGRESO' && movimiento_reem.especificar_tipo_operacion === 'Deposito')));
                switch(total.length){
                    case 0: total = 0; break;
                    case 1: total = total[0].cantidad; break;
                    default: total = total.reduce((a, b) => a + b.cantidad,0); break;
                }

                movimiento.total = total;

                return movimiento;

            }).filter(movimiento => movimiento.cantidad !== 0 || movimiento.total !== 0);

            return(
                <Table size="middle" 
                    columns={columnas} dataSource={nuevos_conceptos} 
                    expandable={{
                        expandedRowRender: expandedRowRenderComprobacionesSolicitudes
                    }} />
            );

        }

    };

    return(
        <>
        <Table size="middle" columns={columns} dataSource={lista_movimientos} expandable={lista_submovimientos.length > 0 ? { expandedRowRender }: null} />
        <Drawer
          title="Editar"
          placement="right"
          closable={true}
          onClose={()=>setActualizar(false)}
          visible={actualizar}
          width='600'
        >
            {actualizar &&
                <RegistrarMovimientoN onResponse={onResponseRegistro} id_proyecto={id_proyecto} ingreso={false} proyectado={false} comprobar={false} actualizar_operacion={tipo_operacion} id_actualizar={id_actualizar} hacer_deposito={false} />
            }
          
        </Drawer>
        <Drawer
          title="Comprobar"
          placement="right"
          closable={true}
          onClose={()=>{setComprobar(false)}}
          visible={comprobar}
          width='600'
        >
            {comprobar &&
                <RegistrarMovimientoN onResponse={onResponseRegistro} id_proyecto={id_proyecto} ingreso={false} proyectado={false} comprobar={true} actualizar_operacion={5} id_actualizar={id_actualizar_comprobar} hacer_deposito={false} />
            }
        </Drawer>

        <Drawer
          title="Depositar"
          placement="right"
          closable={true}
          onClose={()=>{setDepositar(false)}}
          visible={depositar}
          width='600'
        >
            {depositar &&
                <RegistrarMovimientoN onResponse={onResponseRegistro} id_proyecto={id_proyecto} ingreso={false} proyectado={false} comprobar={true} actualizar_operacion={tipo_operacion} id_actualizar={id_depositar} hacer_deposito={true} />
            }
        </Drawer>

        <Drawer
          title={imprimir && info_imprimir.tipo}
          placement="right"
          closable={true}
          onClose={()=>{setImprimir(false)}}
          visible={imprimir}
          width='600'
        >
            {imprimir && 
                (
                    info_imprimir.tipo === 'Solicitud' ?
                        <Solicitud datos={info_imprimir} />
                    :
                    info_imprimir.tipo === 'Reembolso' ?
                        <Reembolso datos={info_imprimir} />
                    :
                        <Comprobacion datos={info_imprimir} />
                )
            }
        </Drawer>
        </>
    );
}

export default MovimientosN;
