import React from "react";
import ReactExport from "react-export-excel";
import { Form, Input, Radio, Tooltip, notification, Cascader, Select, Row, Col, Checkbox, Button, Table, DatePicker } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let componente_excel;

class Excel extends React.Component {

    constructor(props){
        super(props);
        

        this.crearColumnasExcel(this.props['columnas']);

        this.state = {
            nombre: this.props['nombre'],
            datos: this.props['datos']
        }
    }

    crearColumnasExcel(columnas){
        componente_excel = columnas.map(columna => {
            return(
                <ExcelColumn label={columna.title} value={columna.key}/>
            );
        });
    }

    componentWillReceiveProps(nextProps){

        this.crearColumnasExcel(nextProps['columnas']);

        this.setState({
            datos: nextProps['datos'],
            nombre: nextProps['nombre']
        });
    }

    render() {

        return (
            <ExcelFile element={<Tooltip title="Descargar Excel"><Button><FileExcelOutlined  alt="Exportar Excel" style={{color:'green'}} /></Button></Tooltip>}>
                <ExcelSheet data={this.state.datos} name={this.state.nombre}>
                    {componente_excel}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default Excel;
