import React from 'react';
import {Row, Col, Select, Card, Button, Popconfirm, notification, Modal, Input } from 'antd';
import { catalogos, agregar_al_catalogo, borrar_al_catalogo } from '..//../../utils/Jwt';
import { Redirect } from 'react-router-dom';
import { CloseCircleOutlined, PlusCircleOutlined, SmileOutlined ,FrownOutlined} from '@ant-design/icons';

const { Option } = Select;

let tipo_operaciones = [], conceptos = [], gastos_concepto = [], catalogo_gastos_concepto = [], formas_de_pago = [], proyectos= [], empresas = [], catalogo;
let permiso_catalogos = false;
class ListaCatalogo extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            login:false,
            catalogos:false,
            modal_agregar_al_catalogo:false,
            id_concepto_actual: 0,
            nombre_concepto_actual: ''
        };

        permiso_catalogos = false;

        this.valor_catalogo = React.createRef();

        for(let i=0; i < this.props.permisos_usuario.length; i++){
            if(this.props.permisos_usuario[i].id_permiso === 12){
                permiso_catalogos = true;
                break;
            }
        }

        if(permiso_catalogos){
            this.listaCatalogos();
        }
    }

    showModal = () => {
        this.setState({
            modal_agregar_al_catalogo: true,
        });
    };

    openNotification = (icono,color_icono, titulo,mensaje) => {
        notification.open({
        message: titulo,
        description:
            mensaje,
        icon: icono,
        });
    };
    
    handleOk = e => {
        console.log(e);

        if(this.valor_catalogo.current.state.value !== '' || this.valor_catalogo.current.state.value !== ' '){
            let request = {
                catalogo: catalogo,
                valor: this.valor_catalogo.current.state.value,
                concepto_id: this.state.id_concepto_actual
            }

            this.setState({catalogo:false});

            console.log(request);

            agregar_al_catalogo(request).then(data => {
                switch(data.status){
                    case 0:
                        this.openNotification(<SmileOutlined />,'#73fc03','Valor Agregado','');
                        this.listaCatalogos();
                    break;
                    case 5:
                        this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                        this.setState({login:true});
                    break;
                    default:
                        this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
                        this.setState({login:true});
                    break;
                }
            });

        }
        
        this.setState({
            modal_agregar_al_catalogo: false,
        });
    };
    
    handleCancel = e => {
        console.log(e);
        this.setState({
          modal_agregar_al_catalogo: false,
        });
    };

    listaCatalogos(){
        catalogos().then(data => {

            console.log('catalogos: ',data.data);

            switch(data.status){
                case 0:
                    tipo_operaciones = data.data[0].map(operacion => {
                        if(operacion.cancelar === 1){
                            
                        }
                        return(
                            <Popconfirm title="¿Quieres cancelar éste valor?" onConfirm={() => this.borrar_del_catalogo('tipo_operacion',operacion.id_tipo_movimiento)} okText="Sí" cancelText="No" oktype="danger">
                                <p>{operacion.tipo} <CloseCircleOutlined type="close-circle" style={{color:'red'}}/></p>
                            </Popconfirm>
                            
                        );
                    });
                    let id_concepto = true;
                    conceptos = data.data[1].map(concepto => {
                        if(id_concepto){
                            this.setState({ id_concepto_actual: concepto.id_concepto, nombre_concepto_actual:concepto.nombre});
                            id_concepto = false;
                        }
                        return(
                            
                            <p>
                                <span onClick={() => this.cambiarGastosConcepto(concepto.id_concepto,concepto.nombre)}>{concepto.nombre} </span>
                                <Popconfirm title="¿Quieres cancelar éste valor?" onConfirm={() => this.borrar_del_catalogo('concepto',concepto.id_concepto)} okText="Sí" cancelText="No" oktype="danger">
                                    &nbsp;<CloseCircleOutlined  style={{color:'red'}}/>
                                </Popconfirm>
                            </p>
                            
                        );
                    });
                    catalogo_gastos_concepto = data.data[2];
                    gastos_concepto = catalogo_gastos_concepto.map(gasto => {
                        if(this.state.id_concepto_actual == gasto.concepto_id){
                            return(
                                <Popconfirm title="¿Quieres cancelar éste valor?" onConfirm={() => this.borrar_del_catalogo('gasto_concepto',gasto.id_gasto_concepto)} okText="Sí" cancelText="No" oktype="danger">
                                <p>{gasto.nombre} <CloseCircleOutlined  style={{color:'red'}}/></p>
                                </Popconfirm>
                            );
                        }
                        
                    });
                    formas_de_pago = data.data[3].map(forma => {
                        return(
                            <Popconfirm title="¿Quieres cancelar éste valor?" onConfirm={() => this.borrar_del_catalogo('forma_de_pago',forma.id_forma_de_pago)} okText="Sí" cancelText="No" oktype="danger">
                            <p>{forma.tipo} <CloseCircleOutlined  style={{color:'red'}}/></p>
                            </Popconfirm>
                        );
                    });
                    proyectos = data.data[4].map(proyecto => {
                        return(
                            <Popconfirm title="¿Quieres cancelar éste valor?" onConfirm={() => this.borrar_del_catalogo('proyecto',proyecto.id_proyecto)} okText="Sí" cancelText="No" oktype="danger">
                            <p>{proyecto.nombre} <CloseCircleOutlined  style={{color:'red'}}/></p>
                            </Popconfirm>
                        );
                    });;
                    empresas = data.data[5].map(empresa => {
                        return(
                            <Popconfirm title="¿Quieres cancelar éste valor?" onConfirm={() => this.borrar_del_catalogo('empresa',empresa.id_empresa)} okText="Sí" cancelText="No" oktype="danger">
                            <p>{empresa.nombre} <CloseCircleOutlined  style={{color:'red'}}/></p>
                            </Popconfirm>
                        );
                    });

                    this.setState({
                        catalogos:true
                    });
                break;
                default:
                break;
            }
        });
    }

    cambiarGastosConcepto(id, concepto){

        gastos_concepto = catalogo_gastos_concepto.map(gasto => {
            if(id === gasto.concepto_id){
                return(
                    <Popconfirm title="¿Quieres cancelar éste valor?" onConfirm={() => this.borrar_del_catalogo('gasto_concepto',gasto.id_gasto_concepto)} okText="Sí" cancelText="No" oktype="danger">
                    <p>{gasto.nombre} <CloseCircleOutlined  style={{color:'red'}}/></p>
                    </Popconfirm>
                );
            }
            
        });

        this.setState({
            id_concepto_actual:id,
            nombre_concepto_actual: concepto
        });
    }

    borrar_del_catalogo(catalogo,id){
        console.log(id);

        let request = {
            catalogo: catalogo,
            id, id
        }

        borrar_al_catalogo(request).then(data => {
            switch(data.status){
                case 0:
                    this.openNotification(<SmileOutlined />,'#73fc03','Valor cancelado','');
                    this.listaCatalogos();
                break;
                case 5:
                    this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
            }
        });
    }

    agregarACatalogo(catalogo_nombre){
        console.log(catalogo_nombre);
        catalogo = catalogo_nombre;

        this.setState({modal_agregar_al_catalogo:true});
        
    }

    render(){

        if(!permiso_catalogos){
            return(
                <Redirect to="/usuario" />
            );
        }


        return(
            <Row>
                <Col span={20} offset={2}>
                    <p><strong>CATÁLOGOS</strong></p>
                </Col>
                <Col span={6} offset={2}>
                    <Card title="Forma de Pago"extra={<Button type="primary" shape="circle" icon={<PlusCircleOutlined />} size='small' onClick={() => this.agregarACatalogo('forma_de_pago')} />}>
                        {formas_de_pago}
                    </Card>
                </Col>
                <Col span={6} offset={1}>
                    <Card title="Concepto" extra={<Button type="primary" shape="circle" icon={<PlusCircleOutlined />} size='small' onClick={() => this.agregarACatalogo('concepto')} />} style={{ width: 300 }}>
                        {conceptos}
                    </Card>
                </Col>
                <Col span={6} offset={1}>
                    <Card title="Empresa" extra={<Button type="primary" shape="circle" icon={<PlusCircleOutlined />} size='small' onClick={() => this.agregarACatalogo('empresa')} />} >
                        {empresas}
                    </Card>
                </Col>
                <Col span={24}>
                    <br />
                </Col>
                
                <Modal
                    title="Agregar nuevo valor"
                    visible={this.state.modal_agregar_al_catalogo}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    >
                    <Input ref={this.valor_catalogo} />
                </Modal>
            </Row>
        );
    }
}

export default ListaCatalogo;