import React from 'react';
import CanvasJSReact from '../../../../assets/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Linear extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            datos : []
        }
    }

    componentWillReceiveProps(nextProps){
        
        let datos_tabla = [];

        let columna = '';
        switch(nextProps['operacion']){
            case '0':
                columna = 'ingreso';
                break;
            case '1':
                columna = 'egreso';
                break;
            case '2':
                columna = 'ingreso-egreso';
                break;
                case '3':
                columna = 'proyectado';
                break;
            case '4':
                columna = 'ingreso-proyectado';
                break;
            case '5':
                columna = 'proyectado-egreso';
                break;
            default:
                break;
        }

        let fechas = this.fechasPeriodo(
            parseInt(nextProps['periodo'][0].split('/')[1]),parseInt(nextProps['periodo'][0].split('/')[2]),
            parseInt(nextProps['periodo'][1].split('/')[1]),parseInt(nextProps['periodo'][1].split('/')[2]),
            nextProps['periodo'][2]
            )            

            for(let i=0; i < nextProps.datos.length; i++){
                let datos_grafica = [];
                let num_registro_por_tabla = 0;
                let encontrado = false;
                for(let j=0; j < fechas.length; j++){
                    encontrado = false;
                    if(num_registro_por_tabla < nextProps.datos[i].length)
                    for(let k=0; k < nextProps.datos[i].length; k++){
                        if(fechas[j][0] === nextProps.datos[i][k]['mes'] && fechas[j][1] === nextProps.datos[i][k]['anio']){
                            datos_grafica.push(
                                { x: new Date(Date.UTC(fechas[j][1],fechas[j][0]-1,1)), y: nextProps.datos[i][k][columna] }
                            );
                            encontrado = true;
                            num_registro_por_tabla++;
                            break;
                        }
                    }

                    if(!encontrado){
                        datos_grafica.push(
                            { x: new Date(Date.UTC(fechas[j][1],fechas[j][0]-1,1)), y: 0 }
                        );
                    }
                }

                datos_tabla.push(
                    {
                        type: "line",
                        color: nextProps['colores'][i],
                        dataPoints: datos_grafica
                    }
                );


            }

            let options = {
                title: {
                    text: nextProps['nombre']
                },
                data:datos_tabla
            }
        

            this.setState({
                options: options
            });

    }

    fechasPeriodo(mes_inicio, anio_inicio, mes_final, anio_final, periodo){

        let fechas_periodo = [];
        let mes = mes_inicio, anio = anio_inicio;
        let ciclo = true;
        do{

            for(let i=0; i < (periodo - 1); i++ ){
                if(mes === 12){
                    anio++;
                    mes = 1;
                }else{
                    mes++;
                }
            }

            fechas_periodo.push([mes,anio]);

            if(anio === anio_final && mes >= mes_final){
                ciclo = false;
            }else{
                if(anio > anio_final){
                    ciclo = false;
                }
            }

            if(mes === 12){
                anio++;
                mes = 1;
            }else{
                mes++;
            }

            if(anio === 2022){
                ciclo = false;
            }

        }while(ciclo);

        return fechas_periodo;
    }

    render(){
        
        return(

            <div>
                <CanvasJSChart options={this.state.options} />
            </div>
        );

    }
}

export default Linear;
