import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import { BrowserRouter, Route } from 'react-router-dom';

import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { CerrarSesion } from './components/CerrarSesion';
import RegistrarMovimientoN from './components/usuario/proyectos/RegistrarMovimientoN';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/" component={Login} />
        <Route exact path="/prueba/:id?/:comprobar?" component={RegistrarMovimientoN} />
        <Route path="/usuario" component={Dashboard} />
        <Route path="/cerrar-sesion" component={CerrarSesion} />
      </BrowserRouter>
    </div>
  );
}

export default App;
