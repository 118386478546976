import React from 'react';
import { Row, Col, Table, Input, InputNumber, Popconfirm, Form, notification, Button, Select, Drawer, Typography, Statistic, Upload, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { Redirect } from 'react-router-dom';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';

import { SERVER_URL, catalogos, editar_movimiento, movimiento, eliminar_movimiento } from '../../../utils/Jwt';

import '../../../css/lista_movimientos.css';

import Solicitud from '../proceso_movimiento/Solicitud';
import Comprobacion from '../proceso_movimiento/Comprobacion';
import Reembolso from '../proceso_movimiento/Reembolso';
import Completo from '../proceso_movimiento/Completo';

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;



let url_ticket = '', url_factura = '';
let img_src = '';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});


let tipo_operaciones = [], conceptos = [], gastos_concepto = [], formas_de_pago = [], proyectos= [], empresas = [], id_movimiento, deshabilitar_gastos_concepto = [], acciones = [];


const props_ticket = {
    name: 'ticket',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },

    transformFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = function() {
                
                resolve(fr.result);
                var arr = fr.result.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }

                url_ticket = fr.result;
                return new Blob([u8arr], {type:mime});
            }
            fr.readAsDataURL(file);
        });
    }
  };

  const props_factura = {
    name: 'factura',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },

    transformFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = function() {
                
                resolve(fr.result);
                var arr = fr.result.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }

                url_factura = fr.result;
                return new Blob([u8arr], {type:mime});
            }
            fr.readAsDataURL(file);
        });
    }
  };

  function infoImg(src) {

    img_src = src;
    Modal.info({
      title: '',
      content: (
        <div>
          <img src={img_src} style={{width:'100%'}} />
        </div>
      ),
      onOk() {},
      width:"500px"
    });
  }

class MovimientosProyecto extends React.PureComponent{

    constructor(props){
        super(props);

        this.state = { 
            data: [],
            login: false, 
            searchText: '',
            searchedColumn: '',
            drawer_editar_movimiento: false,
            movimiento_editar: {},
            habilitar_gasto_concepto:false,
            existe_ticket: false,
            existe_factura: false,
            visual_imagen: false,
            modal_proceso_movimiento: false,
            datos_proceso_movimiento: null
        }

        this.listaCatalogos();
        
        this.handleChangeConcepto = this.handleChangeConcepto.bind(this);
        this.listaCatalogos = this.listaCatalogos.bind(this);
        this.listaMovimiento = this.listaMovimiento.bind(this);
        this.handleChangeFactura = this.handleChangeFactura.bind(this);
        this.handleChangeTicket = this.handleChangeTicket.bind(this);
        this.showModalImg = this.showModalImg.bind(this);
        this.handleOkImg = this.handleOkImg.bind(this);
        this.handleCancelImg = this.handleCancelImg.bind(this);
        this.mostrarProcesoMovimiento = this.mostrarProcesoMovimiento.bind(this);
    }

    onClose = () => {
        this.setState({
          drawer_editar_movimiento: false,
        });
    };

    hide = () => {
        this.setState({
          visual_imagen: false,
        });
      };
    
      handleVisibleChange = visual_imagen => {
        this.setState({ visual_imagen });
      };

      mostrarProcesoMovimiento(record){
        console.log(record);
        this.setState({datos_proceso_movimiento: record, modal_proceso_movimiento: true});
    }

    handleChangeFactura(value){
        switch(value){
            case 'Si':
                this.setState({existe_factura: true});
            break;
            default:
                this.setState({existe_factura: false});
                break;
        }
    }

    handleChangeTicket(value){
        switch(value){
            case 'Si':
                this.setState({existe_ticket: true});
            break;
            default:
                this.setState({existe_ticket: false});
                break;
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Buscar ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Limpiar
            </Button>
          </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined  style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });

      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };

      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };

    listaCatalogos(){
        catalogos().then(data => {

            console.log(data);

            switch(data.status){
                case 0:
                    tipo_operaciones = data.data[0].map(operacion => {
                        return(
                            <Option value={operacion.id_tipo_movimiento}>{operacion.tipo}</Option>
                        );
                    });
                    conceptos = data.data[1].map(concepto => {
                        return(
                            <Option value={concepto.id_concepto}>{concepto.nombre}</Option>
                        );
                    });
                    deshabilitar_gastos_concepto = data.data[2];
                    formas_de_pago = data.data[3].map(forma => {
                        return(
                            <Option value={forma.id_forma_de_pago}>{forma.tipo}</Option>
                        );
                    });
                    proyectos = data.data[4].map(proyecto => {
                        return(
                            <Option value={proyecto.id_proyecto}>{proyecto.nombre}</Option>
                        );
                    });;
                    empresas = data.data[5].map(empresa => {
                        return(
                            <Option value={empresa.id_empresa}>{empresa.nombre}</Option>
                        );
                    });

                    acciones = data.data[6].filter(accion => {
                        if(this.props.id_proyecto === accion.proyecto_id){
                            return true;
                        }
                        return false;
                    });


                    acciones = acciones.map(accion => {
                        return(
                            <Option value={accion.id_accion}>{accion.accion}</Option>
                        );
                    });

                    this.setState({
                        catalogos:true
                    });
                break;
                default:
                break;
            }
        });
    }

    listaMovimiento(datos, tipo){

        this.props.onResponse(true);

    }

    openNotification = (icono,color_icono, titulo,mensaje) => {
        notification.open({
        message: titulo,
        description:
            mensaje,
        icon: icono,
        });
    };

    edit(id){

        id_movimiento = id;
        movimiento(id).then(data => {
            switch(data.status){
                case 0:
                    
                    this.setState({movimiento_editar: data.data, drawer_editar_movimiento:true, existe_ticket:true, existe_factura:true});
                    url_factura = data.data.url_factura;
                    url_ticket = data.data.url_ticket;
                break;
                case 5:
                    this.openNotification('frown','red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    this.openNotification('frown','orange','Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
            }
        });

    }

    delete(id){
        eliminar_movimiento(id).then(data => {
            switch(data.status){
                case 0:
                    this.openNotification('smile','#73fc03','Movimiento Eliminado','');
                    this.listaMovimiento(data.data,0);
                break;
                case 5:
                    this.openNotification('frown','red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    //this.setState({login:true});
                break;
                default:
                    this.openNotification('frown','orange','Sesión Expirada','Vuele a loguearte');
                    //this.setState({login:true});
                break;
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

            values['url_ticket'] = url_ticket;
            values['url_factura'] = url_factura;

            editar_movimiento(values, id_movimiento).then(data => {
                switch(data.status){
                    case 0:
                        this.openNotification('smile','#73fc03','Cambio exitoso','');
                        this.setState({drawer_editar_movimiento:false});
                        this.listaMovimiento(data.data,1);
                    break;
                    case 5:
                    this.openNotification('frown','red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    this.openNotification('frown','orange','Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
                }
            });

            }
        });
    };

    handleChangeConcepto(value) {
            
            gastos_concepto = deshabilitar_gastos_concepto.map(gasto => {
            if(gasto.concepto_id === value){
                return(
                    <Option className="opcion_gasto_concepto" data-id-concepto={gasto.concepto_id} value={gasto.id_gasto_concepto}>{gasto.nombre}</Option>
                );
            }else{
                return(
                    <Option className="opcion_gasto_concepto" data-id-concepto={gasto.concepto_id} value={gasto.id_gasto_concepto} style={{display:'none'}}>{gasto.nombre}</Option>
                );
            }

        });    
    
        this.setState({
            habilitar_gasto_concepto: true
        });
    }

    showModalImg = (src) => {
        img_src = src;
        this.setState({
            visual_imagen: true,
        });
    };
    
    handleOkImg = e => {
        
        this.setState({
            visual_imagen: false,
        });
    };
    
    handleCancelImg = e => {
       
        this.setState({
            visual_imagen: false,
        });
    };

    render(){

        

        if(this.state.login){
            return(
                <Redirect to="/" />
            );
        }

        let modificar_presupuesto = 0, egreso = 0, costo_proyectado = 0;
        console.log(this.props.movimientos);
        this.props.movimientos.filter(data => {
            if(data.id_proyecto === this.props.id_proyecto){
                switch(data.especificar_tipo_operacion){
                    case 'Comprobacion': case 'Reembolso':
                        if(data.status === 'Aprobado'){
                            egreso += data.cantidad;
                        }
                    break;
                    case 'Proyeccion':
                        costo_proyectado += data.cantidad;
                    break;
                    default: break;
                }
                return true;}else{return false;}
        });

        return(
            <Row>
                <Col span={24} offset={0}>
                    <p><strong>MOVIMIENTOS</strong></p>
                    <Row>
                    
                        <Col xs={{span:12, offset:0}} md={{span:5, offset:2}}>
                        <Statistic
                            title="Presupuesto - Iva"
                            value={(this.props.presupuesto) - (((this.props.presupuesto) * 16)/100)}
                            precision={2}
                            valueStyle={{ color: 'black' }}
                        />
                        </Col>
                        <Col xs={{span:12, offset:0}} md={{span:4, offset:0}}>
                        <Statistic
                            title="Costo Real"
                            value={!this.props.costo_real ? 0 : this.props.costo_real}
                            precision={2}
                            valueStyle={{ color: 'black' }}
                        />
                        </Col>
                        
                        <Col xs={{span:12, offset:0}} md={{span:4, offset:0}}>
                        <Statistic
                            title="Egresos"
                            value={egreso}
                            precision={2}
                            valueStyle={{ color: egreso < 0 ? 'red' : 'black' }}
                        />
                        </Col>
                        <Col xs={{span:12, offset:0}} md={{span:4, offset:0}}>
                        <Statistic
                            title="Utilidad Proyectada"
                            value={(this.props.presupuesto) - (((this.props.presupuesto) * 16)/100) - costo_proyectado}
                            precision={2}
                            valueStyle={{ color: 'black' }}
                        />
                        </Col>
                        <Col xs={{span:12, offset:0}} md={{span:4, offset:0}}>
                        <Statistic
                            title="Utilidad Real"
                            value={(this.props.presupuesto) - (((this.props.presupuesto) * 16)/100) - egreso}
                            precision={2}
                            valueStyle={{ color: 'black' }}
                        />
                        </Col>
                    </Row>
                </Col>
                <Col span={22} offset={1}>
                
                    

                    <Modal title={this.state.datos_proceso_movimiento ? this.state.datos_proceso_movimiento.status : ''} 
                    visible={this.state.modal_proceso_movimiento}
                    onOk={()=>{this.setState({modal_proceso_movimiento: false});}} 
                    onCancel={()=>{this.setState({modal_proceso_movimiento: false});}}>
                        {this.state.datos_proceso_movimiento &&
                            (
                                (this.state.datos_proceso_movimiento.status === 'Solicitud' && this.state.datos_proceso_movimiento.especificar_tipo_operacion === 'Solicitud') ?
                                    <Solicitud movimiento={this.state.datos_proceso_movimiento} />
                                :
                                (this.state.datos_proceso_movimiento.status === 'Solicitud' && this.state.datos_proceso_movimiento.especificar_tipo_operacion === 'Reembolso') ?
                                    <Reembolso movimiento={this.state.datos_proceso_movimiento} />
                                :
                                (this.state.datos_proceso_movimiento.status === 'Por comprobar') ?
                                    <Comprobacion movimiento={this.state.datos_proceso_movimiento} />
                                :
                                <Completo />
                            )
                        }
                </Modal>
                    
                </Col>
            </Row>
        );
    }
}


export default MovimientosProyecto;
