import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Drawer, Button, Row, Col, Menu, notification, Badge } from 'antd';
import { MenuOutlined, CloseOutlined, UserOutlined, CarryOutOutlined, ProfileOutlined} from '@ant-design/icons';

import { whoami, num_movimientos_pendientes } from '../../utils/Jwt';

const { SubMenu } = Menu;

let permiso_ver_usuarios = false, permiso_editar_usuarios = false, permiso_registrar_usuarios = false;
let permiso_ingreso = false, permiso_egreso = false, permiso_registrar = false;
let permiso_ver_mis_movimientos = false, permiso_ver_movimientos = false, permiso_editar_movimientos = false;
let permiso_consultar = false, permiso_consultas_guardadas = false;
let permiso_catalogos = false, permiso_proyectos = false;
let permiso_otorgar_proyecto = false;

class MenuIzquierdo extends React.Component {

  constructor(props){
    super(props);

    permiso_ver_usuarios = false; permiso_editar_usuarios = false; permiso_registrar_usuarios = false;
    permiso_ingreso = false; permiso_egreso = false; permiso_registrar = false;
    permiso_ver_mis_movimientos = false; permiso_ver_movimientos = false; permiso_editar_movimientos = false;
    permiso_consultar = false; permiso_consultas_guardadas = false;
    permiso_catalogos = false;

    this.state = {
      visible: false,
      notificaciones: false,
      num_notificaciones: 0,
      usuario: '',
      lista_movimientos: false,
      login: false,
      display_menu: true,
      mis_proyectos: []
    };

    for(let i=0; i < this.props.permisos_usuario.length; i++){
      switch(this.props.permisos_usuario[i].id_permiso){
        case 1: permiso_ingreso = true; break;
        case 2: permiso_egreso = true; break;
        case 3: permiso_registrar = true; break;
        case 4: permiso_ver_mis_movimientos = true; break;
        case 5: permiso_ver_movimientos = true; break;
        case 6: permiso_editar_movimientos = true; break;
        case 7: permiso_ver_usuarios = true; break;
        case 8: permiso_editar_usuarios = true; break;
        case 9: permiso_registrar_usuarios = true; break;
        case 10: permiso_consultar = true; break;
        case 11: permiso_consultas_guardadas = true; break;
        case 12: permiso_catalogos = true; break;
        case 13: permiso_proyectos = true; break;
        case 14: permiso_ver_movimientos = true; break;
        case 15: permiso_ver_movimientos = true; break;
        case 16: permiso_otorgar_proyecto = true; break;
        default: break;
      }

    }


    whoami().then( data => {
      switch(data.status){
        case 0:
          this.setState({usuario: data.data.nombre});
        break;
        case 5:
          this.openNotification('frown','red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
          this.setState({login:true});
        break;
        default:
          this.openNotification('frown','orange','Sesión Expirada','Vuele a loguearte');
          this.setState({login:true});
        break;
      }
    });

    num_movimientos_pendientes().then(data => {
      switch(data.status){
        case 0:
            if(permiso_editar_movimientos){
              this.setState({notificaciones:true, num_notificaciones: data.data});
            }
          break;
        default:
          break;
      }
    });

    this.ocultarNotificaciones = this.ocultarNotificaciones.bind(this);
  }

  

  openNotification = (icono,color_icono, titulo,mensaje) => {
    notification.open({
      message: titulo,
      description:
        mensaje,
      icon: icono
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

    handleClick = e => {
      this.setState({
        visible: false,
      });
    };


    ocultarNotificaciones(){
      this.setState({num_notificaciones:0, lista_movimientos: true});
    }

  render() {

    if(this.state.login){
      return(
        <Redirect to="/" />
      );
    }


    return (
      <Col span={2} offset={0} style={{textAlign:'left'}}>
        
        <Button onClick={this.showDrawer} style={{margin:'30px 20px'}}>
        <MenuOutlined />
        </Button>

        <Drawer
          title={ (
            <Row type="flex" justify="center" align="middle">
              <Col span={18}>
                Hola {this.state.usuario} 
                
              </Col>
              <Col span={6} style={{textAlign:'right'}}>
                <Button onClick={this.onClose} ><CloseOutlined /></Button>
              </Col>
            </Row>
          ) }
          placement='left'
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          width='310'
        >
          { this.state.display_menu &&

            <Menu
            onClick={this.handleClick}
            style={{ width: 270 }}
            defaultSelectedKeys={['perfil']}
            defaultOpenKeys={['']}
            mode="inline"
            >
            
            <Menu.Item key="usuario"><Link to={{
              pathname: "/usuario",
              state: { fromDashboard: true }
            }}>Perfil</Link></Menu.Item>
            
            { permiso_ver_usuarios &&
              <Menu.Item key="lista-usuario"><Link to="/usuario/lista-usuarios">Usuarios</Link></Menu.Item>
            }

            {(permiso_proyectos || permiso_otorgar_proyecto) &&
              (
                <SubMenu
                key="proyectos"
                title={
                  <span>
                    <ProfileOutlined />
                    <span>Proyectos</span>
                  </span>
                }
                >
                  {permiso_proyectos &&
                  <>
                    <Menu.Item key="lista-mis-proyectos"><Link to="/usuario/mis-proyectos">Mis proyectos</Link></Menu.Item>
                    <Menu.Item key="registrar-proyecto"><Link to="/usuario/registrar-proyecto">Registrar</Link></Menu.Item>
                  </>}
                  

                  {permiso_otorgar_proyecto &&
                    <Menu.Item key="otorgar-proyectos"><Link to="/usuario/otorgar-proyectos">Otorgar permiso Proyecto</Link></Menu.Item>
                  }
                </SubMenu>
              )
            }

            <Menu.Item key="lista-movimiento"><Link to="/usuario/lista-movimientos">Movimientos</Link></Menu.Item>
            
            { permiso_consultar &&
                    <Menu.Item key="reporte-consulta"><Link to="/usuario/reporte-consultas">Consulta</Link></Menu.Item>
            }
            
            { permiso_catalogos &&
              <SubMenu
                key="catalogos"
                title={
                  <span>
                    <ProfileOutlined />
                    <span>Catálogos</span>
                  </span>
                }
              >
                <Menu.Item key="lista-catalogo"><Link to="/usuario/lista-catalogos">Lista</Link></Menu.Item>
              </SubMenu>
            }

            <Menu.Item key="cerrar-sesion"><Link to="/cerrar-sesion">Cerrar Sesión</Link></Menu.Item>

            </Menu>

          }
        
        </Drawer>
      </Col>
    );
  }
}

export default MenuIzquierdo;
