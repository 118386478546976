import React from 'react';
import { Row, Col, Descriptions, notification, Tag, Popconfirm, Modal, Input, Select, Tooltip } from 'antd';
import { personal_permisos, eliminar_personal_permiso, editar_personal } from '../../../utils/Jwt';
import { EditOutlined, SmileOutlined, FrownOutlined } from '@ant-design/icons';
import '../../../css/perfil.css';

let permisos, id, campo, nombre_campo;

const { Option } = Select;

class Usuario extends React.Component{

    
    constructor(props){

        super(props);

        this.state = {
            usuario: {},
            login: false,
            modal_editar:false,
            valor_actualizar: ''
        }

        this.editar_input = React.createRef()

        this.datosUsuario();
    }

    datosUsuario(){
      personal_permisos().then( data => {
        switch(data.status){
            case 0:
                permisos = data.data.map(permiso => {
                  return(
                    
                      <Tag  style={{color:'black', border:'2px solid'+permiso['color']}}>{permiso['tipo']}</Tag>
                    
                  );
                });
                this.setState({usuario:data.data[0]});
              break;
              case 5:
                this.openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                this.setState({login:true});
              break;
              default:
                this.openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
                this.setState({login:true});
              break;
        }
    });
    }

    borrarPermiso(id){
      return event => {
        eliminar_personal_permiso(id).then(data => {
          switch(data.status){
            case 0:
              this.openNotification(<SmileOutlined />,'#73fc03','Elimado','El permiso ha sido eliminado');
              this.datosUsuario();
            break;
            case 5:
              this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
              this.setState({login:true});
            break;
            default:
              this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
              this.setState({login:true});
            break;
          }
        });
      };
    }

    openNotification = (icono,color_icono, titulo,mensaje) => {
        notification.open({
          message: titulo,
          description:
            mensaje,
          icon: icono,
        });
    };

    showModalEditar(id_u,nombre_campo_u,campo_u){
      return event => {
        id = id_u; campo = campo_u; nombre_campo = nombre_campo_u;
        this.setState({
          modal_editar: true,
        });
      }
    };

    handleOkEditar = e => {
      this.setState({
        modal_editar: false,
      });

      editar_personal({id: id, campo:campo, valor: this.state.valor_actualizar}).then(data => {
        switch(data.status){
          case 0:
              this.openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Editado','El campo ha sido actualizado');
              this.datosUsuario();
            break;
            case 5:
              this.openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
              this.setState({login:true});
            break;
            default:
              this.openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
              this.setState({login:true});
            break;
        }
      });
    };
  
    handleCancel = e => {
      this.setState({
        modal_editar: false,
      });
    };

    render(){
        return(
            <Row>
                <Col span={20} offset={2}>
                <Descriptions title="MI PERFIL" column={2} layout="horizontal" bordered={true}>
                  <Descriptions.Item label={<div style={{fontWeight:'bold'}}>Nombre</div>} onClick={this.showModalEditar(this.state.usuario.id_personal,'Nombre','nombre')}>
                    <span onClick={this.showModalEditar(this.state.usuario.id_personal,'Nombre','nombre')}>
                      {this.state.usuario.nombre} <Tooltip title="Editar">
                        <EditOutlined className="icono_editar" />
                      </Tooltip>
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label={<div style={{fontWeight:'bold'}}>Apellidos</div>} onClick={this.showModalEditar(this.state.usuario.id_personal,'Apellidos','apellidos')}>
                    <span onClick={this.showModalEditar(this.state.usuario.id_personal,'Apellidos','apellidos')}>
                      {this.state.usuario.apellidos} <Tooltip title="Editar">
                        <EditOutlined className="icono_editar" />
                      </Tooltip>
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label={<div style={{fontWeight:'bold'}}>Área</div>} >
                    <span >
                      {this.state.usuario.area} 
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label={<div style={{fontWeight:'bold'}}>Cargo</div>}>
                    <span onClick={this.showModalEditar(this.state.usuario.id_personal,'Cargo','cargo')}>
                      {this.state.usuario.cargo} <Tooltip title="Editar">
                        <EditOutlined className="icono_editar" />
                      </Tooltip>
                      </span>
                  </Descriptions.Item>
                  <Descriptions.Item label={<div style={{fontWeight:'bold'}}>Contraseña</div>} span={2}>
                    <span onClick={this.showModalEditar(this.state.usuario.id_personal,'Contraseña','pass')}>
                      ... <Tooltip title="Editar">
                        <EditOutlined className="icono_editar" />
                      </Tooltip>
                      </span>
                  </Descriptions.Item>
                  <Descriptions.Item label={<div style={{fontWeight:'bold'}}>Permisos</div>} span={2}>
                    {permisos}
                  </Descriptions.Item>
                </Descriptions>
                </Col>
                <Modal
                  title={ "Editar "+nombre_campo}
                  visible={this.state.modal_editar}
                  onOk={this.handleOkEditar}
                  onCancel={this.handleCancel}
                  cancelText="Cancelar"
                  okText="Editar"
                >
                  {nombre_campo === 'Área' ?
                  
                  <Select onChange={(val) => this.setState({valor_actualizar:val})}
                            style={{ width: '100%' }}
                            placeholder="Selecciona tu área"
                            allowClear={true}
                            autoClearSearchValue={true}
                        >
                            <Option value="TI">TI</Option>
                            <Option value="ADMINISTRACIÓN">ADMINISTRACIÓN</Option>
                        </Select>
                  :
                  <Input onChange={(val) => this.setState({valor_actualizar:val.target.value})} />
                }
                </Modal>
            </Row>
        );
    }
}

export default Usuario;