import React from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Input, Select, Row, Col, Button, Checkbox, InputNumber, notification, Upload } from 'antd';
import { SearchOutlined, PlusCircleOutlined, UploadOutlined  } from '@ant-design/icons';

import { SERVER_URL, catalogos, registrar_movimiento } from '../../../utils/Jwt';


const { Option } = Select;
const { TextArea } = Input;

let tipo_operaciones = [], conceptos = [], gastos_concepto, deshabilitar_gastos_concepto = [], formas_de_pago = [], proyectos= [], proyectos_ingreso = [], empresas = [], tickets_nombres = [];
let permiso_registrar_movimiento = false, permiso_ingreso = false, permiso_egreso = false, permiso_registrar = false;
let url_ticket = '', url_factura = '', url_documento = '';

const props_ticket = {
    name: 'ticket',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },

    transformFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = function() {
                
                resolve(fr.result);
                var arr = fr.result.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                console.log(new Blob([u8arr], {type:mime}));

                url_ticket = fr.result;
                return new Blob([u8arr], {type:mime});
            }
            fr.readAsDataURL(file);
        });
    }
  };

  const props_factura = {
    name: 'factura',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },

    transformFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = function() {
                
                resolve(fr.result);
                var arr = fr.result.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }

                url_factura = fr.result;
                return new Blob([u8arr], {type:mime});
            }
            fr.readAsDataURL(file);
        });
    }
  };
  const props_documento = {
    name: 'documento',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },

    transformFile(file) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = function() {
                
                resolve(fr.result);
                var arr = fr.result.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }

                url_documento = fr.result;
                return new Blob([u8arr], {type:mime});
            }
            fr.readAsDataURL(file);
        });
    }
  };

class RegistrarMovimiento extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            confirmDirty: false,
            autoCompleteResult: [],
            catalogos: false,
            habilitar_gasto_concepto:false,
            login: false,
            lista_movimientos: false,
            existe_ticket: false,
            existe_factura: false,
            existe_documento: false,
            nuevo_ticket:false,
            es_ingreso: false,
            proyecto_ingreso: 0
        };

        permiso_registrar_movimiento = false;
        permiso_ingreso = false;
        permiso_egreso = false;
        permiso_registrar = false;

        this.select_concepto = React.createRef();
        this.select_gasto_concepto = React.createRef();

        this.handleChangeGastoConcepto = this.handleChangeGastoConcepto.bind(this);
        this.handleChangeConcepto = this.handleChangeConcepto.bind(this);
        this.listaCatalogos = this.listaCatalogos.bind(this);
        this.handleChangeFactura = this.handleChangeFactura.bind(this);
        this.handleChangeDocumento = this.handleChangeDocumento.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTicket = this.handleChangeTicket.bind(this);

        for(let i=0; i < this.props.permisos_usuario.length; i++){
            if(this.props.permisos_usuario[i].id_permiso === 1
                || this.props.permisos_usuario[i].id_permiso === 2
                ||this.props.permisos_usuario[i].id_permiso === 3
            ){
                permiso_registrar_movimiento = true;
                break;
            }
        }

        for(let i=0; i < this.props.permisos_usuario.length; i++){
            if(this.props.permisos_usuario[i].id_permiso === 1){
                permiso_ingreso = true;
                continue;
            }
            if(this.props.permisos_usuario[i].id_permiso === 2){
                permiso_egreso = true;
                continue;
            }
            if(this.props.permisos_usuario[i].id_permiso === 3){
                permiso_registrar = true;
                continue;
            }
        }


        if(permiso_registrar_movimiento){
            this.listaCatalogos();
        }

    }

    openNotification = (icono,color_icono, titulo,mensaje) => {
        notification.open({
        message: titulo,
        description:
            mensaje,
        icon: icono,
        });
    };

    listaCatalogos(){

        catalogos().then(data => {

                console.log('catalogos: ',data.data);

            switch(data.status){
                case 0:

                    if(permiso_registrar){
                        tipo_operaciones = data.data[0].filter(operacion => {
                            if(operacion.id_tipo_movimiento === 2){
                                return(
                                    operacion
                                );
                            }
                        });

                        tipo_operaciones = tipo_operaciones.map(operacion => {
                            return(
                                <Option value={operacion.id_tipo_movimiento}>{operacion.tipo}</Option>
                            );
                        });
                        
                    }else{
                            tipo_operaciones = data.data[0].filter(operacion => {
                                if(permiso_ingreso && operacion.id_tipo_movimiento === 1){
                                    return(
                                        operacion
                                    );
                                }else{
                                    if(permiso_egreso && operacion.id_tipo_movimiento === 2){
                                        return(
                                            operacion
                                        );
                                    }
                                }
                            });

                            tipo_operaciones = tipo_operaciones.map(operacion => {
                                return(
                                    <Option value={operacion.id_tipo_movimiento}>{operacion.tipo}</Option>
                                );
                            });
                    }

                    
                    conceptos = data.data[1].map(concepto => {
                        return(
                            <Option value={concepto.id_concepto}>{concepto.nombre}</Option>
                        );
                    });
                    deshabilitar_gastos_concepto = data.data[2];
                    formas_de_pago = data.data[3].map(forma => {
                        return(
                            <Option value={forma.id_forma_de_pago}>{forma.tipo}</Option>
                        );
                    });
                    proyectos_ingreso = data.data[4];
                    proyectos = data.data[4].map(proyecto => {
                        return(
                            <Option value={proyecto.id_proyecto}>{proyecto.nombre}</Option>
                        );
                    });;
                    empresas = data.data[5].map(empresa => {
                        return(
                            <Option value={empresa.id_empresa}>{empresa.nombre}</Option>
                        );
                    });

                    tickets_nombres = data.data[7].map(ticket => {
                        return(
                        <Option value={ticket.id_ticket_reembolso}>{ticket.nombre_ticket}</Option>
                        );
                    });

                    this.setState({
                        catalogos:true
                    });
                break;
                default:
                break;
            }
        });
    }

    handleChangeConcepto(value) {

        const { getFieldDecorator } = this.props.form;
        
        let num_gastos_concepto = 0;

            gastos_concepto = deshabilitar_gastos_concepto.map(gasto => {
                if(gasto.concepto_id === value){
                    num_gastos_concepto++;
                    return(
                        <Option className="opcion_gasto_concepto" data-id-concepto={gasto.concepto_id} value={gasto.id_gasto_concepto}>{gasto.nombre}</Option>
                    );
                }else{
                    return(
                        <Option className="opcion_gasto_concepto" data-id-concepto={gasto.concepto_id} value={gasto.id_gasto_concepto} style={{display:'none'}}>{gasto.nombre}</Option>
                    );
                }
    
            });
        
            console.log('nombre :',num_gastos_concepto);

        if(num_gastos_concepto > 0){
            this.setState({
                habilitar_gasto_concepto: true
            });
        }else{
            this.setState({
                habilitar_gasto_concepto: false
            });
        }
    
       
    }

    handleChangeGastoConcepto(value) {
        console.log(`selected ${value}`);
    }

    handleChange(value) {
        console.log(`selected ${value}`);
    }

    handleChangeFactura(value){
        switch(value){
            case 'Si':
                this.setState({existe_factura: true});
            break;
            default:
                this.setState({existe_factura: false});
                break;
        }
    }

    handleChangeDocumento(value){
        switch(value){
            case 'Si':
                this.setState({existe_documento: true});
            break;
            default:
                this.setState({existe_documento: false});
                break;
        }
    }

    handleChange(value){
        switch(value){
            case 'Si':
                this.setState({existe_ticket: true});
            break;
            default:
                this.setState({existe_ticket: false});
                break;
        }
    }

    handleChangeTicket(value){
        if(value === 0){
            this.setState({nuevo_ticket:true});
        }else{
            this.setState({nuevo_ticket:false});
        }

    }
    
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
            console.log('Received values of form: ', values);

            switch(values['tipo_operacion_id']){
                case 1:
                    values['especificar_tipo_operacion'] = 'Solicitud';
                    values['status'] = 'Solicitud';
                    values['tipo_operacion_id'] = 2;
                    break;
                case 2:
                    values['especificar_tipo_operacion'] = 'Reembolso';
                    values['status'] = 'Solicitud';
                    values['tipo_operacion_id'] = 2;
                    break;
                case 3:
                    values['especificar_tipo_operacion'] = 'Ingreso';
                    values['status'] = 'Aprobado';
                    values['tipo_operacion_id'] = 1;
                    break;
                default:
                break;
            }

            values['url_ticket'] = url_ticket;
            values['url_factura'] = url_factura;
            values['url_documento'] = url_documento;
            

            registrar_movimiento(values).then(data => {
                switch(data.status){
                    case 0:
                    this.openNotification('smile','#73fc03','Movimiento Agregado','');
                    //this.setState({lista_movimientos:true});
                    //this.props.onResponse();
                    break;
                    case 5:
                        this.openNotification('frown','red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                        //this.setState({login:true});
                    break;
                    default:
                        this.openNotification('frown','orange','Sesión Expirada','Vuele a loguearte');
                        //this.setState({login:true});
                    break;
                }
            });
            }
        });
    };

    render() {

        if(this.state.login){
            return(
                <Redirect to="/" />
            );
        }

        if(!permiso_registrar_movimiento){
            return(
                <Redirect to="/usuario" />
            );
        }

        if(this.state.lista_movimientos){
            return(
                <Redirect to="/usuario/lista-movimientos" />
            );
        }

        const { getFieldDecorator } = this.props.form;
    
        const formItemLayout = {
            labelCol: {
                xs:{
                    span: 24
                },
                sm:{
                    span:5, offset:2
                },
                md:{
                    span:4, offset:3
                }
            },
            wrapperCol: {
                xs:{
                    span: 24
                },
                sm:{
                    span:14, offset:0
                },
                md:{
                    span:10, offset:0
                }
            },
        };
    
        const tailFormItemLayout = {
        wrapperCol: {
            xs: {
            span: 24,
            offset: 0,
            },
            
        },
        };
    
        return (
        <Row>
            
            <Col span={24} >
                <Form onSubmit={this.handleSubmit}>
                    { permiso_registrar &&
                    <Row>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:1}} style={{display:'none'}}>
                            <Form.Item label="Ticket">
                            {getFieldDecorator('ticket_reembolso_id', {
                                rules: [
                                    { required: false, message: '' },
                                    ],
                                })(
                                    <Select onChange={this.handleChangeTicket}>
                                        <Option value={0}>
                                            Nuevo
                                        </Option>
                                    {tickets_nombres}
                                </Select>
                                )}
                            </Form.Item>
                        </Col>

                        { this.state.nuevo_ticket && 
                            <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:2}}>
                            <Form.Item
                        label={
                            <span>
                            Nombre ticket
                            </span>
                        }
                        >
                        {getFieldDecorator('nombre_ticket', {
                            rules: [{ required: true, message: 'Asigna un nombre al código' }],
                        })(
                            <Input style={{width:'100%'}} />
                        )}
                        </Form.Item>
                            </Col>
                        }

                    </Row>
                    }
                    
                    <Row>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:1}}>
                        <Form.Item label="Operación">
                    {getFieldDecorator('tipo_operacion_id', {
                        
                        rules: [
                        { required: true, message: 'Selecciona un tipo de operación' },
                        ],
                    })(
                        <Select onChange={(val)=>{this.handleChange(val); if(val === 3) this.setState({es_ingreso: true});}}>
                            { permiso_egreso &&
                                <Option value={1}>Solicitud</Option>
                            }
                                
                            {permiso_registrar &&
                                (
                                    <Option value={2}>Reembolso</Option>
                                )
                            }
                            
                            

                            { permiso_ingreso &&
                            <Option value={3}>Ingreso</Option>
                            }
                            
                        </Select>
                    )}
                    </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:2}}>
                        <Form.Item
                    label={
                        <span>
                            {this.state.es_ingreso ? 
                                <>Importe pagado</>
                                :
                                <>Cantidad</>

                            }
                        </span>
                    }
                    >
                    {getFieldDecorator('cantidad', {
                        rules: [{ required: true, message: 'Asigna una cantidad' }],
                    })(
                        <InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{width:'100%'}} />
                    )}
                    </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:1}}>
                        <Form.Item
                    label={
                        <span>
                        Fecha {this.state.es_ingreso ? <>factura</>: <></>}
                        </span>
                    }
                    >
                    {getFieldDecorator('fecha', {
                        rules: [{ required: true, message: 'Asigna una fecha', whitespace: true }],
                    })(
                        <Input
                        type="date"
                        
                    />
                    )}
                    </Form.Item>
                        </Col>

{
    this.state.es_ingreso ? 
    <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:2}}>
                        <Form.Item label="Fecha pago">
                    {getFieldDecorator('fecha_pago', {
                    
                        rules: [
                        { required: true, message: 'Asigna una fecha' },
                        ],
                    })(
                        <Input
                        type="date"
                        
                    />
                    )}
                    </Form.Item>
                        </Col>
                        :
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:2}}>
                        <Form.Item label="Forma de Pago">
                    {getFieldDecorator('forma_de_pago_id', {
                    
                        rules: [
                        { required: true, message: 'Selecciona una forma de pago' },
                        ],
                    })(
                        <Select onChange={this.handleChange}>
                        {formas_de_pago}
                    </Select>
                    )}
                    </Form.Item>
                        </Col>
}


                        
                    </Row>
                    <Row>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:1}}>
                        <Form.Item label="Empresa">
                    {getFieldDecorator('empresa_id', {
                        rules: [
                        { required: true, message: 'Selecciona una empresa' },
                        ],
                    })(
                        <Select onChange={this.handleChange}>
                        { empresas }
                    </Select>
                    )}
                    </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:2}}>
                        <Form.Item label="Proyecto">
                    {getFieldDecorator('proyecto_id', {
                        
                        rules: [
                        { required: true, message: 'Selecciona un proyecto' },
                        ],
                    })(
                        <Select onChange={(value)=>{ this.setState({proyecto_ingreso: value});}}>
                        {proyectos}
                    </Select>
                    )}
                    </Form.Item>
                        </Col>
                    </Row>


                    {this.state.es_ingreso ?
                    <>
                    <Row>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:1}}>
                        <Form.Item
                    label={
                        <span>
                            Ingreso proyecto
                        </span>
                    }
                    >
                    {getFieldDecorator('proyecto_ingreso', {
                        rules: [{ required: true, message: 'Asigna una cantidad' }],
                        initialValue: proyectos_ingreso.filter(proy => proy.id_proyecto === this.state.proyecto_ingreso)[0] ? proyectos_ingreso.filter(proy => proy.id_proyecto === this.state.proyecto_ingreso)[0].ingreso : 10
                    })(
                        <InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{width:'100%'}} />
                    )}
                    </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:1}}>
                        <Form.Item label="Documento">
                        {getFieldDecorator('documento', {
                            
                            rules: [
                            { required: true, message: 'Selecciona una opción' },
                            ],
                        })(
                            <Select onChange={this.handleChangeDocumento}>
                                <Option value='Si'>Si</Option>
                                <Option value='No'>No</Option>
                            </Select>
                        )}
                        </Form.Item>
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:2}}>

                        { this.state.existe_documento &&
                            (
                                <Form.Item label="Documento" >
                                    {getFieldDecorator('url_documento', {
                                        
                                    })(
                                        <Upload {...props_documento} >
                                            <Button>
                                            <UploadOutlined /> Clic para adjuntar documento
                                            </Button>
                                        </Upload>,
                                    )}
                                </Form.Item>
                            )
                        }

                    

                    </Col>
                </Row>
                </>
                :
                <>
<Row>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:1}}>
                            <Form.Item label="Factura">
                            {getFieldDecorator('factura', {
                                
                                rules: [
                                { required: true, message: 'Selecciona una opción' },
                                ],
                            })(
                                <Select onChange={this.handleChangeFactura}>
                                    <Option value='Si'>Si</Option>
                                    <Option value='No'>No</Option>
                                    <Option value='No hay'>No hay</Option>
                                </Select>
                            )}
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:2}}>

                            { this.state.existe_factura &&
                                (
                                    <Form.Item label="Factura" >
                                        {getFieldDecorator('url_factura', {
                                            
                                        })(
                                            <Upload {...props_factura} >
                                                <Button>
                                                <UploadOutlined /> Clic para adjuntar factura
                                                </Button>
                                            </Upload>,
                                        )}
                                    </Form.Item>
                                )
                            }

                        

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:1}}>
                            <Form.Item label="Ticket">
                            {getFieldDecorator('ticket', {
                                
                                rules: [
                                { required: true, message: 'Selecciona una opción' },
                                ],
                            })(
                                <Select onChange={this.handleChange}>
                                    <Option value='Si'>Si</Option>
                                    <Option value='No'>No</Option>
                                </Select>
                            )}
                            </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:2}}>

                        { this.state.existe_ticket &&
                            (
                                <Form.Item label="Ticket" >
                                    {getFieldDecorator('url_ticket', {
                                        
                                    })(
                                        <Upload {...props_ticket}>
                                            <Button>
                                            <UploadOutlined /> Clic para adjuntar ticket
                                            </Button>
                                        </Upload>
                                    )}
                                </Form.Item>
                            )
                        }

                        

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:1}}>
                        <Form.Item label="Concepto del gasto">
                    {getFieldDecorator('concepto_id', {
                        
                        rules: [
                        { required: true, message: 'Selecciona un tipo de concepto' },
                        ],
                    })(
                        <Select ref={this.select_concepto} onChange={this.handleChangeConcepto}>
                        {conceptos}
                    </Select>
                    )}
                    </Form.Item>
                        </Col>
                        <Col xs={{span:24}} sm={{span:24}} md={{span:10, offset:2}}>
                        {this.state.habilitar_gasto_concepto && 
                            <Form.Item label="Gasto Concepto">
                            {getFieldDecorator('gasto_concepto_id', {

                                rules: [
                                { required: false, message: 'Selecciona una opción' },
                                ],
                                initialValue:''
                            })(
                                <Select className="select_gasto_concepto" disabled={this.state.deshabilitar_gasto_concepto} onChange={this.handleChangeGastoConcepto}>
                                {gastos_concepto}
                            </Select>
                            )}
                            </Form.Item>
                        }
                        </Col>
                    </Row>
                </>
                        
                    }

                    

                    


                    
                    

                    
<Row>
    <Col xs={{span:24}} sm={{span:24}} md={{span:22, offset:1}}>
    <Form.Item
                    label={
                        <span>
                        Observaciones
                        </span>
                    }
                    >
                    {getFieldDecorator('observaciones', {
                        rules: [{ required: true, message: 'Observaciones sobre éste registro es obligatorio', whitespace: true }],
                    })(
                        <TextArea />
                    )}
                    </Form.Item>
    </Col>

</Row>
                    

<Row>
    <Col span={20} offset={2} style={{textAlign:'right'}}>
    <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Registrar
                    </Button>
                    </Form.Item>

    </Col>
</Row>
                    
                </Form>
            </Col>
        </Row>
        
        );
    }

}

//const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrarMovimiento);
export default RegistrarMovimiento;
