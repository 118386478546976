import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Button, InputNumber, notification, Icon, Upload, message, DatePicker } from 'antd';
import { SERVER_URL, catalogos, agregar_movimientos, get_movimientos_editar, editar_movimiento } from '../../../utils/Jwt';

import { PlusOutlined, DeleteOutlined, UploadOutlined, SmileOutlined, FrownOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const concepto_depositos = 211;

const props_ticket = {
    name: 'ticket',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
        Authorization: `Bearer ${localStorage.token}`,
    }
};

const props_factura = {
    name: 'factura',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
        Authorization: `Bearer ${localStorage.token}`,
    }
};

const props_documento = {
    name: 'documento',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
        Authorization: `Bearer ${localStorage.token}`,
    }
};

const props_deposito = {
    name: 'deposito',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
        Authorization: `Bearer ${localStorage.token}`,
    }
};

const props_xml = {
    name: 'xml',
    action: `${SERVER_URL}/api/archivo-storage`,
    headers: {
        Authorization: `Bearer ${localStorage.token}`,
    }
};

let url_factura = '';
let url_documento = '';
let url_deposito = '';
let url_xml = '';

const openNotification = (icono, titulo, mensaje) => {
    notification.open({
        message: titulo,
        description:
            mensaje,
        icon: icono,
    });
};

const RegistrarMovimientoN = (props) => {

    const [tipo_operacion, setTipoOperacion] = useState(0);
    const [catalogo, setCatalogo] = useState([]);
    const [num_movimientos, setNumMovimientos] = useState([]);
    const [num_comprobaciones, setNumComprobaciones] = useState([]);
    const [num_depositos, setNumDepositos] = useState([]);
    const [proyecto_id, setProyectoId] = useState(0);
    const [otra_empresa, setOtraEmpresa] = useState(false);
    const [otro_cliente, setOtroCliente] = useState(false);
    const [factura_ingreso, setFacturaIngreso] = useState('');
    const [documento_ingreso, setDocumentoIngreso] = useState('');
    const [cambio_factura_ingreso, setCambioFacturaIngreso] = useState('');
    const [datos_form, setDatosForm] = useState(null);
    const [proyecto_actualizar, setProyectoActualiza] = useState(0);
    let { id_proyecto, comprobar, ingreso, proyectado, actualizar_operacion, id_actualizar, onResponse, hacer_deposito } = props;
    const [form] = Form.useForm();
    const [empresa_ingreso, setEmpresaIngreso] = useState(0);


    useEffect(() => {
        catalogos().then(({ data }) => { setCatalogo(data); });
    }, []);

    useEffect(() => {

        const datos = {
            tipo_operacion: actualizar_operacion,
            id: id_actualizar
        };

        if (actualizar_operacion !== 0 && id_actualizar !== 0) {
            get_movimientos_editar(datos).then(({ status, data }) => {
                switch (status) {
                    case 0:
                        switch (actualizar_operacion) {
                            case 1:
                                data.fecha_envio_cliente = moment(data.fecha_envio_cliente, 'YYYY-MM-DD');
                                data.fecha_factura = moment(data.fecha_factura, 'YYYY-MM-DD');
                                data.fecha_pago = moment(data.fecha_pago, 'YYYY-MM-DD');

                                if (data.factura === 'Si') {
                                    setFacturaIngreso('Si');
                                }

                                if(data.status === 'Cancelada'){
                                    setCambioFacturaIngreso('Cancelada');
                                }

                                if (data.documento === 'Si') {
                                    setDocumentoIngreso('Si');
                                }

                                console.log(data);

                                setDatosForm({ ...data });
                                break;
                            case 2:
                                
                                setProyectoActualiza(data[0].proyecto_id);
                                let movimientos = data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Solicitud' && movimiento.concepto_id !== concepto_depositos).map((movimiento, index) => {
                                    return {
                                        id: movimiento.id_movimiento,
                                        index: index + 1, activo: true,
                                        proyecto_accion_id: movimiento.proyecto_accion_id,
                                        concepto_id: movimiento.concepto_id,
                                        gasto_concepto_id: movimiento.gasto_concepto_id,
                                        cantidad: movimiento.cantidad,
                                        empresa_id: movimiento.empresa_id,
                                        nota_id: movimiento.nota_id,
                                    };
                                });

                                setNumMovimientos(movimientos);

                                let depositos = data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Deposito').map((deposito,index) => {
                                    return{
                                        id: deposito.id_movimiento,
                                        index: index+1, activo:true,
                                        nota_id: deposito.nota_id,
                                        cantidad: deposito.cantidad,
                                        observaciones: deposito.observaciones,
                                        url_deposito: deposito.url_documento,
                                        tipo_deposito: deposito.tipo_deposito
                                    };
                                });

                                setNumDepositos(depositos);

                                setDatosForm({
                                    id_nota: data[0].id_nota,
                                    descripcion: data[0].descripcion_nota,
                                    empresa: data[0].empresa_id,
                                    fecha_inicio: moment(data[0].fecha_inicio, 'YYYY-MM-DD'),
                                    fecha_fin: moment(data[0].fecha_fin, 'YYYY-MM-DD'),
                                    proyecto_id: data[0].proyecto_id
                                });
                                break;
                            case 3://Reembolsos

                                setProyectoActualiza(data[0].proyecto_id);
                                let movimientos_ = [];
                                let id_accion_movimiento = 0;
                                let index = 1;

                                let reembolsos = data.filter(reembolso => reembolso.especificar_tipo_operacion !== 'Deposito').map((reembolso, indice) => {

                                    if (id_accion_movimiento !== reembolso.proyecto_accion_id) {
                                        id_accion_movimiento = reembolso.proyecto_accion_id;
                                        movimientos_.push({
                                            id: 0, index: index++, activo: true,
                                            proyecto_accion_id: reembolso.proyecto_accion_id,
                                            concepto_id: reembolso.concepto_id,
                                            gasto_concepto_id: reembolso.gasto_concepto_id,
                                            tipo_comprobante: reembolso.tipo_comprobante,
                                            cantidad: 0, observaciones: ''
                                        });
                                    }

                                    return {
                                        id: reembolso.id_movimiento,
                                        id_movimiento_padre: index - 1,
                                        index: indice + 1, activo: true,
                                        cantidad: reembolso.cantidad,
                                        observaciones: reembolso.observaciones,
                                        forma_de_pago_id: reembolso.forma_de_pago_id,
                                        tipo_comprobante: reembolso.tipo_comprobante, url_factura: reembolso.url_factura, url_ticket: reembolso.url_ticket,
                                        fecha: reembolso.fecha,
                                    };
                                });

                                setNumComprobaciones(reembolsos);
                                setNumMovimientos(movimientos_);

                                let depositos_ = data.filter(deposito => deposito.especificar_tipo_operacion === 'Deposito').map((deposito,index) => {
                                    return{
                                        id: deposito.id_movimiento,
                                        index: index+1, activo:true,
                                        nota_id: deposito.nota_id,
                                        cantidad: deposito.cantidad,
                                        observaciones: deposito.observaciones,
                                        url_deposito: deposito.url_documento,
                                        tipo_deposito: deposito.tipo_deposito
                                    };
                                });

                                setNumDepositos(depositos_);

                                setDatosForm({
                                    id_nota: data[0].id_nota,
                                    descripcion: data[0].descripcion_nota,
                                    empresa: data[0].empresa_id,
                                    fecha_inicio: moment(data[0].fecha_inicio, 'YYYY-MM-DD'),
                                    fecha_fin: moment(data[0].fecha_fin, 'YYYY-MM-DD'),
                                    proyecto_id: data[0].proyecto_id
                                });

                                break;
                            case 4:
                                setNumMovimientos([{ id: 0, index: 1, activo: true, proyecto_accion_id: data.proyecto_accion_id, concepto_id: data.concepto_id, gasto_concepto_id: data.gasto_concepto_id, cantidad: data.cantidad, observaciones: data.observaciones }]);
                                setProyectoActualiza(data.proyecto_id);
                                setDatosForm({ proyecto_id: data.proyecto_id });
                                break;
                            case 5:

                                let comprobaciones = [];
                                let solicitudes = data[0].map((solicitud, index) => {

                                    data[1].filter(comprobacion => comprobacion.movimiento_padre_id === solicitud.id_movimiento).map((comprobacion) => {
                                        comprobaciones.push({
                                            id: comprobacion.id_movimiento,
                                            id_movimiento_padre: index + 1,
                                            index: comprobaciones.length + 1, activo: true,
                                            proyecto_accion_id: comprobacion.proyecto_accion_id,
                                            cantidad: comprobacion.cantidad,
                                            concepto_id: comprobacion.concepto_id,
                                            gasto_concepto_id: comprobacion.gasto_concepto_id,
                                            forma_de_pago_id: comprobacion.forma_de_pago_id,
                                            observaciones: comprobacion.observaciones,
                                            nota_id: comprobacion.nota_id,
                                            proyecto_id: comprobacion.proyecto_id,
                                            url_factura: comprobacion.url_factura,
                                            url_ticket: comprobacion.url_ticket,
                                            tipo_comprobante: comprobacion.tipo_comprobante,
                                            fecha: comprobacion.fecha,
                                        });
                                    });

                                    return {
                                        id: solicitud.id_movimiento,
                                        index: index + 1, activo: true,
                                        proyecto_accion_id: solicitud.proyecto_accion_id,
                                        cantidad: solicitud.cantidad,
                                        concepto_id: solicitud.concepto_id,
                                        gasto_concepto_id: solicitud.gasto_concepto_id,
                                        observaciones: solicitud.observaciones,
                                        nota_id: solicitud.nota_id,
                                        proyecto_id: solicitud.proyecto_id,
                                        empresa_id: solicitud.empresa_id
                                    };
                                });

                                setProyectoActualiza(data[0][0].proyecto_id);
                                setNumMovimientos(solicitudes);
                                setNumComprobaciones(comprobaciones);

                                setDatosForm({
                                    id_nota: data[0][0].id_nota,
                                    descripcion: data[0][0].descripcion_nota,
                                    empresa: data[0][0].empresa_id,
                                    fecha_inicio: moment(data[0][0].fecha_inicio, 'YYYY-MM-DD'),
                                    fecha_fin: moment(data[0][0].fecha_fin, 'YYYY-MM-DD'),
                                    proyecto_id: data[0][0].proyecto_id
                                });

                                break;
                            default:
                                break;
                        }
                        break;
                    case 1:
                        break;
                    default:
                        break;
                }
            });
        }

    }, [actualizar_operacion, id_actualizar]);//catalogo

    useEffect(() => {
        setTipoOperacion(actualizar_operacion);
    }, [datos_form]);

    useEffect(() => {
        if (!datos_form) {
            switch (tipo_operacion) {
                case 1://ingreso
                    setNumMovimientos([{ id: 0, index: 1, activo: true, proyecto_accion_id: 0, concepto_id: 0, gasto_concepto_id: 0, cantidad: 0, observaciones: '', proyecto_id: 0 }]);
                    break;
                case 2://solicitud
                    setNumMovimientos([{ id: 0, index: 1, activo: true, proyecto_accion_id: 0, concepto_id: 0, gasto_concepto_id: 0, cantidad: 0, observaciones: '' }]);
                    break;
                case 3://reembolso
                    setNumMovimientos([{ id: 0, index: 1, activo: true, proyecto_accion_id: 0, concepto_id: 0, gasto_concepto_id: 0, cantidad: 0, observaciones: '' }]);
                    break;
                case 4://proyeccion
                    setNumMovimientos([{ id: 0, index: 1, activo: true, proyecto_accion_id: 0, concepto_id: 0, gasto_concepto_id: 0, cantidad: 0, observaciones: '' }]);
                    break;
                default:
                    break;
            }
        }

    }, [tipo_operacion]);

    const agregarDeposito = () => {
        setNumDepositos([...num_depositos, {id: 0, index: num_depositos.length+1, activo:true, nota_id: 0, cantidad: 0, observaciones: '', tipo_deposito:'Transferencia' ,url_deposito:''}]);
    }

    const borrarDeposito = (index) => {
        let depositos = num_depositos.map(deposito => {if(deposito.index === index) deposito.activo = false; return deposito;});
        setNumDepositos(depositos);
    }

    const agregarMovimiento = () => {
        let nuevo_movimiento;
        switch (tipo_operacion) {
            case 1://ingreso
                nuevo_movimiento = { id: 0, index: num_movimientos.length + 1, activo: true, proyecto_accion_id: 0, concepto_id: 0, gasto_concepto_id: 0, cantidad: 0, observaciones: '' };
                break;
            case 2://solicitud
                nuevo_movimiento = { id: 0, index: num_movimientos.length + 1, activo: true, proyecto_accion_id: 0, concepto_id: 0, gasto_concepto_id: 0, cantidad: 0, observaciones: '' };
                break;
            case 3://reembolso
                nuevo_movimiento = { id: 0, index: num_movimientos.length + 1, activo: true, proyecto_accion_id: 0, concepto_id: 0, gasto_concepto_id: 0, cantidad: 0, observaciones: '' };
                break;
            case 4://proyeccion
                nuevo_movimiento = { id: 0, index: num_movimientos.length + 1, activo: true, proyecto_accion_id: 0, concepto_id: 0, gasto_concepto_id: 0, cantidad: 0, observaciones: '' };
                break;
            default:
                break;
        }
        setNumMovimientos([...num_movimientos, nuevo_movimiento]);
    }

    const borrarMovimiento = (index) => {
        let movimientos = num_movimientos.map(movimiento => { if (movimiento.index === index) movimiento.activo = false; return movimiento });
        setNumMovimientos(movimientos);
    }

    const agregarComprobacion = (id_movimiento_padre, id_accion, id_concepto, id_gasto_concepto) => {
        let nueva_comprobacion = { id: 0, id_movimiento_padre: id_movimiento_padre, index: num_comprobaciones.length + 1, activo: true, proyecto_accion_id: id_accion, forma_de_pago_id: 0, concepto_id: id_concepto, gasto_concepto_id: id_gasto_concepto, cantidad: 0, observaciones: '', url_factura: '', url_ticket: '', tipo_comprobante: '', fecha: '' };
        setNumComprobaciones([...num_comprobaciones, nueva_comprobacion]);
    }

    const borrarComprobante = (index) => {
        let comprobantes = num_comprobaciones.map(comprobante => { if (comprobante.index === index) comprobante.activo = false; return comprobante });
        setNumComprobaciones(comprobantes);
    }

    const onFinish = (values) => {

        let datos = {}

        if(hacer_deposito){
           
            let depositos = num_depositos.map(deposito => {
                deposito.id_movimiento = deposito.id;
                deposito.url_documento = deposito.url_deposito;
                deposito.status = 'Aprobado';
                deposito.especificar_tipo_operacion = 'Deposito';
                deposito.concepto_id = concepto_depositos;
                deposito.tipo_operacion_id = 2;
                deposito.proyecto_id = id_proyecto !== 0 ? id_proyecto : proyecto_actualizar;
                deposito.nota_id = id_actualizar;

                delete deposito.id;
                delete deposito.index;
                delete deposito.url_deposito;

                return deposito;
            });

            let datos = {
                tipo_operacion_id: 6,
                nota: {
                    id_nota: id_actualizar,
                },
                depositos: depositos
            }

            editar_movimiento(datos, id_actualizar).then(({ status, data }) => {
                switch (status) {
                    case 0:
                        setTipoOperacion(0);
                        setNumMovimientos([]);
                        setNumComprobaciones([]);
                        setNumDepositos([]);
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />, 'Depósitos actualizado', '');
                        form.resetFields();
                        setDatosForm(null);
                        onResponse(tipo_operacion);
                        break;
                    default:
                        openNotification(<FrownOutlined  style={{color:'orange'}} />, 'Error de conexión', 'Intenta registrar en otro momento');
                        break;
                }
            });
            return;
        }

        if (actualizar_operacion === 0) {//neuvo registro

            switch (tipo_operacion) {
                case 1://ingreso

                    values.cantidad = parseFloat(values.cantidad);
                    values.fecha_envio_cliente = values.fecha_envio_cliente.format("YYYY-MM-DD");
                    values.fecha_factura = values.fecha_factura.format("YYYY-MM-DD");
                    values.fecha_pago = values.fecha_pago.format("YYYY-MM-DD");
                    values.importe_proyecto = parseFloat(values.importe_proyecto);
                    values.tipo_operacion_id = 1;
                    values.tipo_operacion = 1;
                    values.url_factura = url_factura;
                    values.url_documento = url_documento;
                    values.url_xml = url_xml;
                    values.especificar_tipo_operacion = 'Ingreso';
                    values.proyecto_id = id_proyecto !== 0 ? id_proyecto : values.proyecto_id;
                    values.status = 'Aprobado';

                    if (values.empresa_id === 'Otra') {
                        values.nueva_empresa = values.otra_empresa;
                        values.empresa_id = 0;
                        delete values.otra_empresa;
                    }

                    if (values.cliente_id === 'Otro') {
                        values.nuevo_cliente = values.otro_cliente;
                        values.cliente_id = 0;
                        delete values.otro_cliente;
                    }

                    delete values.operacion;
                    datos = values;

                    break;
                case 2: //Solicitud

                    let movimientos = num_movimientos.filter(movimiento => movimiento.activo).map(movimiento => {

                        movimiento.proyecto_id = id_proyecto !== 0 ? id_proyecto : values.proyecto_id;
                        movimiento.empresa_id = values.empresa;
                        movimiento.tipo_operacion_id = 4;
                        movimiento.especificar_tipo_operacion = 'Solicitud';
                        movimiento.status = 'Pendiente';

                        delete movimiento.activo;
                        delete movimiento.id;
                        delete movimiento.index;
                        delete movimiento.observaciones;

                        return movimiento;
                    });

                    datos = {
                        tipo_operacion: tipo_operacion,
                        nota: {
                            fecha_inicio: values.fecha_inicio.format("YYYY-MM-DD"),
                            fecha_fin: values.fecha_fin.format("YYYY-MM-DD"),
                            descripcion_nota: values.descripcion,
                            status_nota: 'Pendiente',
                            tipo_nota: 'Solicitud',
                            tipo_resto: '',
                            empresa_id: values.empresa,
                            proyecto_id: id_proyecto !== 0 ? id_proyecto : values.proyecto_id,
                            costo: movimientos.reduce((accumulator, movimiento) => accumulator + movimiento.cantidad, 0),
                            depositado:0,
                            comprobado: 0,
                            resto: 0,
                        },
                        movimientos: movimientos
                    }

                    break;
                case 3: //Reembolso

                    let comprobaciones = [];

                    num_movimientos.filter(movimiento => movimiento.activo).map(movimiento => {

                        num_comprobaciones.filter(
                            comprobacion => { return ((comprobacion.id_movimiento_padre === movimiento.index)); })
                            .map(comprobacion => {
                                comprobacion.proyecto_id = id_proyecto !== 0 ? id_proyecto : values.proyecto_id;
                                comprobacion.empresa_id = values.empresa;
                                comprobacion.tipo_operacion_id = 2;
                                comprobacion.proyecto_accion_id = movimiento.proyecto_accion_id;
                                comprobacion.concepto_id = movimiento.concepto_id;
                                comprobacion.gasto_concepto_id = movimiento.gasto_concepto_id;
                                comprobacion.status = 'Pendiente';
                                comprobacion.especificar_tipo_operacion = 'Reembolso';

                                delete comprobacion.activo;
                                delete comprobacion.id;
                                delete comprobacion.index;
                                delete comprobacion.id_movimiento_padre;

                                comprobaciones.push(comprobacion);

                                return comprobacion;

                            });
                    });

                    datos = {
                        tipo_operacion: tipo_operacion,
                        nota: {
                            fecha_inicio: values.fecha_inicio.format("YYYY-MM-DD"),
                            fecha_fin: values.fecha_fin.format("YYYY-MM-DD"),
                            descripcion_nota: values.descripcion,
                            status_nota: 'Pendiente',
                            tipo_nota: 'Reembolso',
                            tipo_resto: 'Reintegro',
                            empresa_id: values.empresa,
                            proyecto_id: id_proyecto !== 0 ? id_proyecto : values.proyecto_id,
                            costo: 0,
                            depositado:0,
                            comprobado: comprobaciones.reduce((accumulator, comprobacion) => accumulator + comprobacion.cantidad, 0),
                            resto: comprobaciones.reduce((accumulator, comprobacion) => accumulator + comprobacion.cantidad, 0),
                        },
                        reembolsos: comprobaciones
                    }

                    break;
                case 4://proyecciones

                    num_movimientos.map(proyeccion => {
                        proyeccion.proyecto_id = id_proyecto !== 0 ? id_proyecto : values.proyecto_id;
                        proyeccion.tipo_operacion_id = 3;
                        proyeccion.especificar_tipo_operacion = 'Proyeccion';
                        proyeccion.status = 'Aprobado';

                        delete proyeccion.activo;
                        delete proyeccion.id;
                        delete proyeccion.index;

                        return proyeccion;
                    });

                    datos = {
                        tipo_operacion: tipo_operacion,
                        movimientos: num_movimientos
                    }

                    break;
                default: break;
            }

            agregar_movimientos(datos).then(({ status, data }) => {
                switch (status) {
                    case 0:
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />, 'Movimiento creado', '');
                        setNumMovimientos([]);
                        setNumComprobaciones([]);
                        setTipoOperacion(0);
                        form.resetFields();
                        setDatosForm(null);
                        onResponse(tipo_operacion);
                        break;
                    default:
                        openNotification(<FrownOutlined style={{color:'red'}} />, 'Error de conexión', 'Intenta registrar en otro momento');
                        break;
                }
            });

        } else {//actualizar_regsitro

            switch (tipo_operacion) {
                case 1://ingreso

                    values.cantidad = parseFloat(values.cantidad);
                    values.fecha_envio_cliente = values.fecha_envio_cliente.format("YYYY-MM-DD");
                    values.fecha_factura = values.fecha_factura.format("YYYY-MM-DD");
                    values.fecha_pago = values.fecha_pago.format("YYYY-MM-DD");
                    values.importe_proyecto = parseFloat(values.importe_proyecto);
                    values.tipo_operacion_id = 1;
                    if (url_factura === '') {
                        delete values.url_factura;
                    } else {
                        values.url_factura = url_factura;
                    }

                    values.especificar_tipo_operacion = 'Ingreso';

                    if (values.empresa_id === 'Otra') {
                        values.nueva_empresa = values.otra_empresa;
                        values.empresa_id = 0;
                        delete values.otra_empresa;
                    }

                    delete values.operacion;

                    datos = values;

                    break;
                case 2: //Solicitud

                    num_movimientos.map(movimiento => {

                        movimiento.id_movimiento = movimiento.id;
                        movimiento.proyecto_id = id_proyecto !== 0 ? id_proyecto : proyecto_actualizar;
                        movimiento.empresa_id = values.empresa;
                        movimiento.tipo_operacion_id = 4;
                        movimiento.especificar_tipo_operacion = 'Solicitud';
                        movimiento.status = 'Pendiente';
                        movimiento.nota_id = id_actualizar;

                        delete movimiento.index;
                        delete movimiento.id;
                        delete movimiento.observaciones;

                        return movimiento;

                    });

                    datos = {
                        tipo_operacion_id: tipo_operacion,
                        nota: {
                            id_nota: id_actualizar,
                            fecha_inicio: values.fecha_inicio.format("YYYY-MM-DD"),
                            fecha_fin: values.fecha_fin.format("YYYY-MM-DD"),
                            descripcion_nota: values.descripcion,
                            status_nota: 'Pendiente',
                            tipo_nota: 'Solicitud',
                            //tipo_resto: '',
                            empresa_id: values.empresa,
                            proyecto_id: id_proyecto !== 0 ? id_proyecto : proyecto_actualizar,
                            costo: num_movimientos.filter(movimiento => movimiento.activo).reduce((accumulator, movimiento) => accumulator + movimiento.cantidad, 0),
                            //depositado:0,
                            comprobado: 0,
                            //resto: 0,
                        },
                        movimientos: num_movimientos
                    }

                    break;
                case 3: //Reembolso

                    num_movimientos.map(movimiento => {

                        num_comprobaciones.filter(comprobacion => comprobacion.id_movimiento_padre === movimiento.index).map(comprobacion => {

                            comprobacion.nota_id = id_actualizar;
                            comprobacion.activo = movimiento.activo && comprobacion.activo;
                            comprobacion.id_movimiento = comprobacion.id;
                            comprobacion.proyecto_id = id_proyecto !== 0 ? id_proyecto : proyecto_actualizar;
                            comprobacion.empresa_id = values.empresa;
                            comprobacion.tipo_operacion_id = 2;
                            comprobacion.proyecto_accion_id = movimiento.proyecto_accion_id;
                            comprobacion.concepto_id = movimiento.concepto_id;
                            comprobacion.gasto_concepto_id = movimiento.gasto_concepto_id;
                            comprobacion.status = 'Pendiente';
                            comprobacion.especificar_tipo_operacion = 'Reembolso';

                            delete comprobacion.id;
                            delete comprobacion.index;
                            delete comprobacion.id_movimiento_padre;

                            return comprobacion;

                        });

                    });

                    datos = {
                        tipo_operacion_id: tipo_operacion,
                        nota: {
                            id_nota: id_actualizar,
                            fecha_inicio: values.fecha_inicio.format("YYYY-MM-DD"),
                            fecha_fin: values.fecha_fin.format("YYYY-MM-DD"),
                            descripcion_nota: values.descripcion,
                            status_nota: 'Pendiente',
                            tipo_nota: 'Reembolso',
                            tipo_resto: 'Reintegro',
                            empresa_id: values.empresa,
                            proyecto_id: id_proyecto !== 0 ? id_proyecto : proyecto_actualizar,
                            costo: 0,
                            depositado:0,
                            comprobado: num_comprobaciones.filter(comprobacion => comprobacion.activo).reduce((accumulator, comprobacion) => accumulator + comprobacion.cantidad, 0),
                            resto: num_comprobaciones.filter(comprobacion => comprobacion.activo).reduce((accumulator, comprobacion) => accumulator + comprobacion.cantidad, 0),
                        },
                        reembolsos: num_comprobaciones
                    }

                    break;
                case 4://proyecciones

                    num_movimientos.map(proyeccion => {
                        proyeccion.proyecto_id = id_proyecto !== 0 ? id_proyecto : proyecto_actualizar;;
                        proyeccion.tipo_operacion_id = 3;
                        proyeccion.especificar_tipo_operacion = 'Proyeccion';
                        proyeccion.status = 'Aprobado';

                        delete proyeccion.activo;
                        delete proyeccion.id;
                        delete proyeccion.index;
                    });

                    datos = {
                        tipo_operacion_id: tipo_operacion,
                        movimientos: num_movimientos
                    }

                    break;
                case 5://Comprobaciones
                    num_movimientos.map(movimiento => {

                        console.log(movimiento);

                        num_comprobaciones.filter(comprobacion => comprobacion.id_movimiento_padre === movimiento.index).map(comprobacion => {

                            comprobacion.id_movimiento = comprobacion.id;
                            comprobacion.nota_id = movimiento.nota_id;
                            comprobacion.movimiento_padre_id = movimiento.id;
                            comprobacion.id_movimiento = comprobacion.id;
                            comprobacion.proyecto_id = id_proyecto !== 0 ? id_proyecto : proyecto_actualizar;
                            comprobacion.empresa_id = movimiento.empresa_id;
                            
                            comprobacion.proyecto_accion_id = movimiento.proyecto_accion_id;
                            comprobacion.status = 'Comprobado';

                            if(movimiento.concepto_id === concepto_depositos){
                                comprobacion.tipo_operacion_id = 1;
                                comprobacion.especificar_tipo_operacion = 'Deposito';
                            }else{
                                comprobacion.tipo_operacion_id = 2;
                                comprobacion.especificar_tipo_operacion = 'Comprobacion';
                            }

                            delete comprobacion.id;
                            delete comprobacion.index;
                            delete comprobacion.id_movimiento_padre;

                            return comprobacion;

                        });
                    });

                    datos = {
                        tipo_operacion_id: 5,
                        nota: {
                            id_nota: id_actualizar,
                        },
                        comprobaciones: num_comprobaciones
                    }

                    break;
                default: break;
            }

            editar_movimiento(datos, id_actualizar).then(({ status, data }) => {
                switch (status) {
                    case 0:
                        setTipoOperacion(0);
                        setNumMovimientos([]);
                        setNumComprobaciones([]);
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />, 'Movimiento actualizado', '');
                        form.resetFields();
                        setDatosForm(null);
                        onResponse(tipo_operacion);
                        break;
                    default:
                        openNotification(<FrownOutlined style={{color:'red'}} />, 'Error de conexión', 'Intenta registrar en otro momento');
                        break;
                }
            });
        }
    };

    const actualizarValor = (index, campo, valor) => {
        let movimientos = num_movimientos.map(movimiento => { if (movimiento.index === index) movimiento[campo] = valor; return movimiento });
        setNumMovimientos(movimientos);
    }

    const actualizarComprobante = (index, campo, valor) => {
        let comprobaciones = num_comprobaciones.map(comprobacion => { if (comprobacion.index === index) comprobacion[campo] = valor; return comprobacion });
        setNumComprobaciones(comprobaciones);
    }

    const actualizarDeposito = (index, campo, valor) => {
        let depositos = num_depositos.map(deposito => {if(deposito.index === index) deposito[campo] = valor; return deposito});
        setNumDepositos(depositos);
    }

    useEffect(() => () => {
        setDatosForm(null);
    }, []);

    return (
        <Row>
            <Col span={24} offset={0}>
                {(datos_form || (actualizar_operacion === 0)) &&
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={{ status:'Aprobado',...datos_form }}
                    >
                        <Row justify="space-around">
                            {!hacer_deposito ?

                            <>

                            {id_proyecto === 0 && id_actualizar === 0 &&
                                <Col span={24}>
                                    <Form.Item
                                        label="Proyecto"
                                        name="proyecto_id"
                                        rules={[{ required: true, message: 'Elige un proyecto' }]}
                                    >
                                        <Select showSearch style={{ width: '100%' }} optionFilterProp="children"
                                         onChange={(val) => { setProyectoId(val); }}
                                            disabled={comprobar}
                                        >
                                            {catalogo[4] && catalogo[4].map((proyecto) => { return (<Option value={proyecto.id_proyecto}>{proyecto.nombre}</Option>); })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }

                            {((id_proyecto !== 0 || proyecto_id !== 0) && actualizar_operacion === 0) &&
                                <Col span={24}>
                                    <Form.Item
                                        label="Operación"
                                        name="operacion"
                                        rules={[{ required: true, message: 'Elige una operación' }]}
                                    >
                                        <Select onChange={(val) => { setTipoOperacion(val); }}>
                                            {ingreso && <Option value={1}>Ingreso</Option>}
                                            <Option value={2}>Solicitud</Option>
                                            <Option value={3}>Reembolso</Option>
                                            {proyectado && <Option value={4}>Proyección</Option>}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }

                            {tipo_operacion === 1 ? //ingreso
                                <>

                                    <Col span={otra_empresa ? 11 : 22}>
                                        <Form.Item
                                            label="Empresa"
                                            name="empresa_id"
                                            rules={[{ required: true, message: 'Elige una empresa' }]}
                                        >
                                            <Select showSearch style={{ width: '100%' }} optionFilterProp="children"
                                            onChange={val => { if (val === 'Otra'){setOtraEmpresa(true); setEmpresaIngreso(0); }else{setOtraEmpresa(false); setEmpresaIngreso(val);} }}>
                                                {catalogo[5] && catalogo[5].map((empresa) => { return (<Option value={empresa.id_empresa}>{empresa.nombre}</Option>); })}
                                                <Option value="Otra">Otra</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {otra_empresa &&
                                        <Col span={11}>
                                            <Form.Item
                                                label="Cuál"
                                                name="otra_empresa"
                                                rules={[{ required: true, message: 'Ingresa la empresa' }]}
                                            >
                                                <Input style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    }

                                    <Col span={24}></Col>

                                    <Col span={otro_cliente ? 11 : 22}>
                                        <Form.Item
                                            label="Cliente"
                                            name="cliente_id"
                                            rules={[{ required: true, message: 'Elige un cliente' }]}
                                        >
                                            <Select showSearch style={{ width: '100%' }} optionFilterProp="children"
                                             onChange={val => { if (val === 'Otro'){setOtroCliente(true)}else{setOtroCliente(false)} }}>
                                                {catalogo[9] && catalogo[9].filter(cliente => cliente.empresa_id === empresa_ingreso).map((cliente) => { return (<Option value={cliente.id_cliente}>{cliente.nombre_cliente}</Option>); })}
                                                <Option value="Otro">Otro</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {otro_cliente &&
                                        <Col span={11}>
                                            <Form.Item
                                                label="Cuál"
                                                name="otro_cliente"
                                                rules={[{ required: true, message: 'Ingresa el cliente' }]}
                                            >
                                                <Input style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    }

                                    <Col span={24}></Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Importe del proyecto"
                                            name={`importe_proyecto`}
                                            rules={[{ required: true, message: 'Registra un importe' }]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                precision={2}
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                stringMode
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Mes"
                                            name={`mes`}
                                            rules={[{ required: true, message: 'Elige un mes' }]}
                                        >
                                            <Select>
                                                <Option value={'Enero'}>Enero</Option>
                                                <Option value={'Febrero'}>Febrero</Option>
                                                <Option value={'Marzo'}>Marzo</Option>
                                                <Option value={'Abril'}>Abril</Option>
                                                <Option value={'Mayo'}>Mayo</Option>
                                                <Option value={'Junio'}>Junio</Option>
                                                <Option value={'Julio'}>Julio</Option>
                                                <Option value={'Agosto'}>Agosto</Option>
                                                <Option value={'Septiembre'}>Septiembre</Option>
                                                <Option value={'Octubre'}>Octubre</Option>
                                                <Option value={'Noviembre'}>Noviembre</Option>
                                                <Option value={'Diciembre'}>Diciembre</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Fecha factura"
                                            name="fecha_factura"
                                            rules={[{ required: true, message: 'Elige una fecha' }]}
                                        >
                                            <DatePicker style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Número de factura"
                                            name={`numero_factura`}
                                            rules={[{ required: true, message: 'Registra el número de factura' }]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Importe de la factura"
                                            name={`cantidad`}
                                            rules={[{ required: true, message: 'Registra el número de factura' }]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                precision={2}
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                stringMode
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Concepto de la factura"
                                            name={`concepto_factura`}
                                            rules={[{ required: true, message: 'Registra el concepto de la factura' }]}
                                        >
                                            <Input
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Fecha de envío a cliente"
                                            name="fecha_envio_cliente"
                                            rules={[{ required: true, message: 'Elige una fecha' }]}
                                        >
                                            <DatePicker style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Fecha de pago"
                                            name="fecha_pago"
                                        >
                                            <DatePicker style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>

                                        <Form.Item
                                            label="Factura"
                                            name={`factura`}
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <Select onChange={(val) => { setFacturaIngreso(val); }}>
                                                <Option value="Si">Si</Option>
                                                <Option value="No">No</Option>
                                            </Select>
                                        </Form.Item>
                                        {factura_ingreso === 'Si' &&
                                        
                                        <>
                                            <Form.Item
                                                label={null}
                                                name={`url_factura`}
                                                rules={[{ required: false, message: '' }]}

                                            >
                                                <Upload {...props_factura}
                                                    onChange={(info) => {
                                                        if (info.file.status !== 'uploading') {
                                                            url_factura = info.file.name;
                                                        }
                                                        if (info.file.status === 'done') {
                                                            message.success(`${info.file.name} file uploaded successfully`);
                                                        } else if (info.file.status === 'error') {
                                                            message.error(`${info.file.name} file upload failed.`);
                                                        }
                                                    }}
                                                >
                                                    <Button icon={<UploadOutlined />}>Adjuntar factura</Button>
                                                </Upload>
                                            </Form.Item>

                                            <Form.Item
                                            label={null}
                                            name={`url_xml`}
                                            rules={[{ required: false, message: '' }]}

                                            >
                                            <Upload {...props_xml}
                                                onChange={(info) => {
                                                    if (info.file.status !== 'uploading') {
                                                        url_xml = info.file.name;
                                                    }
                                                    if (info.file.status === 'done') {
                                                        message.success(`${info.file.name} file uploaded successfully`);
                                                    } else if (info.file.status === 'error') {
                                                        message.error(`${info.file.name} file upload failed.`);
                                                    }
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>Adjuntar XML</Button>
                                            </Upload>
                                            </Form.Item>
                                        </>
                                        }
                                    </Col>
                                    <Col span={11}>

                                        <Form.Item
                                            label="Orden de compra"
                                            name={`documento`}
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <Select onChange={(val) => { setDocumentoIngreso(val); }}>
                                                <Option value="Si">Si</Option>
                                                <Option value="No">No</Option>
                                            </Select>
                                        </Form.Item>
                                        {documento_ingreso === 'Si' &&
                                        
                                            <Form.Item
                                                label={null}
                                                name={`url_documento`}
                                                rules={[{ required: false, message: '' }]}

                                            >
                                                <Upload {...props_documento}
                                                    onChange={(info) => {
                                                        if (info.file.status !== 'uploading') {
                                                            url_documento = info.file.name;
                                                        }
                                                        if (info.file.status === 'done') {
                                                            message.success(`${info.file.name} file uploaded successfully`);
                                                        } else if (info.file.status === 'error') {
                                                            message.error(`${info.file.name} file upload failed.`);
                                                        }
                                                    }}
                                                >
                                                    <Button icon={<UploadOutlined />}>Adjuntar orden</Button>
                                                </Upload>
                                            </Form.Item>
                                        }
                                    </Col>
                                    
                                    <Col span={24}></Col>
                                    
                                    {datos_form &&
                                    <>
                                        <Col span={11}>

                                        <Form.Item
                                            label="Status factura"
                                            name={`status`}
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <Select onChange={val => { setCambioFacturaIngreso(val) }}>
                                                <Option value="Aprobado">Aprobado</Option>
                                                <Option value="Cancelada">Cancelada</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {cambio_factura_ingreso === 'Cancelada' &&

                                        <Col span={11}>

                                            <Form.Item
                                                label="Cambiar por:"
                                                name={`movimiento_cambio_id`}
                                                rules={[{ required: true, message: '' }]}
                                            >
                                                <Select>
                                                    {catalogo[8] && catalogo[8].map((factura) => { return (<Option value={factura.id_movimiento}>#{factura.numero_factura + ' ' + factura.concepto_factura}</Option>); })}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                    }
                                    </>
                                    }

                                    

                                </>
                                :

                                <>
                                    {tipo_operacion !== 4 && tipo_operacion !== 0 &&
                                        <Col span={24}>
                                            <Form.Item
                                                label="Empresa"
                                                name="empresa"
                                                rules={[{ required: true, message: 'Elige una empresa' }]}
                                            >
                                                <Select disabled={comprobar}>
                                                    {catalogo[5] && catalogo[5].map((empresa) => { return (<Option value={empresa.id_empresa}>{empresa.nombre}</Option>); })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    }
                                    {(tipo_operacion === 2 || tipo_operacion === 3) &&
                                        <>
                                            <Col span={11}>
                                                <Form.Item
                                                    label="Fecha inicio"
                                                    name="fecha_inicio"
                                                    rules={[{ required: true, message: 'Elige una fecha' }]}
                                                >
                                                    <DatePicker disabled={comprobar} style={{ width: '100%' }} onChange={(val) => { console.log(val); }} />
                                                </Form.Item>
                                            </Col>

                                            <Col span={11}>
                                                <Form.Item
                                                    label="Fecha fin"
                                                    name="fecha_fin"
                                                    rules={[{ required: true, message: 'Elige una fecha' }]}
                                                >
                                                    <DatePicker disabled={comprobar} format="YYYY-MM-DD" style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Col>

                                            <Col span={23}>
                                                <Form.Item
                                                    label="Descripción"
                                                    name={`descripcion`}
                                                    rules={[{ required: false, message: '' }]}
                                                >
                                                    <TextArea disabled={comprobar} style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Col>


                                        </>
                                    }

                                    {num_movimientos.filter(movimiento => movimiento.activo).length > 0 &&
                                        <Col span={24} >
                                            <p style={{color:'white', backgroundColor:'purple', fontWeight:'bold', textAlign:'center'}}>Acciones</p>
                                        </Col>
                                    }
                                    {catalogo.length > 0 &&

                                        

                                        num_movimientos.filter(movimiento => movimiento.activo).map((movimiento,index) => {

                                            return (
                                                <>
                                                    <Col span={24} style={{borderBottom: index === (num_movimientos.filter(movimiento => movimiento.activo).length - 1) ? '' : '1px solid purple', padding:'2px', marginBottom:'10px'}}>

                                                        <Row align="middle">
                                                        <Col span={22}>
                                                        <Row justify="space-around" >
                                                            <Col span={tipo_operacion === 3 ? 22 : 22}>
                                                                <Form.Item
                                                                    label="Acción"
                                                                    name={`accion[${movimiento.index}]`}
                                                                    rules={[{ required: false, message: 'Elige una acción' }]}
                                                                    initialValue={datos_form ? movimiento.proyecto_accion_id : null}
                                                                >
                                                                    <Select showSearch style={{ width: '100%' }} optionFilterProp="children" 
                                                                    disabled={comprobar} onChange={(val) => { actualizarValor(movimiento.index, 'proyecto_accion_id', val) }}>
                                                                        {catalogo[7].filter(objetivo_especifico => {
                                                                            if (datos_form) {
                                                                                return objetivo_especifico.proyecto_id === proyecto_actualizar
                                                                            } else {
                                                                                if(id_proyecto === 0){
                                                                                    return objetivo_especifico.proyecto_id === proyecto_id
                                                                                }else{
                                                                                    return objetivo_especifico.proyecto_id === id_proyecto
                                                                                }
                                                                            }

                                                                        })

                                                                            .map(objetivo_especifico => {

                                                                                let acciones = catalogo[6].filter(accion => accion.objetivo_especifico_id === objetivo_especifico.id_objetivo_especifico)
                                                                                    .map(accion => {
                                                                                        return (
                                                                                            <Option value={accion.id_accion}>{accion.accion}</Option>
                                                                                        );
                                                                                    });

                                                                                return (

                                                                                    <OptGroup label={objetivo_especifico.objetivo_especifico}>
                                                                                        {acciones}
                                                                                    </OptGroup>

                                                                                );
                                                                            })}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={tipo_operacion === 3 ? 22 : 11}>
                                                                <Form.Item
                                                                    label="Concepto del gasto"
                                                                    name={`concepto[${movimiento.index}]`}
                                                                    rules={[{ required: true, message: 'Elige una opción' }]}
                                                                    initialValue={datos_form ? movimiento.concepto_id : null}
                                                                >
                                                                    <Select showSearch style={{ width: '100%' }} optionFilterProp="children" 
                                                                     disabled={comprobar} onChange={(val) => { actualizarValor(movimiento.index, 'concepto_id', val) }}>
                                                                        {catalogo[1].map(concepto => {

                                                                            return (

                                                                                <Option value={concepto.id_concepto}>{concepto.nombre}</Option>

                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            {movimiento.concepto_id !== concepto_depositos &&
                                                                <Col span={tipo_operacion === 3 ? 0 : 11}>
                                                                <Form.Item
                                                                    label="Importe"
                                                                    name={`cantidad[${movimiento.index}]`}
                                                                    rules={[{ required: tipo_operacion === 3 ? false : true, message: 'Registra un importe' }]}
                                                                    initialValue={datos_form ? movimiento.cantidad : null}
                                                                >
                                                                    <InputNumber
                                                                        style={{ width: '100%' }}
                                                                        precision={2}
                                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                        stringMode
                                                                        onChange={(val) => { actualizarValor(movimiento.index, 'cantidad', parseFloat(val)) }}
                                                                        disabled={comprobar}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            }
                                                            {tipo_operacion === 4 &&
                                                                <Col span={23}>
                                                                    <Form.Item
                                                                        label="Observación"
                                                                        name={`observacion[${movimiento.index}]`}
                                                                        rules={[{ required: false, message: '' }]}
                                                                        initialValue={datos_form ? movimiento.observaciones : null}
                                                                    >
                                                                        <TextArea style={{ width: '100%' }} onChange={(val) => { actualizarValor(movimiento.index, 'observaciones', val.currentTarget.value) }} />
                                                                    </Form.Item>
                                                                </Col>
                                                            }

                                                        </Row>



                                                    </Col>
                                                    {((datos_form && actualizar_operacion !== 4 && !comprobar) || (!comprobar && actualizar_operacion !== 4)) &&
                                                        <Col span={2}>
                                                            <Button type="danger" onClick={() => { borrarMovimiento(movimiento.index) }} >
                                                                <DeleteOutlined />
                                                            </Button>
                                                        </Col>
                                                    }

                                                    {(comprobar || tipo_operacion === 3) &&

                                                        <>
                                                            {(num_comprobaciones.filter(comprobante => comprobante.id_movimiento_padre === movimiento.id).length > 0 || (tipo_operacion === 3 && num_comprobaciones.filter(comprobante => comprobante.activo).length > 0)  ) &&
                                                                <Col span={24} offset={0}>
                                                                    <p style={{ textAlign: 'center', backgroundColor:'#BA55D3', color:'white', fontWeight:'bold' }}>Comprobantes</p>
                                                                </Col>
                                                            }


                                                            {num_comprobaciones.filter(comprobante => comprobante.id_movimiento_padre === movimiento.index && comprobante.activo).map((comprobante, index2) => {

                                                                return (
                                                                    <Col span={24}>
                                                                        <Row align="middle" style={{borderBottom:index2 === (num_comprobaciones.filter(comprobante => comprobante.id_movimiento_padre === movimiento.index && comprobante.activo).length - 1) ? '' : '1px solid #BA55D3', marginBottom:'5px', padding:'3px'}}>
                                                                            <Col span={22} offset={0}>

                                                                            <Row>
                                                                                <Col span={22}>

                                                                                    <Form.Item
                                                                                        label="Descripción"
                                                                                        name={`observaciones_comprobante[${comprobante.index}]`}
                                                                                        rules={[{ required: true, message: 'Registra una descripción' }]}
                                                                                        initialValue={comprobante.observaciones}

                                                                                    >
                                                                                        <Input onChange={(val) => { actualizarComprobante(comprobante.index, 'observaciones', val.currentTarget.value) }} />
                                                                                    </Form.Item>



                                                                                </Col>
                                                                                <Col span={11}>

                                                                                    <Form.Item
                                                                                        label="Importe"
                                                                                        name={`cantidad_comprobante[${comprobante.index}]`}
                                                                                        rules={[{ required: true, message: '' }]}
                                                                                        initialValue={comprobante.cantidad}
                                                                                    >
                                                                                        <InputNumber
                                                                                            style={{ width: '100%' }}
                                                                                            precision={2}
                                                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                                            stringMode
                                                                                            onChange={(val) => { actualizarComprobante(comprobante.index, 'cantidad', parseFloat(val)) }}
                                                                                        />
                                                                                    </Form.Item>

                                                                                </Col>
                                                                                <Col span={11}>

                                                                                    <Form.Item
                                                                                        label="Forma de pago"
                                                                                        name={`forma_de_pago[${comprobante.index}]`}
                                                                                        rules={[{ required: true, message: '' }]}
                                                                                        initialValue={comprobante.forma_de_pago_id}
                                                                                    >
                                                                                        <Select onChange={(val) => { actualizarComprobante(comprobante.index, 'forma_de_pago_id', val) }}>
                                                                                            {catalogo[3].map(forma_pago => {
                                                                                                return (
                                                                                                    <Option value={forma_pago.id_forma_de_pago}>{forma_pago.tipo}</Option>
                                                                                                );
                                                                                            })}
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={22}>

                                                                                    <Form.Item
                                                                                        label="Fecha"
                                                                                        name={`fecha[${comprobante.index}]`}
                                                                                        rules={[{ required: true, message: '' }]}
                                                                                        initialValue={comprobante.fecha === '' ? '' : moment(comprobante.fecha, 'YYYY-MM-DD')}
                                                                                    >
                                                                                        <DatePicker style={{ width: '100%' }} onChange={(val) => { actualizarComprobante(comprobante.index, 'fecha', val.format("YYYY-MM-DD")); }} />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={11}>

                                                                                    <Form.Item
                                                                                        label="Tipo comprobante"
                                                                                        name={`tipo_comprobante[${comprobante.index}]`}
                                                                                        rules={[{ required: true, message: '' }]}
                                                                                        initialValue={comprobante.tipo_comprobante}
                                                                                    >
                                                                                        <Select onChange={(val) => { actualizarComprobante(comprobante.index, 'tipo_comprobante', val) }}>
                                                                                            <Option value="Factura">Factura</Option>
                                                                                            <Option value="Ticket">Ticket</Option>
                                                                                            <Option value="NA">NA</Option>
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col span={10} offset={1}>

                                                                                    {comprobante.tipo_comprobante === 'Factura' ?

                                                                                        <Form.Item
                                                                                            label={'...'}
                                                                                            name={`url_factura[${comprobante.index}]`}
                                                                                            rules={[{ required: false, message: '' }]}

                                                                                        >
                                                                                            <Upload {...props_factura}
                                                                                                onChange={(info) => {
                                                                                                    if (info.file.status !== 'uploading') {
                                                                                                        actualizarComprobante(comprobante.index, 'url_factura', info.file.name)
                                                                                                    }
                                                                                                    if (info.file.status === 'done') {
                                                                                                        message.success(`${info.file.name} file uploaded successfully`);
                                                                                                    } else if (info.file.status === 'error') {
                                                                                                        message.error(`${info.file.name} file upload failed.`);
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <Button icon={<UploadOutlined />}>Adjuntar factura</Button>
                                                                                            </Upload>
                                                                                        </Form.Item>
                                                                                        : comprobante.tipo_comprobante === 'Ticket' &&
                                                                                        <Form.Item
                                                                                            label={null}
                                                                                            name={`url_ticket[${comprobante.index}]`}
                                                                                            rules={[{ required: false, message: '' }]}

                                                                                        >
                                                                                            <Upload {...props_ticket}
                                                                                                onChange={(info) => {
                                                                                                    if (info.file.status !== 'uploading') {
                                                                                                        //console.log(info.file.name, info.fileList);
                                                                                                        actualizarComprobante(comprobante.index, 'url_ticket', info.file.name)
                                                                                                    }
                                                                                                    if (info.file.status === 'done') {
                                                                                                        message.success(`${info.file.name} file uploaded successfully`);
                                                                                                    } else if (info.file.status === 'error') {
                                                                                                        message.error(`${info.file.name} file upload failed.`);
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <Button icon={<UploadOutlined />}>Adjuntar ticket</Button>
                                                                                            </Upload>
                                                                                        </Form.Item>
                                                                                    }
                                                                                </Col>

                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            <Button type="danger" onClick={() => { borrarComprobante(comprobante.index) }} >
                                                                                <DeleteOutlined />
                                                                            </Button>
                                                                        </Col>
                                                                        </Row>
                                                                    </Col>
                                                                );
                                                            })
                                                            }

                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                <br />
                                                                <Button type="primary" style={{color:'white', backgroundColor:'#BA55D3'}} onClick={() => { agregarComprobacion(movimiento.index, movimiento.proyecto_accion_id, movimiento.concepto_id, movimiento.gasto_concepto_id) }}>
                                                                    <PlusOutlined /> Comprobante
                                                                </Button>
                                                                <br /><br />
                                                            </Col>
                                                        </>
                                                    }
                                                        </Row>

                                                    </Col>
                                                    


                                                </>
                                            );
                                        })
                                    }

                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Form.Item >

                                            {(tipo_operacion === 0 || comprobar || actualizar_operacion === 4) ?
                                                <></>
                                                :
                                                <>
                                                <br />
                                                <Button style={{color:'white', backgroundColor:'purple', fontWeight:'bold'}} type="dashed" onClick={agregarMovimiento}>
                                                    <PlusOutlined />
                                                    {tipo_operacion === 2 ?
                                                        'Solicitud'
                                                        :
                                                        tipo_operacion === 3 ?
                                                            'Reembolso'
                                                            :
                                                            'Proyección'
                                                    }
                                                </Button>
                                                </>
                                            }
                                        </Form.Item>
                                    </Col>

                                    
                                </>

                            }
                            </>

                            :
                            
                                        <>
                                            {num_depositos.filter(deposito => deposito.activo).map(deposito => {

                                                return(
                                                    <>
                                                    <Col span={20}>
                                                        <Form.Item
                                                                                        label="Cantidad"
                                                                                        name={`cantidad_deposito[${deposito.index}]`}
                                                                                        rules={[{ required: true, message: 'Ingresa la cantidad del depósito' }]}
                                                                                        initialValue={deposito.cantidad}
                                                                                    >
                                                                                        <InputNumber 
                                                                                        style={{ width: '100%' }}
                                                                                        precision={2}
                                                                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                                        stringMode
                                                                                        onChange={(val) => { actualizarDeposito(deposito.index, 'cantidad', val) }} />
                                                                                    </Form.Item>

                                                                                </Col>
                                                                                <Col span={4}>
                                                                                    <Button type="danger" onClick={() => { borrarDeposito(deposito.index) }} >
                                                                                        <DeleteOutlined />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    <Form.Item
                                                                                            label={'Tipo Comprobante'}
                                                                                            name={`tipo_deposito[${deposito.index}]`}
                                                                                            rules={[{ required: false, message: 'Elige un tipo de comprobante' }]}
                                                                                            initialValue={deposito.tipo_deposito}
                                                                                        >
                                                                                            <Select onChange={(val) => { actualizarDeposito(deposito.index, 'tipo_deposito', val) }} >
                                                                                                <Option value="Transferencia">Transferencia</Option>
                                                                                                <Option value="Efectivo">Efectivo</Option>
                                                                                            </Select>

                                                                                        </Form.Item>
                                                                                </Col>
                                                                                <Col span={11}>
                                                                                    {deposito.tipo_deposito === 'Transferencia' &&
                                                                                        <Form.Item
                                                                                        label={'...'}
                                                                                        name={`url_deposito[${deposito.index}]`}
                                                                                        rules={[{ required: false, message: 'Adjunta el comprobante' }]}
                                                                                    >
                                                                                        <Upload {...props_deposito}
                                                                                            onChange={(info) => {
                                                                                                if (info.file.status !== 'uploading') {
                                                                                                    //console.log(info.file.name, info.fileList);
                                                                                                    actualizarDeposito(deposito.index, 'url_deposito', info.file.name)
                                                                                                }
                                                                                                if (info.file.status === 'done') {
                                                                                                    message.success(`${info.file.name} file uploaded successfully`);
                                                                                                } else if (info.file.status === 'error') {
                                                                                                    message.error(`${info.file.name} file upload failed.`);
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <Button icon={<UploadOutlined />}>Adjuntar</Button>
                                                                                        </Upload>
                                                                                    </Form.Item>
                                                                                    }
                                                                                    
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <Form.Item
                                                                                            label={'Observaciones'}
                                                                                            name={`observaciones_deposito[${deposito.index}]`}
                                                                                            initialValue={deposito.observaciones}
                                                                                        >
                                                                                            <Input.TextArea onChange={(val) => { actualizarDeposito(deposito.index,'observaciones',val.currentTarget.value); }} />
                                                                                        </Form.Item>
                                                                                </Col>
                                                                                
                                                                                </>
                                                );

                                            })}
                                            <Col span={24} style={{textAlign:'center'}}>
                                                <Button type="dashed" onClick={agregarDeposito}>
                                                        <PlusOutlined /> Depósito
                                                    </Button>
                                            </Col>
                                        </>
                                    
                        
                            }

                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Form.Item >
                                    <Button type="primary" htmlType="submit">
                                        {id_actualizar === 0 ?
                                            'Crear'
                                        : 'Guardar'
                                        }
                                    </Button>
                                </Form.Item>
                            </Col>


                        </Row>
                    </Form>
                }
            </Col>
        </Row>
    );

}

export default RegistrarMovimientoN;