import React from 'react';
import CanvasJSReact from '../../../../assets/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Barras extends React.Component {

    constructor(props){
        super(props);
        
        this.state = {
            options : {}
        };

    }

    componentWillReceiveProps(nextProps){
        
        console.log('las props: ',nextProps);

        let datos_tabla = [];

        let columna = '';
        switch(nextProps['operacion']){
            case '0':
                columna = 'ingreso';
                break;
            case '1':
                columna = 'egreso';
                break;
            case '2':
                columna = 'ingreso-egreso';
                break;
                case '3':
                columna = 'proyectado';
                break;
            case '4':
                columna = 'ingreso-proyectado';
                break;
            case '5':
                columna = 'proyectado-egreso';
                break;
            default:
                break;
        }

        let num_selecciones = 0;
        let nombre_select = '';
        for (const prop in nextProps.datos[0]) {

            if(prop !== 'ingreso' && prop !== 'egreso' && prop !== 'proyectado'
                && prop !== 'ingreso-egreso' && prop !== 'ingreso-proyectado' && prop !== 'proyectado-egreso'
                && prop !== 'color'){
                num_selecciones++;
                nombre_select = prop;
            }
        }

        if(num_selecciones === 1){
            for(let i=0; i < nextProps['datos'].length; i++){
                datos_tabla.push({label:nextProps['datos'][i][nombre_select], y: nextProps['datos'][i][columna], color: nextProps['colores'][i]});
            }
        }else{
            for(let i=0; i < nextProps['datos'].length; i++){
                datos_tabla.push({label:(i+1), y: nextProps['datos'][i][columna], color: nextProps['colores'][i]});
            }
        }

        

        let options = {
            title: {
                text: nextProps['nombre']
            },
            data:[
                    {			
                        type: "column",
                        dataPoints: datos_tabla
                    }
            ]
        }

        this.setState({
            options: options
        });

    }

    render(){

        return(
            <div>
                <CanvasJSChart options = {this.state.options} />
            </div>
        );
    }

}

export default Barras;
