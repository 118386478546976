import React from 'react';
import { Row, Col, Table, Input, InputNumber, Popover, Popconfirm, Form, notification, Button, Select, Drawer, Typography, Upload, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { Redirect, Link } from 'react-router-dom';
import  RegistrarMovimientoN from '../proyectos/RegistrarMovimientoN';
import { SearchOutlined, PlusCircleOutlined, UploadOutlined, FrownOutlined  } from '@ant-design/icons';

import { SERVER_URL , movimientos, catalogos, editar_movimiento, movimiento, eliminar_movimiento, mis_movimientos, lista_tickets_reembolsos } from '../../../utils/Jwt';

import '../../../css/lista_movimientos.css';
import Solicitud from '../proceso_movimiento/Solicitud';
import Comprobacion from '../proceso_movimiento/Comprobacion';
import Reembolso from '../proceso_movimiento/Reembolso';
import Completo from '../proceso_movimiento/Completo';
import MovimientosN from './MovimientosN';

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

let tipo_operaciones = [], conceptos = [], gastos_concepto = [], formas_de_pago = [], proyectos= [], empresas = [], id_movimiento, deshabilitar_gastos_concepto = [];

let permiso_editar_movimientos = false, permiso_movimientos = false, permiso_ingresos = false;

let permiso_ver_egresos = false, permiso_ver_ingresos = false, permiso_ver_proyecciones = false;
let url_ticket = '', url_factura = '';
let img_src = '';

  function infoImg(src) {

    img_src = src;
    Modal.info({
      title: '',
      content: (
        <div>
          <img src={img_src} style={{width:'100%'}} />
        </div>
      ),
      onOk() {},
      width:"500px"
    });
  }

class ListaMovimiento extends React.Component{

    constructor(props){
        super(props);

        this.state = { 
            data: [[],[]],
            login: false, 
            searchText: '',
            searchedColumn: '',
            drawer_editar_movimiento: false,
            movimiento_editar: {},
            habilitar_gasto_concepto:false,
            existe_ticket: false,
            existe_factura: false,
            visual_imagen: false,
            visualizar_registrar: false,
            mostrar_ticket_reembolso: false,
            registro_tickets_reembolsos: [],
            modal_proceso_movimiento: false,
            datos_proceso_movimiento: null,
            tipo_vista_movimientos:'Folio',
        }

        permiso_editar_movimientos = false;
        permiso_movimientos = false;
        permiso_ver_egresos = false;
        permiso_ver_ingresos = false;
        permiso_ver_proyecciones = false;

        for(let i=0; i < this.props.permisos_usuario.length; i++){

            if(this.props.permisos_usuario[i].id_permiso === 1){
                permiso_ingresos = true;
            }

            if(this.props.permisos_usuario[i].id_permiso === 3){
                permiso_movimientos = true;
            }

            if(this.props.permisos_usuario[i].id_permiso === 6){
                permiso_editar_movimientos = true;
            }
        }

        this.listaMovimiento();
        this.listaCatalogos();

        this.handleChangeConcepto = this.handleChangeConcepto.bind(this);
        this.handleChangeFactura = this.handleChangeFactura.bind(this);
        this.handleChangeTicket = this.handleChangeTicket.bind(this);
        this.registrarMovimiento = this.registrarMovimiento.bind(this);
        this.seRegistroMovimiento = this.seRegistroMovimiento.bind(this);
        this.listaTicketsReembolsos = this.listaTicketsReembolsos.bind(this);
        this.mostrarProcesoMovimiento = this.mostrarProcesoMovimiento.bind(this);

    }

    listaTicketsReembolsos(id){
        lista_tickets_reembolsos(id).then(data => {
            switch(data.status){
                case 0:
                    this.setState({mostrar_ticket_reembolso: true, registro_tickets_reembolsos: data.data});
                break;
            }
        });
    }

    mostrarProcesoMovimiento(record){
        this.setState({datos_proceso_movimiento: record, modal_proceso_movimiento: true});
    }

    seRegistroMovimiento(response){
        this.onClose();
        this.listaMovimiento();
    }

    hide = () => {
        this.setState({
          visual_imagen: false,
        });
      };
    
      handleVisibleChange = visual_imagen => {
        this.setState({ visual_imagen });
      };

    handleChangeFactura(value){
        switch(value){
            case 'Si':
                this.setState({existe_factura: true});
            break;
            default:
                this.setState({existe_factura: false});
                break;
        }
    }

    handleChangeTicket(value){
        switch(value){
            case 'Si':
                this.setState({existe_ticket: true});
            break;
            default:
                this.setState({existe_ticket: false});
                break;
        }
    }

    onClose = () => {
        this.setState({
          drawer_editar_movimiento: false,
          drawer_editar_mis_movimiento: false,
          visual_imagen: false,
          visualizar_registrar: false
        });
    };

    movimientoGuardado(response){
        switch(response){
            case 1:
                this.setState({tipo_vista_movimientos:'Ingreso'});
                break;
            case 2:case 3: case 5:
                this.setState({tipo_vista_movimientos:'Folio'});
                break;
            case 4:
                this.setState({tipo_vista_movimientos:'Proyectado'});
                break;
            default:
                break;
        }
        this.movientosProyecto();
        this.onClose();
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Buscar ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Limpiar
            </Button>
          </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });

      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };

      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };

    listaCatalogos(){
        catalogos().then(data => {

            switch(data.status){
                case 0:
                    tipo_operaciones = data.data[0].map(operacion => {
                        return(
                            <Option value={operacion.id_tipo_movimiento}>{operacion.tipo}</Option>
                        );
                    });
                    conceptos = data.data[1].map(concepto => {
                        return(
                            <Option value={concepto.id_concepto}>{concepto.nombre}</Option>
                        );
                    });
                    deshabilitar_gastos_concepto = data.data[2];
                    formas_de_pago = data.data[3].map(forma => {
                        return(
                            <Option value={forma.id_forma_de_pago}>{forma.tipo}</Option>
                        );
                    });
                    proyectos = data.data[4].map(proyecto => {
                        return(
                            <Option value={proyecto.id_proyecto}>{proyecto.nombre}</Option>
                        );
                    });;
                    empresas = data.data[5].map(empresa => {
                        return(
                            <Option value={empresa.id_empresa}>{empresa.nombre}</Option>
                        );
                    });

                    this.setState({
                        catalogos:true
                    });
                break;
                default:
                break;
            }
        });
    }

    listaMovimiento(){

        if(permiso_movimientos){
            movimientos().then(data => {
                switch(data.status){
                    case 0:
                        let mov = [];
                        mov = data.data;
                        this.setState({data:mov});
                    break;
                    case 5:
                        this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                        this.setState({login:true});
                    break;
                    default:
                        this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
                        this.setState({login:true});
                    break;
                }
            });
        }else{
            mis_movimientos().then(data => {
                switch(data.status){
                    case 0:
                        let mov = [];
                        mov = data.data;
                        this.setState({data:mov});
                    break;
                    case 5:
                        this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                        this.setState({login:true});
                    break;
                    default:
                        this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
                        this.setState({login:true});
                    break;
                }
            });
        }

    }

    openNotification = (icono, titulo, mensaje) => {
        notification.open({
        message: titulo,
        description:
            mensaje,
        icon: icono,
        });
    };

    edit(id){
        id_movimiento = id;
        movimiento(id).then(data => {
            switch(data.status){
                case 0:
                    this.setState({movimiento_editar: data.data, drawer_editar_movimiento:true, existe_ticket:true, existe_factura:true});
                    url_factura = data.data.url_factura;
                    url_ticket = data.data.url_ticket;
                break;
                case 5:
                    this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
            }
        });

    }

    editMisMovimientos(id){
        id_movimiento = id;
        movimiento(id).then(data => {
            switch(data.status){
                case 0:
                    this.setState({movimiento_editar: data.data, drawer_editar_mis_movimiento:true, existe_ticket:true, existe_factura:true});
                    url_factura = data.data.url_factura;
                    url_ticket = data.data.url_ticket;
                break;
                case 5:
                    this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
            }
        });

    }

    delete(id){
        eliminar_movimiento(id).then(data => {
            switch(data.status){
                case 0:
                    this.openNotification('smile','#73fc03','Movimiento Eliminado','');
                    this.listaMovimiento();
                break;
                case 5:
                    this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

            values['url_ticket'] = url_ticket;
            values['url_factura'] = url_factura;

            editar_movimiento(values, id_movimiento).then(data => {
                switch(data.status){
                    case 0:
                        this.openNotification('smile','#73fc03','Cambio exitoso','');
                        this.setState({drawer_editar_movimiento:false});
                        this.listaMovimiento();
                    break;
                    case 5:
                    this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
                }
            });

            }
        });
    };

    handleChangeConcepto(value) {
            
            gastos_concepto = deshabilitar_gastos_concepto.map(gasto => {
            if(gasto.concepto_id === value){
                return(
                    <Option className="opcion_gasto_concepto" data-id-concepto={gasto.concepto_id} value={gasto.id_gasto_concepto}>{gasto.nombre}</Option>
                );
            }else{
                return(
                    <Option className="opcion_gasto_concepto" data-id-concepto={gasto.concepto_id} value={gasto.id_gasto_concepto} style={{display:'none'}}>{gasto.nombre}</Option>
                );
            }

        });    
    
        this.setState({
            habilitar_gasto_concepto: true
        });
    }

    registrarMovimiento(){
        this.setState({
            visualizar_registrar: true
        });
    }

    render(){

        /*if(!permiso_movimientos){
            return(
                <Redirect to="/usuario" />
            );
        }*/

        let lista_movimientos = [];
        let lista_submovimientos = [];

        switch(this.state.tipo_vista_movimientos){
            case 'Ingreso':
                lista_movimientos = this.state.data[0].filter(movimiento => movimiento.tipo_operacion === 'INGRESO' && movimiento.especificar_tipo_operacion === 'Ingreso');
                lista_submovimientos = [];
                break;
            case 'Folio':
                console.log(this.state.data[0]);
                lista_submovimientos = this.state.data[0].filter(movimiento => movimiento.tipo_operacion === 'EGRESO' ||  movimiento.tipo_operacion === 'SOLICITUD' || movimiento.especificar_tipo_operacion === 'Deposito').map((movimiento,index) => { movimiento.key = index; return movimiento });
                lista_movimientos = this.state.data[1].map((folio,index) => { folio.key = index; return folio });
                break;
            default:
                lista_movimientos = this.state.data[1];
                break;
        }
        

        return(
            <Row>
                <Col span={22} offset={1}>
                    <p><strong>MOVIMIENTOS</strong></p>
                        <p style={{textAlign:'right'}}><Button type="primary" onClick={this.registrarMovimiento} ><PlusCircleOutlined /> Movimiento</Button></p>

                        <Select defaultValue={this.state.tipo_vista_movimientos} onChange={val => this.setState({tipo_vista_movimientos: val})} style={{width:'100%'}}>
                            {permiso_ingresos && <Option value={"Ingreso"}>Ingreso</Option>}
                            <Option value={"Folio"}>Folio</Option>
                        </Select>
                    <MovimientosN lista_movimientos={lista_movimientos} lista_submovimientos={lista_submovimientos} tipo_vista_movimientos={this.state.tipo_vista_movimientos} id_proyecto={0}  onResponse={this.seRegistroMovimiento}  editar_movimientos={permiso_editar_movimientos} aprobar={permiso_editar_movimientos} habilitar_depositos={permiso_editar_movimientos} aprobar={permiso_editar_movimientos} />
                </Col>
                <Col span={22} offset={1}>
                    <Drawer
                        title="Registrar Movimiento"
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visualizar_registrar}
                        width="600px"
                        >
                            {this.state.visualizar_registrar &&
                                <RegistrarMovimientoN onResponse={this.seRegistroMovimiento} id_proyecto={0} ingreso={permiso_ingresos} proyectado={false} comprobar={false} actualizar_operacion={0} id_actualizar={0} />
                            }
                    </Drawer>
                </Col>
                <Modal title="Ticket Reembolso" 
                visible={false} 
                onOk={()=>{this.setState({mostrar_ticket_reembolso: false});}} 
                onCancel={()=>{this.setState({mostrar_ticket_reembolso: false});}}>
                    
                    {this.state.registro_tickets_reembolsos.map((ticket,index) => {
                        if(index === 0){
                            return(
                                <Row>
                                    <Col span={24}>
                                    <p style={{textAlign:'center', fontWeight:'bold'}}>{ticket.nombre_ticket}</p>
                                    </Col>
                                    <Col span={4}>
                                        <p><strong>Fecha</strong></p>
                                    </Col>
                                    <Col span={4}><p><strong>Cantidad</strong></p></Col>
                                    <Col span={16}><p><strong>Observaciones</strong></p></Col>
                                    <Col span={4}>
                                        <p>{ticket.fecha}</p>
                                    </Col>
                                    <Col span={4}><p>{ticket.cantidad}</p></Col>
                                    <Col span={16}><p>{ticket.observaciones}</p></Col>
                                </Row>
                            );
                        }

                        if(index === this.state.registro_tickets_reembolsos.length -1){
                            return(
                                <Row>
                                    <Col span={4}>
                                    <p>{ticket.fecha}</p>
                                </Col>
                                <Col span={4}><p>{ticket.cantidad}</p></Col>
                                <Col span={16}><p>{ticket.observaciones}</p></Col>
                                    <Col span={24}>
                                        <p style={{textAlign:'right'}}>
                                            <strong>
                                                Total: ${this.state.registro_tickets_reembolsos.reduce((a, b) => a + b.cantidad,0)}
                                            </strong>
                                        </p>
                                    </Col>
                                    
                                </Row>
                            );
                        }

                        return(
                            <Row>
                                <Col span={4}>
                                    <p>{ticket.fecha}</p>
                                </Col>
                                <Col span={4}><p>{ticket.cantidad}</p></Col>
                                <Col span={16}><p>{ticket.observaciones}</p></Col>
                            </Row>
                        );
                    })
                    
                    }
                </Modal>

                <Modal title={this.state.datos_proceso_movimiento ? this.state.datos_proceso_movimiento.status : ''} 
                    visible={this.state.modal_proceso_movimiento}
                    onOk={()=>{this.setState({modal_proceso_movimiento: false});}} 
                    onCancel={()=>{this.setState({modal_proceso_movimiento: false});}}>
                        {this.state.datos_proceso_movimiento &&
                            (
                                (this.state.datos_proceso_movimiento.status === 'Solicitud' && this.state.datos_proceso_movimiento.especificar_tipo_operacion === 'Solicitud') ?
                                    <Solicitud movimiento={this.state.datos_proceso_movimiento} />
                                :
                                (this.state.datos_proceso_movimiento.status === 'Solicitud' && this.state.datos_proceso_movimiento.especificar_tipo_operacion === 'Reembolso') ?
                                    <Reembolso movimiento={this.state.datos_proceso_movimiento} />
                                :
                                (this.state.datos_proceso_movimiento.status === 'Por comprobar') ?
                                    <Comprobacion movimiento={this.state.datos_proceso_movimiento} />
                                :
                                <Completo />
                            )
                        }
                </Modal>

            </Row>
        );
    }
}

//const WrappedRegistrationForm = Form.create({ name: 'register' })(ListaMovimiento);

export default ListaMovimiento;
