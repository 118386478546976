import React from 'react';
import {Row, Col} from 'antd';
import { Document, Page, View, Text, StyleSheet, PDFDownloadLink, Image, BlobProvider } from '@react-pdf/renderer';

const Reembolso = (props) => {
    const { nota, reembolsos } = props.datos;

    console.log(nota, reembolsos);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });

    //612.0, 792.0

    const styles = StyleSheet.create({
        page: {},
        section_flex: {display:'flex'},
        section: {width:'590px', color: 'black', textAlign: 'center', margin:'21px', flexDirection: 'row'},

        section_1:{width:'100px', marginLeft:'20px', marginRight:'10px', flexDirection: 'column'},
        section_2:{width:'150px', marginLeft:'20px', marginRight:'10px', flexDirection: 'column'},
        section_3:{width:'170px', marginLeft:'20px', marginRight:'10px', flexDirection: 'column'},

        section_left: {width:'170px', marginLeft:'20px', marginRight:'10px', flexDirection: 'column'},
        section_right: {width:'382px', marginLeft:'10px', marginRight:'20px', flexDirection: 'column'},
        section_right_linea:{width:'342px', marginLeft:'10px', marginRight:'20px', flexDirection: 'column'},
        titulo: {textAlign:'center'},
        info: {textAlign:'left'},
        campo: { fontWeight:'bold', textAlign:'left'},
        valor: { textAlign:'left' },
        linea: { backgroundColor:'black', fontSize:'2', marginTop:'15px' }
      });

      const document =  
        <Document>
            <Page size="LETTER" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>
                            Nº - {10000+nota.id_nota}
                        </Text>
                    </View>
                    <View style={styles.section_right}>
                        <Text style={styles.campo}>
                            Solicitud de Reembolso
                        </Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Consultor</Text>
                    </View>
                    <View style={styles.section_right}>
                        <Text style={styles.valor}>
                            {nota.nombre+' '+nota.apellidos}
                        </Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Proyecto</Text>
                    </View>
                    <View style={styles.section_right}>
                        <Text style={styles.valor}>
                            {nota.proyecto}
                        </Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Fecha inicio</Text>
                    </View>
                    <View style={styles.section_right}>
                        <Text style={styles.valor}>
                            {nota.fecha_inicio}
                        </Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Fecha fin</Text>
                    </View>
                    <View style={styles.section_right}>
                        <Text style={styles.valor}>
                            {nota.fecha_fin}
                        </Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.titulo}>Con factura</Text>
                </View>
                <View style={styles.section}>
                    {reembolsos.filter(reembolso => reembolso.tipo_comprobante === 'Factura').map(reembolso => 
                        <>
                                <View style={styles.columna1}>
                                    <Text style={styles.campo}>{reembolso.concepto}</Text>
                                </View>
                                <View style={styles.columna2}>
                                    <Text style={styles.campo}>{reembolso.concepto}</Text>
                                </View>
                                <View style={styles.columna3}>
                                    <Text style={styles.campo}>{formatter.format(reembolso.cantidad)}</Text>
                                </View>
                            </>
                    )
                    }
                </View>
                <View style={styles.section}>
                    <Text style={styles.titulo}>Sin factura</Text>
                </View>
                <View style={styles.section}>
                    {reembolsos.filter(reembolso => reembolso.tipo_comprobante !== 'Factura').map(reembolso => 
                        <>
                                <View style={styles.columna1}>
                                    <Text style={styles.campo}>{reembolso.concepto}</Text>
                                </View>
                                <View style={styles.columna2}>
                                    <Text style={styles.campo}>{reembolso.concepto}</Text>
                                </View>
                                <View style={styles.columna3}>
                                    <Text style={styles.campo}>{formatter.format(reembolso.cantidad)}</Text>
                                </View>
                            </>
                    )
                    }
                </View>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Observaciones</Text>
                    </View>
                    <View style={styles.section_right}>
                        <Text style={styles.valor}>
                            {nota.descripcion_nota}
                        </Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Total</Text>
                    </View>
                    <View style={styles.section_right}>
                        <Text style={styles.valor}>
                            {formatter.format(nota.resto)}
                        </Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Recibi:</Text>
                    </View>
                    <View style={styles.section_right_linea}>
                        <Text style={styles.linea}>
                            asa
                        </Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Fecha:</Text>
                    </View>
                    <View style={styles.section_right_linea}>
                    <Text style={styles.linea}>
                            asa
                        </Text>
                    </View>
                </View>
                
            </Page>
        </Document>;

    return(
        <Row>
            {/*<Col span={24} style={{textAlign:'right'}}>
            
            <PDFDownloadLink document={document} fileName="solicitud.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Cargando...' : 'Descargar!')}
            </PDFDownloadLink>
            </Col>*/}
            
            <Col span={20}>
                <div style={styles.section}>
                    
                <p style={styles.info}>
                    <span style={styles.campo}>Nº-</span> <span style={styles.valor}>{10000+nota.id_nota}</span>
                </p>
                
                <p style={styles.info}>
                    <span style={styles.campo}>Consultor: </span>
                    <span style={styles.valor}> {nota.nombre+' '+nota.apellidos}</span>
                </p>
                <p style={styles.info}>
                    <span style={styles.campo}>Proyecto: </span>
                    <span style={styles.valor}> {nota.proyecto}</span>
                </p>
                <p style={styles.info}>
                    <span style={styles.campo}>Fecha inicio: </span>
                    <span style={styles.valor}>{nota.fecha_inicio}</span>
                </p>
                <p style={styles.info}>
                    <span style={styles.campo}>Fecha fin: </span>
                    <span style={styles.valor}>{nota.fecha_fin}</span>
                </p>
                <p style={styles.titulo}>
                    <span>Con factura</span>
                    <table>
                        <tr>
                            <th style={{width:'100px'}} >&nbsp;</th>
                            <th>Fecha</th>
                            <th style={{width:'20px'}} >&nbsp;</th>
                            <th>Concepto</th>
                            <th style={{width:'20px'}} >&nbsp;</th>
                            <th>Importe</th>
                        </tr>
                        {reembolsos.filter(reembolso => reembolso.tipo_comprobante === 'Factura').map(reembolso => 
                            <tr>
                                <th style={{width:'100px'}} >&nbsp;</th>
                                <th>{reembolso.fecha}</th>
                                <th style={{width:'20px'}} >&nbsp;</th>
                                <th>{reembolso.concepto}</th>
                                <th style={{width:'20px'}} >&nbsp;</th>
                                <th>{formatter.format(reembolso.cantidad)}</th>
                            </tr>
                        )}
                            <tr >
                                <th style={{width:'100px'}} >&nbsp;</th>
                                <th></th>
                                <th style={{width:'20px'}} >&nbsp;</th>
                                <th style={{borderTop:'1px solid black'}}>Importe total</th>
                                <th style={{width:'20px', borderTop:'1px solid black'}} >&nbsp;</th>
                                <th style={{borderTop:'1px solid black'}}>{formatter.format(reembolsos.filter(reembolso => reembolso.tipo_comprobante === 'Factura').reduce((a, b) => a + b.cantidad,0))}</th>
                            </tr>
                    </table>
                </p>
                <p style={styles.titulo}>
                    <span>Sin factura</span>
                    <table>
                        <tr>
                            <th style={{width:'100px'}} >&nbsp;</th>
                            <th>Fecha</th>
                            <th style={{width:'20px'}} >&nbsp;</th>
                            <th>Concepto</th>
                            <th style={{width:'20px'}} >&nbsp;</th>
                            <th>Importe</th>
                        </tr>
                        {reembolsos.filter(reembolso => reembolso.tipo_comprobante !== 'Factura').map(reembolso => 
                            <tr>
                                <th style={{width:'100px'}} >&nbsp;</th>
                                <th>{reembolso.fecha}</th>
                                <th style={{width:'20px'}} >&nbsp;</th>
                                <th>{reembolso.concepto}</th>
                                <th style={{width:'20px'}} >&nbsp;</th>
                                <th>{formatter.format(reembolso.cantidad)}</th>
                            </tr>
                        )}
                        <tr>
                                <th style={{width:'100px'}} >&nbsp;</th>
                                <th></th>
                                <th style={{width:'20px'}} >&nbsp;</th>
                                <th style={{borderTop:'1px solid black'}}>Importe total</th>
                                <th style={{width:'20px', borderTop:'1px solid black'}} >&nbsp;</th>
                                <th style={{borderTop:'1px solid black'}}>{formatter.format(reembolsos.filter(reembolso => reembolso.tipo_comprobante !== 'Factura').reduce((a, b) => a + b.cantidad,0))}</th>
                            </tr>
                    </table>
                </p>
                <p style={styles.info}>
                        <span style={styles.campo}>Total: </span>
                        <span style={styles.valor}>{formatter.format(nota.comprobado)}</span>
                </p>
                <p style={styles.info}>
                        <span style={styles.campo}>Observaciones: </span>
                        
                        <span style={styles.valor}>{nota.descripcion_nota}</span>
                </p>
                </div>
            </Col>
        </Row>
    );

}

export default Reembolso;
