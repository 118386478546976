import React from 'react'; 
import { Redirect } from 'react-router-dom';

export const CerrarSesion = () => {

    localStorage.clear();

    return(
        <Redirect to="/" />
    );
}