import React from 'react';
import {Row, Col} from 'antd';
import { Document, Page, View, Text, StyleSheet, PDFDownloadLink, Image, BlobProvider, PDFViewer } from '@react-pdf/renderer';

const Solicitud = (props) => {
    const {nota, solicitudes} = props.datos;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });

    const styles = StyleSheet.create({
        page: {},
        section_flex: {display:'flex'},
        section: {width:'590px', color: 'black', textAlign: 'center', margin:'21px', flexDirection: 'row'},
        section_1:{width:'100px', marginLeft:'20px', marginRight:'10px', flexDirection: 'column'},
        section_2:{width:'150px', marginLeft:'20px', marginRight:'10px', flexDirection: 'column'},
        section_3:{width:'170px', marginLeft:'20px', marginRight:'10px', flexDirection: 'column'},
        section_left_datos: {width:'120px', marginLeft:'20px', marginRight:'10px', flexDirection: 'column', padding:'0px', marginTop:'0px', marginBottom:'0px'},
        section_right_datos: {width:'432px', marginLeft:'10px', marginRight:'20px', flexDirection: 'column', padding:'0px', marginTop:'0px', marginBottom:'0px'},
        section_left: {width:'170px', marginLeft:'20px', marginRight:'10px', flexDirection: 'column', padding:'0px', marginTop:'0px', marginBottom:'0px'},
        section_right: {width:'382px', marginLeft:'10px', marginRight:'20px', flexDirection: 'column', padding:'0px', marginTop:'0px', marginBottom:'0px'},
        section_right_linea:{width:'342px', marginLeft:'10px', marginRight:'20px', flexDirection: 'column'},
        titulo: {textAlign:'center'},
        info: {textAlign:'left'},
        campo: { fontWeight:'bold', textAlign:'left', fontSize:'12pt', margin:'0px', padding:'0px'},
        valor: { textAlign:'left', fontSize:'12pt', margin:'0px', padding:'0px' },
        linea: { backgroundColor:'black', fontSize:'2', marginTop:'15px' }
      });

      const document =  
        <Document>
            <Page size="LETTER" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Folio</Text>
                        <Text style={styles.campo}>Consultor</Text>
                        <Text style={styles.campo}>Proyecto</Text>
                        <Text style={styles.campo}>Fecha inicio</Text>
                        <Text style={styles.campo}>Fecha fin</Text>
                    </View>
                    <View style={styles.section_right}>
                        <Text style={styles.campo}>{10000+nota.id_nota}</Text>
                        <Text style={styles.campo}>{nota.nombre+' '+nota.apellidos}</Text>
                        <Text style={styles.valor}>{nota.proyecto}</Text>
                        <Text style={styles.valor}>{nota.fecha_inicio}</Text>
                        <Text style={styles.valor}>{nota.fecha_fin}</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.titulo}>Monto solicitado</Text>
                </View>
                <View style={styles.section}>
                    <View style={styles.columna1}>
                        <Text style={styles.campo}>Concepto</Text>
                    </View>
                    <View style={styles.columna2}>
                        <Text style={styles.campo}>Importe</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    {solicitudes.map(solicitud => 
                        <>
                                <View style={styles.columna1}>
                                    <Text style={styles.campo}>{solicitud.concepto}</Text>
                                </View>
                                <View style={styles.columna2}>
                                    <Text style={styles.campo}>{formatter.format(solicitud.cantidad)}</Text>
                                </View>
                            </>
                    )
                    }
                </View>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Observaciones</Text>
                    </View>
                    <View style={styles.section_right}>
                        <Text style={styles.valor}>
                            {nota.descripcion_nota}
                        </Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Recibi:</Text>
                    </View>
                    <View style={styles.section_right_linea}>
                        <Text style={styles.linea}>
                            asa
                        </Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.section_left}>
                        <Text style={styles.campo}>Fecha:</Text>
                    </View>
                    <View style={styles.section_right_linea}>
                    <Text style={styles.linea}>
                            asa
                        </Text>
                    </View>
                </View>
                
            </Page>
        </Document>;

    return(
        <Row>
            {/*<Col span={24}>
                <PDFViewer>
                {document}
                </PDFViewer>
            </Col>
            <Col span={24} style={{textAlign:'right'}}>
            
            <PDFDownloadLink document={document} fileName="Solicitud.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Cargando...' : 'Descargar!')}
            </PDFDownloadLink>
            </Col>*/}
            <Col span={20}>
                <div style={styles.section}>
                    
                <p style={styles.info}>
                    <span style={styles.campo}>Nº-</span> <span style={styles.valor}>{10000+nota.id_nota}</span>
                </p>
                <p style={styles.info}>
                    <span style={styles.campo}>Consultor:</span> 
                    <span style={styles.valor}> {nota.nombre+' '+nota.apellidos}</span>
                </p>
                <p style={styles.info}>
                    <span style={styles.campo}>Proyecto:</span>
                    <span style={styles.valor}> {nota.proyecto}</span>
                </p>
                <p style={styles.info}>
                    <span style={styles.campo}>Fecha inicio:</span>
                    <span style={styles.valor}> {nota.fecha_inicio}</span>
                </p>
                <p style={styles.info}>
                    <span style={styles.campo}>Fecha fin:</span>
                    <span style={styles.valor}> {nota.fecha_fin}</span>
                </p>
                <p style={styles.titulo}>
                    <span style={styles.campo}>Monto solicitado</span>
                </p>
                <p style={styles.titulo}>
                    <table>
                        <tr>
                            <th style={{width:'150px'}} >&nbsp;</th>
                            <th>Concepto</th>
                            <th style={{width:'50px'}} >&nbsp;</th>
                            <th>Importe</th>
                        </tr>
                        {solicitudes.filter(solicitud => solicitud.concepto !== 'Depositos').map(solicitud => 
                            <tr>
                                <th style={{width:'150px'}} >&nbsp;</th>
                                <td>{solicitud.concepto}</td>
                                <th style={{width:'50px'}} >&nbsp;</th>
                                <td>{formatter.format(solicitud.cantidad)}</td>
                            </tr>
                        )}
                    </table>
                </p>
                <p style={styles.info}>
                    <table>
                        <tr>
                            <th >Importe total:</th>
                            <td>{formatter.format(solicitudes.filter(solicitud => solicitud.concepto !== 'Depositos').reduce((a, b) => a + b.cantidad,0))}</td>
                        </tr>
                        <tr>
                            <th>Observaciones: </th>
                            <td> {nota.descripcion_nota}</td>
                        </tr>
                        
                    </table>
                </p>
                </div>
            </Col>
        </Row>
    );

}

export default Solicitud;
