import React from 'react';
import { Row, Col, Table, Input, InputNumber, Popconfirm, Form, notification, Tag, Button, Modal, Select } from 'antd';
import { Redirect } from 'react-router-dom';
import { CloseSquareOutlined, SmileOutlined, FrownOutlined } from '@ant-design/icons';

import { datos_usuarios, editar_datos_usuario, eliminar_personal_permiso, agregar_personal_permisos, permisos, eliminar_personal } from '../../../utils/Jwt';
import ListaUsuarioN from './ListaUsuarioN';

const { Option } = Select;
let opciones_permisos, permisos_registrados = [], permisos_para_agregar = [], id_personal;
let permiso_ver_usuarios = false, permiso_editar_usuarios = false; let permiso_registrar_usuarios = false;
const EditableContext = React.createContext();
class EditableCell extends React.Component {
  getInput = (val) => {
    if (this.props.inputType === 'number') {
      return <InputNumber defaultValue={val} />;
    }
    return <Input defaultValue={val} />;
  };

  renderCell = () => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}
          name={dataIndex}
          initialValue={record[dataIndex]}
          defaultValue={record[dataIndex]}
          >
            {this.getInput(record[dataIndex])}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

const openNotification = (icono, titulo,mensaje) => {
  notification.open({
    message: titulo,
    description:
      mensaje,
    icon: icono,
  });
};

class ListaUsuario extends React.Component{

    constructor(props) {
        super(props);
        this.state = { data: [],login: false,editingKey: '', modal_agregar_permisos: false, permisos_registrados: false };

        this.valorDefaultPermisos = React.createRef();

        for(let i=0; i < this.props.permisos_usuario.length; i++){

          if(this.props.permisos_usuario[i].id_permiso === 8){
            permiso_editar_usuarios = true;
            break;
          }else{
            permiso_editar_usuarios = false;
          }
        }  

        for(let i=0; i < this.props.permisos_usuario.length; i++){
          if(this.props.permisos_usuario[i].id_permiso === 7){
            permiso_ver_usuarios = true;
            break;
          }else{
            permiso_ver_usuarios = false;
          }
        }

        for(let i=0; i < this.props.permisos_usuario.length; i++){
          if(this.props.permisos_usuario[i].id_permiso === 9){
            permiso_registrar_usuarios = true;
            break;
          }else{
            permiso_registrar_usuarios = false;
          }
        }

        if(permiso_ver_usuarios){
            this.listaUsuario();
            this.listaPermisos();
        }

    }

    handleChangePermisos(value) {
      permisos_para_agregar = value;
    }

    showModalPermisos = (registro) => {

      permisos_registrados = [];
      id_personal = registro.id_personal;

      if(registro.hasOwnProperty('permisos')){
        for(let i = 0; i < registro.permisos.length; i++){
          permisos_registrados.push(registro.permisos[i].props['data-id-permiso']);
        }
      }
    
      this.setState({
        modal_agregar_permisos: true,
        permisos_registrados:true
      });

    };
  
    handleOkPermisos = e => {
      
      agregar_personal_permisos({id: id_personal, permisos: permisos_para_agregar}).then(data => {
        
        switch(data.status){
          case 0:
            openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Permisos Agregados','');
            this.listaUsuario();
            break;
          case 5:
            openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
            this.setState({login:true});
          break;
          default:
            openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
            this.setState({login:true});
          break;
        }
      });

      this.setState({
        modal_agregar_permisos: false,
        permisos_registrados: false
      });

    };

    handleCancel = e => {
      
      this.setState({
        modal_agregar_permisos: false,
        permisos_registrados: false
      });
    };

    listaPermisos(){

      permisos().then(data => {
        switch(data.status){
          case 0:
            opciones_permisos = data.data.map(permiso => {
              return(
                <Option value={permiso.id_permiso} label={permiso.tipo}>
                  <span role="img" aria-label={permiso.tipo} style={{backgroundColor:permiso.color}}>
                    🇨&nbsp;
                  </span>
                  {permiso.tipo}
                </Option>
              );
            });

          break;
          case 5:
            openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
            this.setState({login:true});
          break;
          default:                
            openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
            this.setState({login:true});
          break;
        }
      });
      
    }

    listaUsuario(){
        datos_usuarios().then(data => {
            switch(data.status){
                case 0:
                    let usuarios = [];
                    let usuario_permisos = [];
                    let id_usuario_actual = 0;
                    let permisos_nombre = [];

                    for(let i=0; i < data.data.length; i++){
                        if(i===0){
                            id_usuario_actual = data.data[i]['id_personal'];
                        }

                        if(id_usuario_actual === data.data[i]['id_personal']){
                            permisos_nombre.push(data.data[i]['tipo']);
                            usuario_permisos.push({
                                id_personal_permiso: data.data[i]['id_personal_permiso'],  
                                id_permiso: data.data[i]['id_permiso'],
                                tipo: data.data[i]['tipo'],
                                color: data.data[i]['color']
                            });
                        }else{
                            id_usuario_actual = data.data[i]['id_personal'];
                            i--;
                            if(usuario_permisos[0]['id_personal_permiso'] !== null){
                              data.data[i]['nombre_permisos'] = permisos_nombre.toString();
                              data.data[i]['permisos'] = usuario_permisos.map(permiso => {
                                if(permiso_editar_usuarios){
                                  return(
                                    <Popconfirm title="¿Quieres eliminar éste permiso?" data-id={permiso['id_personal_permiso']} data-id-permiso={permiso['id_permiso']} onConfirm={this.borrarPermiso(permiso['id_personal_permiso'])} okText="Sí" cancelText="No" oktype="danger">
                                      <Tag style={{color:'black', border:'2px solid '+permiso['color']}}>{permiso['tipo']} <CloseSquareOutlined /></Tag>
                                    </Popconfirm>
                                  );
                                }else{
                                  return(
                                      <Tag style={{color:'black', border:'2px solid '+permiso['color']}}>{permiso['tipo']}</Tag>
                                  );
                                }
                              });
                            }
                            usuarios.push(data.data[i]);
                            usuario_permisos = [];
                            permisos_nombre = [];
                        }

                        if(i === data.data.length - 1 ){
                          if(usuario_permisos[0]['id_personal_permiso'] !== null){
                            data.data[i]['nombre_permisos'] = permisos_nombre.toString();
                            data.data[i]['permisos'] = usuario_permisos.map(permiso => {
                              if(permiso_editar_usuarios){
                                return(
                                  <Popconfirm title="¿Quieres eliminar éste permiso?" data-id={permiso['id_personal_permiso']} data-id-permiso={permiso['id_permiso']} onConfirm={this.borrarPermiso(permiso['id_personal_permiso'])} okText="Sí" cancelText="No" oktype="danger">
                                    <Tag style={{color:'black', border:'2px solid '+permiso['color']}}>{permiso['tipo']} <CloseSquareOutlined /></Tag>
                                  </Popconfirm>
                                );
                              }else{
                                return(
                                    <Tag style={{color:'black', border:'2px solid '+permiso['color']}}>{permiso['tipo']} </Tag>
                                );
                              }
                            });
                          }
                            usuarios.push(data.data[i]);
                            usuario_permisos = [];
                            permisos_nombre = [];
                            id_usuario_actual = data.data[i]['id_personal'];
                        }
                    }
                    this.setState({
                        data: usuarios
                    });
                break;
                case 5:
                    openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
            }
            
        });
    }
    
    isEditing = record => record.id_personal === this.state.editingKey;
    
    cancel = () => {
        this.setState({ editingKey: '' });
    };

    

    agregarPermiso(id){
      
      
    }

    borrarPermiso(id){
      return event => {
        
        eliminar_personal_permiso(id).then(data => {
          switch(data.status){
            case 0:
              openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Eliminado','El permiso ha sido eliminado');
              this.listaUsuario();
            break;
            case 5:
              openNotification(<FrownOutlined style={{color:'red'}}/>,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
              this.setState({login:true});
            break;
            default:
              openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
              this.setState({login:true});
            break;
          }
        
        });
      };
    }
    
    save(form, key) {

        form.validateFields((error, row) => {
          if (error) {
            return;
          }
          const newData = [...this.state.data];

          const index = newData.findIndex(item => key === item.id_personal);


          if (index > -1) {

            const item = newData[index];
                  newData.splice(index, 1, {
                    ...item,
                    ...row,
                  });

                  editar_datos_usuario(newData[index]).then(data => {
                    switch(data.status){
                      case 0:
                        this.setState({ data: newData, editingKey: '' });
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Usuario Editado','');
                      break;
                      case 5:
                        openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                        this.setState({login:true});
                      break;
                      default:
                        openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
                        this.setState({login:true});
                      break;
                    }
                  });
          } 
        });
    }
    
    edit(key) {
        this.setState({ editingKey: key });
    }

    delete(id){

      eliminar_personal(id).then(data => {
        switch(data.status){
          case 0:
              openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Usuario Eliminado','');
              this.listaUsuario();
            break;
            case 5:
              openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
              this.setState({login:true});
            break;
            default:
              openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
              this.setState({login:true});
            break;
        }
      });

    }
    
    render() {

        if(permiso_ver_usuarios){
      
          return (
              <Row justify="center">
                <Col span={20}>
                <ListaUsuarioN usuarios={this.state.data} permiso_editar_usuarios={permiso_editar_usuarios} permiso_registrar_usuario={permiso_registrar_usuarios} agregarPermisos={this.showModalPermisos} edit={this.edit} save={this.save} cancel={this.cancel} borrar={this.delete} onResponse={()=>this.listaUsuario()} />
                  <Modal
                    title="Agregar Permisos"
                    visible={this.state.modal_agregar_permisos}
                    onOk={this.handleOkPermisos}
                    onCancel={this.handleCancel}
                    okText="Actualizar"
                    cancelText="Cancelar"
                  >
  
                    {this.state.modal_agregar_permisos &&
                      this.state.permisos_registrados &&
                      (<Select 
                        ref={this.valorDefaultPermisos}
                        defaultValue={ permisos_registrados }
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Selecciona los permisos"
                        onChange={this.handleChangePermisos}
                        allowClear={true}
                        autoClearSearchValue={true}
                      >
                        {opciones_permisos}
                      </Select>)
                    }
                    
                  </Modal>
                </Col>
                  
              </Row>
            
          );

        }else{
          return(
            <Redirect to="/usuario" />
          );
        }

        
      }
    }

export default ListaUsuario;