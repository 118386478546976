import React from 'react';
import { Row } from 'antd';
import { Redirect } from 'react-router-dom';

import { Header } from './master/Header';
import { Footer } from './master/Footer';
import MenuIzquierdo from './master/MenuIzquierdo';
import Contenido from './usuario/Contenido';

import { personal_permisos } from '../utils/Jwt';

class Dashboard extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            permisos_usuario: [],
            login: false,
            display:false
        }

        personal_permisos().then(data => {
            console.log(data.datas);
            switch(data.status){
                case 0:
                    this.setState({
                        permisos_usuario: data.data,
                        display:true
                    }, () => {});
                break;
                default:
                    this.setState({
                        login: true
                    }, () => {});
                break;
            }
        });
    }

    render(){

        if(this.state.login){
            return(
                <Redirect to="/" />
            );
        }

        if(this.state.display){
            return(
                <Row>
                    <MenuIzquierdo permisos_usuario={this.state.permisos_usuario} />
                    <Header />
                    <Contenido permisos_usuario={this.state.permisos_usuario} />
                    <Footer />
                </Row>
            );
        }else{
            return(
                <Row>
                    
                </Row>
            );
        }
    }
}

export default Dashboard;
