import React, { useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Select, Button, Row, Col, Modal, Drawer, notification, Tooltip } from 'antd';
import {EditOutlined, PlusCircleOutlined, SmileOutlined, FrownOutlined, UserSwitchOutlined, UserAddOutlined, FileExcelOutlined} from '@ant-design/icons';
import RegistrarUsuario from './RegistrarUsuario';
import { eliminar_personal, editar_datos_usuario } from '../../../utils/Jwt';
import Excel from '../consultas/archivos/Excel';
import '../../../css/lista_usuarios.css';

const originData = [];

const {Option} = Select;

const openNotification = (icono, titulo,mensaje) => {
  notification.open({
    message: titulo,
    description:
      mensaje,
    icon: icono,
  });
};

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

const ListaUsuarioN = (props) => {
    
const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const {usuarios, agregarPermisos, save, cancel, borrar, edit, onResponse, permiso_registrar_usuario, permiso_editar_usuarios} = props;
  const [registrar_usuario, setRegistrarUsuario] = useState(false);
  const [editar_area, setEditarArea] = useState(false);
  const [datos_personal, setDatosPersonal] = useState(null);
  const [editar_coordinador, setEditarCoordinador] = useState(false);

  const isEditing = (record) => record.key === editingKey;

  const editarCampo = (values) => {

    values.id_personal = datos_personal.id_personal;
    values.nombre = datos_personal.nombre;
    values.apellidos = datos_personal.apellidos;
    values.cargo = datos_personal.cargo;
    if(editar_area){
      values.encargado_area = datos_personal.encargado_area;
      values.area = values.valor;
    }

    if(editar_coordinador){
      values.area = datos_personal.area;
      values.encargado_area = values.valor;
    }

    editar_datos_usuario(values).then(data => {
      switch(data.status){
        case 0:
            openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Editado','El campo ha sido actualizado');
            onResponse();
            setEditarArea(false);
          break;
          case 5:
            openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
            setEditarArea(false);
          break;
          default:
            openNotification(<FrownOutlined style={{color:'orange'}} />,'Sesión Expirada','Vuele a loguearte');
            setEditarArea(false);
          break;
      }
    });

    

  }

  const columns = [
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        editable: true,
      },
      {
        title: 'Apellidos',
        dataIndex: 'apellidos',
        editable: true,
      },
      {
        title: 'Área',
        dataIndex: 'area',
        editable: true,
        render: (text, record) => {
          return (
            <div>
              {text} 
              {permiso_editar_usuarios &&
              <Tooltip title="Editar Área"><EditOutlined className="icono_editar_campo" onClick={()=>{setDatosPersonal(record); setEditarArea(true);}} /> </Tooltip>
              }
              </div>
            );
        },
      },
      {
        title: 'Cargo',
        dataIndex: 'cargo',
        editable: true,
      },
      {
        title: 'Coordinador',
        dataIndex: 'encargado_area',
        editable: true,
        render: (text, record) => {
          return (
            <div>
              {text === 1 ? 'Si' : 'No'}
              {permiso_editar_usuarios &&
              <Tooltip title="Editar coordinador de área"><EditOutlined className="icono_editar_campo" onClick={()=>{setDatosPersonal(record); setEditarCoordinador(true);}} /> </Tooltip>
              }
              </div>
            );
        },
      },
      {
        title: 'Permisos',
        dataIndex: 'permisos',
        render: (text, record) => {
          console.log(record);
          if(permiso_editar_usuarios){
            return (
              <div>
                {record.permisos}
                <Tooltip title="Agregar permiso">
                  <Button type="default" shape="circle" size='small' className="icono_agregar_permido" onClick={ () => {agregarPermisos(record)} } ><PlusCircleOutlined /></Button>
                </Tooltip>
              </div>
                
              );
          }else{
            return (
              <div>
                {record.nombre_permisos}
                </div>
                
              );
          }
          
        },
      },
      {
        title: '...',
        dataIndex: '...',
        render: (text, record) => {
          return (
            <div>
              {permiso_editar_usuarios &&
              (
              record.eliminado === 0 ?
                <Popconfirm
                title="Estas seguro de bloquear a éste usuario?"
                onConfirm={()=> eliminar_personal(record.id_personal).then(({data,status})=>{switch(status){ case 0: openNotification(<SmileOutlined style={{color:'#73fc03'}} /> ,'Usuario bloqueado',''); onResponse(); break; default: openNotification(<FrownOutlined style={{color:'red'}} /> ,'Error de conexión','Inténtalo en otro momento'); }})}
                okText="Si"
                cancelText="No"
              >
                <Tooltip  placement="left" title="Bloquear usuario"><UserSwitchOutlined style={{color:'blue'}} /></Tooltip>
                
              </Popconfirm>
              :
              <Popconfirm
                title="Estas seguro de desbloquear a éste usuario?"
                onConfirm={()=> eliminar_personal(record.id_personal).then(({data,status})=>{switch(status){ case 0: openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Usuario desbloqueado',''); onResponse(); break; default: openNotification(<FrownOutlined style={{color:'red'}} /> , 'Error de conexión','Inténtalo en otro momento'); }})}
                okText="Si"
                cancelText="No"
              >
                <Tooltip  placement="left" title="Desbloquear usuario"><UserSwitchOutlined style={{color:'red'}} /></Tooltip>
              </Popconfirm>
              )
              }
            </div>
            );
        },
      }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const campos_excel = [
    {title: 'Nombre', key: 'nombre'},
    {title: 'Apellidos', key: 'apellidos'},
    {title: 'Área', key: 'area'},
    {title: 'Cargo', key: 'cargo'},
    {title:'Coordinador',key:'coordinador'},
    {title:'Bloqueado',key:'bloqueado'},
    {title:'Permisos',key:'permisos'}

  ];

  let datos_consulta = usuarios.map(usuario => {

    console.log(usuario);

    return({
      nombre: usuario.nombre,
      apellidos: usuario.apellidos,
      cargo: usuario.cargo,
      area: usuario.area,
      coordinador: usuario.encargado_area === 1 ? 'Si' : 'No',
      bloqueado: usuario.bloqueado === 1 ? 'Si' : 'No',
      permisos: usuario.nombre_permisos
    });
  });


  return (
    <Row>
      <Col span={24} style={{textAlign:'right'}}>
        <Excel datos={datos_consulta} columnas={campos_excel} nombre="tabla" />
        {permiso_registrar_usuario &&
          <Tooltip title="Agregar usuario"><Button onClick={()=>setRegistrarUsuario(true)} ><UserAddOutlined /></Button></Tooltip>
        }
        <br /><br />
      </Col>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={usuarios}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
            }}
          />
        </Form>
      </Col>
        <Drawer
          title="Registrar usuario"
          placement="right"
          closable={()=>setRegistrarUsuario(false)}
          onClose={()=>setRegistrarUsuario(false)}
          visible={registrar_usuario}
          width={520}
        >
          <RegistrarUsuario onResponse={()=>{setRegistrarUsuario(false); onResponse();}} />
        </Drawer>

        <Modal
          title={ editar_area ? "Editar Área" : "Editar Coordinador"}
          visible={editar_area || editar_coordinador}
          onCancel={()=>{setEditarArea(false); setEditarCoordinador(false);}}
          cancelText="Cancelar"
          okText="Editar"
          footer={null}
        >
          <Form onFinish={editarCampo}>
            {editar_area &&
            <Form.Item name="valor">
            <Select 
                            style={{ width: '100%' }}
                            placeholder="Selecciona el área"
                            allowClear={true}
                            autoClearSearchValue={true}
                        >
                            <Option value="Administración y Finanzas">Administración y Finanzas</Option>
                            <Option value="Contrataciones Públicas y Auditoría">Contrataciones Públicas y Auditoría</Option>
                            <Option value="Temas de Seguridad, Prevención y CapacitaciónCapacitación y Seguridad">Temas de Seguridad, Prevención y CapacitaciónCapacitación y Seguridad</Option>
                            <Option value="Asuntos Corporativos">Asuntos Corporativos</Option>
                            <Option value="Dirección General">Dirección General</Option>
                            <Option value="Género y Derechos Humanos">Género y Derechos Humanos</Option>
                            <Option value="Relaciones Públicas">Relaciones Públicas</Option>
                            <Option value="Asuntos  Jurídicos y Legislativos">Asuntos  Jurídicos y Legislativos</Option>
                            <Option value="Asuntos Sociales y Políticos">Asuntos Sociales y Políticos</Option>
                            <Option value="Consultor">Consultor</Option>
                        </Select>
            </Form.Item>
            }

            {editar_coordinador &&
              <Form.Item name="valor">
              <Select 
                              style={{ width: '100%' }}
                              placeholder="elige si es coordinador"
                              allowClear={true}
                              autoClearSearchValue={true}
                          >
                              <Option value={1}>Si</Option>
                              <Option value={0}>No</Option>
                          </Select>
              </Form.Item>
            }
            
            <Button type="primary" htmlType="submit">
          Editar
        </Button>
          </Form>
                </Modal>
    </Row>
  );
}

export default ListaUsuarioN;
