import React from 'react';
import { Row, Col} from 'antd';

const Completo = () => {

    return(
        <Row>
            <Col span={24}>
                <p>Todo en orden</p>
            </Col>
        </Row>
    );
}

export default Completo;
