import React, { useEffect, useState } from "react";
import { Form, Input, Radio, Tooltip, notification,List, Cascader, Select, Row, Col, Checkbox, Button, Table, DatePicker, Modal, Drawer } from "antd";
import { exportComponentAsPNG } from "react-component-export-image";
import { catalogos_reporte, reporte_tabla, guardar_consulta, consultas } from '../../../utils/Jwt';
import Barras from './graficas/Barras';
import Linear from './graficas/Linear';
import '../../../css/reporte_consulta.css';
import { StockOutlined, CloseSquareOutlined, FileImageOutlined, SaveOutlined, PlusCircleOutlined, SmileOutlined, FrownOutlined, DeleteOutlined, UnorderedListOutlined, ClearOutlined } from '@ant-design/icons';
import Excel from './archivos/Excel';
import { Redirect } from "react-router-dom";

const { Option } = Select;

const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

const colors =
  ['#ff6960', '#00bfff', '#90ee90', '#964b00', '#e3b1ff', '#2a1506', '#b1aba1', '#ffab82', '#add8e6', '#8b4513'];

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const openNotification = (icono, titulo, mensaje) => {
  notification.open({
    message: titulo,
    description:
      mensaje,
    icon: icono,
  });
};


const campos_ingreso = [
  { key: 'proyecto', campo_bd: 'proyectos.nombre', campo_id: 'id_proyecto', campo_visible: 'Proyecto' },
  { key: 'empresa', campo_bd: 'empresas.nombre', campo_id: 'id_empresa', campo_visible: 'Empresa' },
  { key: 'cliente', campo_bd: 'clientes.nombre_cliente', campo_id: 'id_cliente', campo_visible: 'Cliente' },
  {key: 'factura', campo_bd: 'movimientos.factura', campo_id: 'factura', campo_visible: 'Factura'},
  {key: 'documento', campo_bd: 'movimientos.documento', campo_id: 'documento', campo_visible: 'Orden Compra'},
  { key: 'mes', campo_bd: 'movimientos.mes', campo_id: 'mes', campo_visible: 'Mes' },
  { key: 'año', campo_bd: 'año', campo_id: 'año', campo_visible: 'Año' }
];

const campos_folios = [
  { key: 'proyecto', campo_bd: 'proyectos.nombre', campo_id: 'id_proyecto', campo_visible: 'Proyecto' },
  { key: 'empresa', campo_bd: 'empresas.nombre', campo_id: 'id_empresa', campo_visible: 'Empresa' },
  { key: 'tipo_nota', campo_bd: 'nota_personals.tipo_nota', campo_id: 'tipo_nota', campo_visible: 'Tipo Folio' },
  { key: 'forma_de_pago', campo_bd: 'forma_de_pagos.tipo', campo_id: 'id_forma_de_pago', campo_visible: 'Forma de Pago' },
  { key: 'nombre', campo_bd: 'personals.nombre', campo_id: 'id_personal', campo_visible: 'Personal' },
  { key: 'concepto', campo_bd: 'conceptos.nombre', campo_id: 'id_concepto', campo_visible: 'Concepto' },
  {key: 'tipo_comprobante', campo_bd: 'movimientos.tipo_comprobante', campo_id: 'tipo_comprobante', campo_visible: 'Tipo comprobante'},
  { key: 'objetivo_especifico', campo_bd: 'objetivo_especifico_proyectos.objetivo_especifico', campo_id: 'id_objetivo_especifico', campo_visible: 'Objetivo Especifico' },
  { key: 'accion', campo_bd: 'proyecto_acciones.accion', campo_id: 'id_accion', campo_visible: 'Acción' },
  { key: 'meses', campo_bd: 'meses', campo_id: 'meses', campo_visible: 'Mes' },
  { key: 'año', campo_bd: 'año', campo_id: 'año', campo_visible: 'Año' }
];

const campos_proyecciones = [
  { key: 'proyecto', campo_bd: 'proyectos.nombre', campo_id: 'id_proyecto', campo_visible: 'Proyecto' },
  { key: 'concepto', campo_bd: 'conceptos.nombre', campo_id: 'id_concepto', campo_visible: 'Concepto' },
  { key: 'objetivo_especifico', campo_bd: 'objetivo_especifico_proyectos.objetivo_especifico', campo_id: 'id_objetivo_especifico', campo_visible: 'Objetivo Especifico' },
  { key: 'accion', campo_bd: 'proyecto_acciones.accion', campo_id: 'id_accion', campo_visible: 'Acción' }
];

const Reporte = (props) => {

  const [tipo_operaciones, setTipoOperaciones] = useState(['Folio']);
  const [tipo_operacion, setTipoOperacion] = useState('Folio');
  const [catalogos, setCatalogos] = useState([]);
  const [operaciones, setOperaciones] = useState([]);
  const [campos, setCampos] = useState([]);
  const [filtros, setFiltros] = useState([]);
  const [datos, setDatos] = useState([]);
  const [campos_check, setCamposCheck] = useState([]);
  const [campos_tabla, setCamposTabla] = useState([]);
  const [nombre_consulta, setNombreConsulta] = useState({id_consulta_guardada:0,nombre:'', consulta:null});
  const [modal_guardar_consulta, setModalGuardarConsulta] = useState(false);
  const [drawer_lista_consultas, setDrawerListaConsultas] = useState(false);
  const [consultas_guardadas, setConsultasGuardadas] = useState([]);
  const [seleccionar_consulta_guardada, setSeleccionarConsultaGuardada] = useState(0);
  const [permitir_consultar, setPermitirConsultar] = useState(true);
  const { permisos_usuario } = props;
  const [importe_total, setImporteTotal] = useState(0);

  useEffect(() => {
    catalogos_reporte().then(data => {
      setCatalogos(data.data);
      
    });

    consultas().then(data => {
      setConsultasGuardadas(data.data);
      
    });

    setCamposCheck(campos_folios);

    let existe_consultar = false;
    let operaciones_permitidas = ['Folio'];



    for(let i=0; i < permisos_usuario.length; i++){
      if(permisos_usuario[i].id_permiso === 10){
        existe_consultar = true;
      }
      if(permisos_usuario[i].id_permiso === 1){
        operaciones_permitidas.push('Ingreso');
      }
      if(permisos_usuario[i].id_permiso === 13){
        operaciones_permitidas.push('Proyeccion');
      }
    }

    setTipoOperaciones([...operaciones_permitidas]);
    setPermitirConsultar(existe_consultar);

  }, []);

  useEffect(()=>{console.log(tipo_operaciones);},[tipo_operaciones]);

  const cambiarTipoOperacion = (val) => {
      switch (val) {
        case 'Ingreso': setCamposCheck(campos_ingreso); break;
        case 'Proyeccion': setCamposCheck(campos_proyecciones); break;
        default: setCamposCheck(campos_folios); break;
      }
      setCampos([]);
      setFiltros([]);
      setDatos([]);
      setCamposTabla([]);
      setTipoOperacion(val);
  };

  const agregarOperacion = () => {
    let nueva_operacion = [...operaciones];
    nueva_operacion.push({ index: operaciones.length + 1, activo: true, val: '' });
    setOperaciones(nueva_operacion);
  }

  const eliminarOperacion = (index) => {
    let nuevas_operaciones = operaciones.map(operacion => {
      if (operacion.index === index) {
        operacion.activo = false;
      }
      return operacion;
    });
    setOperaciones(nuevas_operaciones);
  }

  const cambiarValorOperacion = (index, val) => {
    let nuevas_operaciones = operaciones.map(operacion => {
      if (operacion.index === index) {
        operacion.val = val;
      }
      return operacion;
    });
    setOperaciones(nuevas_operaciones);
  }

  const seleccionarCampo = (val, objeto) => {

    let nuevos_campos = [...campos];
    if (val.target.checked) {
      nuevos_campos.push({
        index: campos.length + 1, activo: true, key: objeto.key, campo_bd: objeto.campo_bd, campo_id: objeto.campo_id, campo_visible: objeto.campo_visible
      });
    } else {
      nuevos_campos = campos.map(campo => {
        if (campo.key === objeto.key) {
          campo.activo = false;
        }
        return campo;
      });
    }
    setCampos(nuevos_campos);
  }

  const agregarFiltro = () => {
    let nuevos_filtros = [...filtros];
    nuevos_filtros.push({ index: filtros.length + 1, activo: true, campo: '', valores: [] });
    setFiltros(nuevos_filtros);
  };

  const borrarFiltro = (index) => {
    let nuevos_filtros = filtros.map(filtro => {
      if (filtro.index === index) {
        filtro.activo = false;
        filtro.valores.map(val => {val.activo = false; val.val=0; return val;});
      }
      return filtro;
    });
    setFiltros(nuevos_filtros);
  }

  const cambiarCampoFiltro = (index, campo) => {
    let nuevos_filtros = filtros.map(filtro => {
      if (filtro.index === index) {
          filtro.campo = campo;
          filtro.valores = [];
          filtro.valores.push({ index_valor: filtro.valores.length + 1, activo: true, val: 0 });
          //let valores = filtro.valores.map(val => {val.activo = false; val.val = 0; return val});
          //filtro.valores.map(val => {val.activo = false; val.val = 0; return val})
          //valores.push({ index_valor: filtro.valores.length + 1, activo: true, val: 0 });
          //filtro.valores = valores;
      }
      return filtro;
    });
    setFiltros(nuevos_filtros);
  }

  const agregarValorFiltro = (index) => {
    let nuevos_filtros = filtros.map(filtro => {
      if (filtro.index === index) {
        let nuevo_valor_filtro = filtro.valores;
        nuevo_valor_filtro.push({ index_valor: filtro.valores.length + 1, activo: true, val: 0 });
        filtro.valores = nuevo_valor_filtro;
      }
      return filtro;
    });
    setFiltros(nuevos_filtros);
  }

  const cambiarValorFiltro = (index, index_valor, valor) => {
    let nuevos_filtros = filtros.map(filtro => {
      if (filtro.index === index) {
        filtro.valores.map(val => {
          if (val.index_valor === index_valor) {
            val.val = valor;
          }
          return val;
        });
      }
      return filtro;
    });
    setFiltros(nuevos_filtros);
  }

  const borrarValorFiltro = (index, index_valor) => {
    
    let nuevos_filtros = filtros.map(filtro => {
      if (filtro.index === index) {
        filtro.valores.map(val => {
          if (val.index_valor === index_valor) {
            val.activo = false;
          }
          return val;
        });
      }
      return filtro;
    });
    setFiltros(nuevos_filtros);
  }

  const cambiarNombreConsultaGuardada = (e) => {
    /*
    this.setState({
      nombre_consulta: e.target.value
    });
    */
  }

  const showModalGuardarConsulta = () => {
    /*
    this.setState({
      modal_guardar_consulta: true,
    });*/
  };

  const handleOkGuardarConsulta = e => {
    /*
      this.setState({
        modal_guardar_consulta: false,
      });*/
  };

  const onFinish = () => {
    //console.log(values);
    console.log('operacion: ', tipo_operacion);
    console.log('campos: ', campos);
    console.log('filtro: ', filtros);

    let datos = {
      operacion: tipo_operacion,
      campos: campos.filter(campo => campo.activo),
      filtros: filtros.filter(filtro => filtro.activo)
    }

    let datos_consulta = {
      id_consulta_guardada: nombre_consulta.id_consulta_guardada,
      nombre: nombre_consulta.nombre,
      consulta: datos
    };
    setNombreConsulta(datos_consulta);

    let campos_mostrar = [{
      title: 'Importe',
      dataIndex: 'cantidad',
      key: 'cantidad',
      render: (text, record) => {
        return (
          <span>{formatter.format(text)}</span>
        );
      }
    }];

    campos.filter(campo => campo.activo).map(campo => {
      if(campo.key !== 'meses'){
        campos_mostrar.push({
          title: campo.campo_visible,
          dataIndex: campo.key,
          key: campo.key,
        });
      }else{
        campos_mostrar.push({
          title: campo.campo_visible,
          dataIndex: campo.key,
          key: campo.key,
          render: (text, record) => {
            return(
              text === 1 ? 'Enero' : text === 2 ? 'Febrero' : text === 3 ? 'Marzo' :
              text === 3 ? 'Abril' : text === 5 ? 'Mayo' : text === 6 ? 'Junio' :
              text === 7 ? 'Julio' : text === 8 ? 'Agosto' : text === 9 ? 'Septiembre' :
              text === 10 ? 'Octubre' : text === 11 ? 'Noviembre' : 'Diciembre' 
            );
          }
        });
      }
      
    });

    setCamposTabla(campos_mostrar);

    reporte_tabla(datos).then(data => {
     

      switch (data.status) {
        case 0:
          setDatos(data.data);
          console.log(data.data);
          setImporteTotal(data.data.reduce((a, b) => a + b.cantidad,0));
          /*
          switch(tipo_operacion){
            case 'Ingreso':
              let ingresos = data.data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Ingreso');  
              setDatos(ingresos);

            break;
            case 'Proyeccion':
              let proyecciones = data.data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Proyeccion'); 
              setDatos(proyecciones);
            break;
            default:
              let movimientos = data.data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Reembolso' || movimiento.especificar_tipo_operacion === 'Comprobacion');
              console.log(campos_mostrar);
              console.log(movimientos);
              //setDatos(movimientos);
              let solicitudes = data.data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Solicitud');
              let reembolsos = data.data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Reembolso');
              let comprobaciones = data.data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Comprobaciones');
              let depositos = data.data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Deposito' && movimientos.tipo_operacion_id === 2);
              let depositos_reembolsos = data.data.filter(movimiento => movimiento.especificar_tipo_operacion === 'Deposito' && movimientos.tipo_operacion_id === 1);
              break;
          }
        */

        break;
        case 5:
          openNotification(<FrownOutlined style={{color:'red'}} />, 'Error de Conexión', 'Problemas para conectarse, inténtalo más tarde');
          //this.setState({login:true});
          break;
        default:
          openNotification(<FrownOutlined style={{color:'orange'}} />, 'Sesión Expirada', 'Vuele a loguearte');
          //this.setState({login:true});
          break;
      }
    });
  }

  const onFinishConsulta = (values) => {
    let datos_consulta = {
      id_consulta_guardada: nombre_consulta.id_consulta_guardada,
      nombre: values.nombre_consulta,
      consulta: nombre_consulta.consulta
    };

    guardar_consulta(datos_consulta).then(({data, status}) => {
      switch(status){
        case 0:
          openNotification(<SmileOutlined style={{color:'#73fc03'}} />, 'Consulta guardada','');
          datos_consulta = {
            id_consulta_guardada: data.id_consulta_guardada,
            nombre: values.nombre_consulta,
            consulta: nombre_consulta.consulta
          };
          setNombreConsulta(datos_consulta);
        break;
        default:
          openNotification(<FrownOutlined style={{color:'red'}} />, 'Error de conexión inténtelo más tarde','');
        break;
      }
    });

    setModalGuardarConsulta(false);
  }

  const limpiarConsulta = ()=>{
    window.location.reload();
    //setNombreConsulta({id_consulta_guardada:0,nombre:'', consulta:null});
    //setTipoOperacion('Folio');
    //setFiltros([]);
    //setCampos([]);
  }

  const seleccionarConsultaGuardada = (id)=>{

    let consulta = consultas_guardadas.filter(consulta => {if(consulta.id_consulta_guardada === id){return true;}return false;})[0];
      
    let campos_cg = [];

    if(consulta.campos){
      campos_cg = consulta.campos.split('|').map((camp,index) => {
        let campo_ori = campos_check.filter(campo => {if(campo.key === camp){return true;} return false;} )[0];
        
        return({
          index: index + 1, activo: true, 
          key: campo_ori.key, 
          campo_bd: campo_ori.campo_bd, 
          campo_id: campo_ori.campo_id, 
          campo_visible: campo_ori.campo_visible
        });
    });
    }
    
    let filtros_cg = [];
    if(consulta.where_campos){
      filtros_cg = consulta.where_campos.split('|').map((filtro, index) => {

        let valores_cg = consulta.where_valores.split('|')[index].split('$').map((valor,index) => {
          return(
            { index_valor: index + 1, activo: true, val: parseInt(valor,10) }
          );
        });
  
        return({
          index: index + 1, activo: true, campo: filtro, valores: valores_cg
        });
      });
    }

    switch(consulta.tipo_operacion){
      case 'Ingreso': setCamposCheck(campos_ingreso); break;
      case 'Proyeccion': setCamposCheck(campos_proyecciones); break;
      default: setCamposCheck(campos_folios); break;
    }

    setTipoOperacion(consulta.tipo_operacion);
    setCampos(campos_cg);
    setFiltros(filtros_cg);
    setNombreConsulta({id_consulta_guardada:consulta.id_consulta_guardada,nombre:consulta.nombre, consulta:null});
    setDrawerListaConsultas(false);
  };

  useEffect(()=>{
    if(nombre_consulta.id_consulta_guardada !== 0){
      setSeleccionarConsultaGuardada(nombre_consulta.id_consulta_guardada);
    }
  },[nombre_consulta])

  return (
    <Row>

      {!permitir_consultar &&
        <Redirect to="/usuario" />
      }

      <Col xs={{ span: 22, ofset: 1 }} md={{ span: 20, offset: 2 }}>
        <Row>
          <Col span={24} style={{textAlign:'right'}}>
            <Tooltip title="Limpiar consulta">
              <Button onClick={limpiarConsulta}>
                <ClearOutlined style={{color:'orange'}} />
              </Button>
            </Tooltip>
            <Tooltip title="Mostrar consultas guardadas">
              <Button onClick={()=>setDrawerListaConsultas(true)}>
              <UnorderedListOutlined  style={{color:'green'}} />
              </Button>
            </Tooltip>
            
          </Col>
          <Col span={24}>
            <Form
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Row>
                {seleccionar_consulta_guardada === 0 ?
<>
<Col span={24} >
<p style={{textAlign:'center', fontWeight:'bold'}}>Selecciona la operación</p>

  <Form.Item label={null}>
    <Select
      onChange={(val) => { cambiarTipoOperacion(val); }} style={{ width: '100%' }}>
      {tipo_operaciones.map(operacion_permitida => {
        switch (operacion_permitida) {
          case 'Ingreso':
            return (
              <>
                <Option value="Ingreso">Ingreso</Option>
              </>
            );
            break;
          case 'Folio':
            return (
              <>
                <Option value="Folio">Folio</Option>
              </>
            );
            break;
          case 'Proyeccion':
            return (
              <>
                <Option value="Proyeccion">Proyección</Option>
              </>
            );
            break;
          default: return (
            <>
            </>
          ); break;
        }
      })}
    </Select>
  </Form.Item>
</Col>
<Col span={24}>
<p style={{textAlign:'center', fontWeight:'bold'}}>Campos</p>
  {campos_check.map(check => {
    return (
      <Checkbox value={check.campo_bd} onChange={val => { seleccionarCampo(val, check) }} defaultChecked={false} >{check.campo_visible}</Checkbox>
    );
  })}
</Col>
<Col span={24}>
<br />
  {filtros.filter(filtro => filtro.activo).length > 0 &&
    <p style={{textAlign:'center', fontWeight:'bold'}}>Filtros</p>
  }
  {filtros.filter(filtro => filtro.activo).map(filtro => {
    
    return (
      <Row>
        <Col span={16} offset={2}>
          <Form.Item
            label={null}
            name={`campo_filtro${filtro.index}`}
            rules={[
              {
                required: true,
                message: 'Selecciona un filtro',
              },
            ]}
            initialValue={filtro.campo}
          >
            <Select onChange={val => cambiarCampoFiltro(filtro.index, val)} style={{ width: '100%' }}>
              {campos_check.map(campo => {
                return (
                  <Option value={campo.campo_id}>{campo.campo_visible}</Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Tooltip title="Eliminar filtro">
            <Button onClick={() => { borrarFiltro(filtro.index) }} style={{color:'red'}} >
              <DeleteOutlined />
            </Button>
          </Tooltip>
          &nbsp;&nbsp;
          {filtro.valores.filter(val => val.activo).length > 0 &&
            <Tooltip title="Agregar valores al filtro">
              <Button onClick={() => { agregarValorFiltro(filtro.index)}} style={{color:'blue'}} >
                <PlusCircleOutlined />
              </Button>
            </Tooltip>
          }
        </Col>
        <Col span={18} offset={3}>
          <Row>
            {filtro.valores.filter(val => val.activo).map(val => {
              return (
                <>
                  <Col span={22}>
                    {
                      catalogos.filter(cat => {
                        if (cat[0] === filtro.campo) {
                          return true;
                        }
                        return false;
                      }).map(cat => {
                        switch (cat[1]) {
                          case 'Select':
                            return (
                              <Form.Item
                                label={null}
                                name={`valor_filtro_${filtro.index}_${val.index_valor}`}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Selecciona un valor',
                                  },
                                ]}
                              >
                                <Select onChange={value => { cambiarValorFiltro(filtro.index, val.index_valor, value) }} style={{ width: '100%' }}>
                                  {cat[2].map(valor_filtro => {
                                    return (
                                      <Option value={valor_filtro.id}>{valor_filtro.valor}</Option>
                                    );
                                  })
                                  }
                                </Select>
                              </Form.Item>
                            );
                            break;
                          default:
                            return (
                              <Form.Item
                                label={null}
                                name={`valor_filtro_${filtro.index}_${val.index_valor}`}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Selecciona un valor',
                                  },
                                ]}
                              >
                                <Input onChange={value => { cambiarValorFiltro(filtro.index, val.index_valor, value.target.value) }} />
                              </Form.Item>
                            );
                            break;
                        }
                      })
                    }
                  </Col>
                  <Col span={2}>
                    <Tooltip title="Eliminar valor del filtro">
                      <Button onClick={() => borrarValorFiltro(filtro.index, val.index_valor)} style={{color:'red'}}>
                        <CloseSquareOutlined /> 
                      </Button>
                    </Tooltip>
                  </Col>
                </>
              );
            })}
          </Row>
        </Col>
      </Row>
    )
  })}
</Col>
<Col span={24}>
  <Button onClick={agregarFiltro} ><PlusCircleOutlined /> Filtro</Button>
  </Col>
</>
                
                :
                <div>&nbsp;
                </div>
                }


                <Col span={24}><span> </span></Col>


                {seleccionar_consulta_guardada !== 0 ?
                <>
                <Col span={24} >
                <p style={{textAlign:'center', fontWeight:'bold'}}>Selecciona la operación</p>
                  <Form.Item label={null}>
                    <Select
                    defaultValue={tipo_operacion}
                      onChange={(val) => { cambiarTipoOperacion(val); }} style={{ width: '100%' }}>
                      {tipo_operaciones.map(operacion_permitida => {
                        switch (operacion_permitida) {
                          case 'Ingreso':
                            return (
                              <>
                                <Option value="Ingreso">Ingreso</Option>
                              </>
                            );
                            break;
                          case 'Folio':
                            return (
                              <>
                                <Option value="Folio">Folio</Option>
                              </>
                            );
                            break;
                          case 'Proyeccion':
                            return (
                              <>
                                <Option value="Proyeccion">Proyección</Option>
                              </>
                            );
                            break;
                          default: break;
                        }
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                <p style={{textAlign:'center', fontWeight:'bold'}}>Campos</p>
                  {campos_check.map(check => {
                    
                    let existe_campo = false;
                    campos.map(campo => {
                      if(campo.campo_bd === check.campo_bd){
                        existe_campo = true;
                      }
                      return campo;
                    });
                    if(existe_campo){
                      return (
                      <Checkbox value={check.campo_bd} onChange={val => { seleccionarCampo(val, check) }} defaultChecked={true} >{check.campo_visible}</Checkbox>
                      );
                    }else{
                      return (
                      <span>
                      <Checkbox value={check.campo_bd} onChange={val => { seleccionarCampo(val, check) }} defaultChecked={false} >{check.campo_visible}</Checkbox>
                      </span>
                      );
                    }
                    
                      
                    
                  })}
                </Col>
                <Col span={24}>
                <br />
                  {filtros.filter(filtro => filtro.activo).length > 0 &&
                    <p style={{textAlign:'center', fontWeight:'bold'}}>Filtros</p>
                  }
                  {filtros.filter(filtro => filtro.activo).map(filtro => {
                    
                    return (
                      <Row>
                        <Col span={16} offset={2}>
                          <Form.Item
                            label={null}
                            name={`campo_filtro${filtro.index}`}
                            rules={[
                              {
                                required: true,
                                message: 'Selecciona un filtro',
                              },
                            ]}
                            initialValue={filtro.campo}
                          >
                            <Select onChange={val => cambiarCampoFiltro(filtro.index, val)} style={{ width: '100%' }}>
                              {campos_check.map(campo => {
                                return (
                                  <Option value={campo.campo_id}>{campo.campo_visible}</Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Tooltip title="Eliminar filtro">
                            <Button onClick={() => { borrarFiltro(filtro.index) }} style={{color:'red'}} >
                              <DeleteOutlined />
                            </Button>
                          </Tooltip>
                          &nbsp;&nbsp;
                          {filtro.campo !== '' &&
                            <Tooltip title="Agregar valores al filtro">
                              <Button onClick={() => { agregarValorFiltro(filtro.index)}} style={{color:'blue'}} >
                                <PlusCircleOutlined />
                              </Button>
                            </Tooltip>
                          }
                        </Col>
                        <Col span={18} offset={3}>
                          <Row>
                            {filtro.valores.filter(val => val.activo).map(val => {
                              
                              return (
                                <>
                                  <Col span={22}>
                                    {
                                      catalogos.filter(cat => {
                                        if (cat[0] === filtro.campo) {
                                          return true;
                                        }
                                        return false;
                                      }).map(cat => {
                                        switch (cat[1]) {
                                          case 'Select':
                                            return (
                                              <Form.Item
                                                label={null}
                                                name={`valor_filtro_${filtro.index}_${val.index_valor}`}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Selecciona un valor',
                                                  },
                                                ]}
                                                initialValue={val.val}
                                              >
                                                <Select  onChange={value => { cambiarValorFiltro(filtro.index, val.index_valor, value) }} style={{ width: '100%' }}>
                                                  {cat[2].map(valor_filtro => {
                                                    
                                                    return (
                                                      <Option value={valor_filtro.id}>{valor_filtro.valor}</Option>
                                                    );
                                                  })
                                                  }
                                                </Select>
                                              </Form.Item>
                                            );
                                            break;
                                          default:
                                            return (
                                              <Form.Item
                                                label={null}
                                                name={`valor_filtro_${filtro.index}_${val.index_valor}`}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Selecciona un valor',
                                                  },
                                                ]}
                                              >
                                                <Input onChange={value => { cambiarValorFiltro(filtro.index, val.index_valor, value.target.value) }} />
                                              </Form.Item>
                                            );
                                            break;
                                        }
                                      })
                                    }
                                  </Col>
                                  <Col span={2}>
                                    <Tooltip title="Eliminar valor del filtro">
                                      <Button onClick={() => borrarValorFiltro(filtro.index, val.index_valor)} style={{color:'red'}}>
                                        <CloseSquareOutlined /> 
                                      </Button>
                                    </Tooltip>
                                  </Col>
                                </>
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>
                    )
                  })}
                </Col>
                <Col span={24}>
                  <Button onClick={agregarFiltro} ><PlusCircleOutlined /> Filtro</Button>
                  </Col>
                  </>
                  :
                  <div>
                    <br />
                  </div>  
              }

              </Row>

              <Form.Item>
                <br /><br />
                <Button type="primary" htmlType="submit">
                  Consultar
                </Button>
                <br /><br />
              </Form.Item>
            </Form>

          </Col>
          <Col span={24} offset={0} style={{textAlign:'right'}}>
            {datos.length > 0 &&
              <>
                <Excel datos={datos} columnas={campos_tabla} nombre="tabla" />
                <Tooltip title="Guardar consulta">
                  <Button onClick={()=>{setModalGuardarConsulta(true)}}>
                    <SaveOutlined style={{color:'blue'}} />
                  </Button>
                </Tooltip>
                <p style={{textAlign:'left'}}>
                  <strong>Importe total:</strong> {formatter.format(importe_total)}
                </p>
                
                <Table columns={campos_tabla} dataSource={datos} />
              </>}

          </Col>


        </Row>
        <Modal title="Guardar consulta" visible={modal_guardar_consulta} onCancel={()=>setModalGuardarConsulta(false)} footer={null}>
          <Form layout="vertical" onFinish={onFinishConsulta}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={nombre_consulta.id_consulta_guardada === 0 ? "Nombra a tu consulta" : "Renombra a tu consulta"}
                  name="nombre_consulta"
                  rules={[
                    {
                      required: true,
                      message: 'Ingresa un nombre para tu consulta!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} style={{textAlign:'right'}}>
                <Button type="primary" htmlType="submit">
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Drawer
          title={<span style={{fontWeight:'bold'}}>Consultas guardadas</span>}
          placement="right"
          closable={true}
          onClose={()=> setDrawerListaConsultas(false)}
          visible={drawer_lista_consultas}
          width={"40%"}
        >
          <List
            header={null}
            footer={null}
            
            dataSource={consultas_guardadas}
            renderItem={consulta => (
              <List.Item className="item_lista_consulta_guardada" onClick={()=>seleccionarConsultaGuardada(consulta.id_consulta_guardada)}>
                - {consulta.nombre}
              </List.Item>
            )}
          />
        </Drawer>
      </Col>
    </Row>
  );

}

export default Reporte;
