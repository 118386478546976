import React from 'react';
import moment from 'moment';
import { Form, Input, Radio, Tooltip, notification, Cascader, Select, Row, Col, Checkbox, Button, Table, DatePicker, Modal } from 'antd';
import ReactExport from "react-export-excel";
import { Redirect } from 'react-router-dom';
import Excel from './archivos/Excel';
import { exportComponentAsPNG } from "react-component-export-image";
import { StockOutlined, CloseSquareOutlined, FileImageOutlined, SaveOutlined , PlusCircleOutlined, SmileOutlined, FrownOutlined } from '@ant-design/icons';

import Barras from './graficas/Barras';
import Linear from './graficas/Linear';
import { catalogos, reporte_tabla, reporte_linea_tiempo, guardar_consulta } from '../../../utils/Jwt';
import '../../../css/reporte_consulta.css';

const { Option } = Select;

const colors = 
        ['#ff6960','#00bfff','#90ee90','#964b00','#e3b1ff','#2a1506','#b1aba1','#ffab82','#add8e6','#8b4513'];

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const fecha_linea_de_tiempo = '01/01/2000';
const fecha_linea_de_tiempo_inicio = '01/01/2019';
const fecha_linea_de_tiempo_final = '31/12/2020';

const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

let datos_operaciones = [], datos_campos = [], datos_where = [], datos_catalogo= [], datos_linea_de_tiempo = [fecha_linea_de_tiempo_inicio,fecha_linea_de_tiempo_final,1], datos_consulta = [];
let componentes_operaciones, componentes_campos, componentes_where, componentes_tabla;
let index_operacion=0, index_where=0, index_row=1, campos_excel= [], datos_guardar = {};
let totales_operaciones = [], datos_grafica = [], datos_ingreso_egreso = [], permiso_consulta = false;


const campos_ingreso = [
  { key: 'proyecto', campo_bd: 'proyectos.nombre', campo_visible: 'Proyecto'},
  {key: 'empresa', campo_bd: 'empresas.nombre', campo_visible: 'Empresa'},
  {key: 'cliente', campo_bd: 'clientes.nombre_cliente', campo_visible: 'Cliente'},
  {key: 'cantidad', campo_bd: 'movimientos.cantidad', campo_visible: 'Importe Factura'},
  {key: 'mes', campo_bd: 'movimientos.mes', campo_visible: 'Mes'},
  {key: 'año', campo_bd: 'año', campo_visible: 'Año'},
  {key: 'factura', campo_bd: 'movimientos.factura', campo_visible: 'Factura'},
  {key: 'orden_compra', campo_bd: 'movimientos.orden_compra', campo_visible: 'Orden Compra'}
];

const campos_folios = [
  { key:'proyecto', campo_bd: 'proyectos.nombre', campo_visible: 'Proyecto'},
  { key:'empresa', campo_bd: 'empresas.nombre', campo_visible: 'Empresa'},
  { key:'tipo', campo_bd: 'nota_personals.tipo_nota', campo_visible: ''},
  { key:'status', campo_bd: 'nota_personals.status_nota', campo_visible: 'Status'},
  { key:'personal', campo_bd: 'personals.nombre, personals.apellidos', campo_visible: 'Personal'},
  {key: 'conepto', campo_bd: 'conceptos.nombre', campo_visible: 'Concepto'},
  {key: 'objetivo_especifico', campo_bd: 'objetivo_especifico_proyectos.objetivo_especifico', campo_visible: 'Objetivo Especifico'},
  {key: 'accion', campo_bd: 'proyecto_acciones.accion', campo_visible: 'Acción'},
  {key: 'mes', campo_bd: 'movimientos.mes', campo_visible: 'Mes'},
  {key: 'año', campo_bd: 'año', campo_visible: 'Año'}
];

const campos_proyecciones = [
  { key:'proyecto', campo_bd: 'proyectos.nombre', campo_visible: 'Proyecto'},
  {key: 'conepto', campo_bd: 'conceptos.nombre', campo_visible: 'Concepto'},
  {key: 'objetivo_especifico', campo_bd: 'objetivo_especifico_proyectos.objetivo_especifico', campo_visible: 'Objetivo Especifico'},
  {key: 'accion', campo_bd: 'proyecto_acciones.accion', campo_visible: 'Acción'}
];


const campos = [
    ['conceptos.nombre','Concepto',{title: 'Concepto', dataIndex: 'concepto', key: 'concepto'}],
    ['empresas.nombre','Empresas',{title: 'Empresa', dataIndex: 'empresa', key: 'empresa'}],
    ['forma_de_pagos.tipo','Formas de Pago',{title: 'Forma de Pago', dataIndex: 'forma_de_pago', key: 'forma_de_pago'}],
    //['gasto_conceptos.nombre','Gasto Concepto',{title: 'Gasto Concepto', dataIndex: 'gasto_concepto', key: 'gasto_concepto'}],
    ['personals.nombre','Personal',{title: 'Personal', dataIndex: 'personal', key: 'personal'}],
    ['proyectos.nombre','Proyecto',{title: 'Proyecto', dataIndex: 'proyecto', key: 'proyecto'}],
    //['movimientos.fecha','Fecha',{title: 'Fecha', dataIndex: 'fecha', key: 'fecha'}],
    ['movimientos.anio','Año',{title: 'Anio', dataIndex: 'anio', key: 'anio'}],
    ['movimientos.mes','Mes',{title: 'Mes', dataIndex: 'mes', key: 'mes'}],
    ['movimientos.factura','Factura',{title: 'Factura', dataIndex: 'factura', key: 'factura'}],
    ['movimientos.status','Status',{title: 'Status', dataIndex: 'status', key: 'status'}]
];

let campos_header_table = [], header_campos_operaciones = [];

class ReporteMovimiento extends React.Component{

    constructor(props){
        super(props);

        this.state = {
          login: false,
          num_operaciones:0,
          num_where: 0,
          cambiar_campo: false,
          datos_reporte: [],
          linea_de_tiempo: false,
          tipo_grafica: -1,
          datos_grafica: [],
          nombre_consulta:'',
          num_operacion:0,
          periodo: [],
          modal_guardar_consulta: false
        };

        for(let i=0; i < this.props.permisos_usuario.length; i++){
            if(this.props.permisos_usuario[i].id_permiso === 10){
              permiso_consulta = true;
              break;
            }
        }

        this.exportar_imagen = React.createRef();

        this.agregarOperacion = this.agregarOperacion.bind(this);
        this.borrarOperacion = this.borrarOperacion.bind(this);
        this.pintarOperaciones = this.pintarOperaciones.bind(this);

        this.handleCambiarValorOperacion= this.handleCambiarValorOperacion.bind(this);
        
        this.agregarWhere = this.agregarWhere.bind(this);
        this.borrarWhere = this.borrarWhere.bind(this);
        this.pintarWhere = this.pintarWhere.bind(this);
        
        this.handleCambiarCampo = this.handleCambiarCampo.bind(this);
        this.handleCambiarValorCampo = this.handleCambiarValorCampo.bind(this);

        this.reporte = this.reporte.bind(this);
        this.habilitarLineaDelTiempo = this.habilitarLineaDelTiempo.bind(this);
        this.seleccionarGrafica = this.seleccionarGrafica.bind(this);

        this.guardarConsulta = this.guardarConsulta.bind(this);
        this.cambiarNombreConsultaGuardada = this.cambiarNombreConsultaGuardada.bind(this);

        if(permiso_consulta){
          this.pintarCampos();

        catalogos().then(data => {
          datos_catalogo = data.data;
        });
        }

        
    }

    openNotification = (icono,color_icono, titulo,mensaje) => {
      notification.open({
      message: titulo,
      description:
          mensaje,
      icon: icono,
      });
    };

    pintarOperaciones(){

        componentes_operaciones = datos_operaciones.map( campo => {
          return(
            <Col span={24}>
              <Select defaultValue={campo.valor} onChange={this.handleCambiarValorOperacion(campo.index)} style={{width:'50%'}}>
                <Option value={-1}>Elige una Operación</Option>
                <Option value={0}>Ingresos</Option>
                <Option value={1}>Egresos</Option>
                <Option value={3}>Proyectados</Option>
                <Option value={2}>Ingresos menos Egresos</Option>
                <Option value={4}>Ingresos menos Proyectados</Option>
                <Option value={5}>Proyectados menos Egresos</Option>
              </Select>
              <CloseSquareOutlined  onClick={() => this.borrarOperacion(campo.index)} style={{fontSize:'20px', color:'red'}} />
            </Col>
            
          );
        });

    }

    agregarOperacion(){
      datos_operaciones.push({index:index_operacion++,valor:-1});
      this.pintarOperaciones();
      this.setState({num_operaciones: (this.state.num_operaciones)+1});
    }

    borrarOperacion(index){

      for(let i=0; i < datos_operaciones.length; i++){
        if(datos_operaciones[i]['index'] === index){
          datos_operaciones.splice(i,1);
          break;
        }
      }
      this.pintarOperaciones();
      this.setState({num_operaciones: (this.state.num_operaciones)-1});
    }

    handleCambiarValorOperacion(index){
  
      return valor => {

        for(let i=0; i < datos_operaciones.length; i++){
          if(datos_operaciones[i]['index'] === index){
            datos_operaciones[i]['valor']= valor;
          }
        }

        
      }
    }

    pintarCampos(){

      componentes_campos = campos.map(campo => {
        return(
        <Checkbox value={campo[0]} onChange={this.camposSeleccionados}>{campo[1]}</Checkbox>
        );
      });

    }

    camposSeleccionados(e){

      if(e.target.checked){
        datos_campos.push(e.target.value);
        for(let i=0; i < campos.length; i++){
          if(e.target.value === campos[i][0]){
            campos_header_table.push(campos[i][2]);
            break;
          }
        }
      }else{
        for(let i=0; i < datos_campos.length; i++){
          if(e.target.value === datos_campos[i]){
            datos_campos.splice(i,1);
            campos_header_table.splice(i,1);
            break;
          }
        }
      }
    }

    agregarWhere(){

      datos_where.push({index:index_where++,campo:-1,valor:-1});
      
      this.pintarWhere();

      this.setState({num_where: datos_where.length });
      
    }

    borrarWhere(index){

      for(let i=0; i < datos_where.length; i++){
        if(datos_where[i]['index'] === index){
          datos_where.splice(i,1);
          break;
        }
      }
      this.pintarWhere();
      this.setState({num_where: (this.state.num_where)-1});
    }

    pintarWhere(){

      let es_select = true;

      
//<Option value={2}>Gasto Concepto</Option>
      componentes_where = datos_where.map( where => {

        let campos_where = 
        <Select onChange={ this.handleCambiarCampo(where.index)} style={{width:'100%'}}>
          <Option value={-1}>Elige una Operación</Option>
          <Option value={1}>Concepto</Option>
          
          <Option value={3}>Forma de Pago</Option>
          <Option value={4}>Proyecto</Option>
          <Option value={5}>Empresa</Option>

          <Option value={6}>Mes</Option>
          <Option value={7}>Año</Option>
          
        </Select>;

        let valores_where;

        if(where.campo === -1){
          valores_where = <Option value={-1}>Elige una Operación</Option>;
        }else{

          let campos = [];

          es_select = true;

          switch(where.campo){
            case 1:
              campos.push({id: 'id_concepto', valor:'nombre'});
            break;
            case 2:
              campos.push({id: 'id_gasto_concepto', valor:'nombre'});
            break;
            case 3:
              campos.push({id: 'id_forma_de_pago', valor:'tipo'});
            break;
            case 4:
              campos.push({id: 'id_proyecto', valor:'nombre'});
            break;
            case 5:
              campos.push({id: 'id_empresa', valor:'nombre'});
            break;
            default:
              es_select = false;
              break;
          }

          if(es_select){
            valores_where = datos_catalogo[where.campo].map(valores => {
              return(
                <Option key={valores[campos[0]['id']]} value={valores[campos[0]['id']]}>{valores[campos[0]['valor']]}</Option>
              );
            });
          }else{
          }

          
        }
        


        return(
          <Row>
            <Col span={8} offset={3}>
            {campos_where}
          </Col>
          <Col span={8}>

            {es_select ?
              (
                <Select onChange={this.handleCambiarValorCampo(where.index)} style={{width:'100%'}}>
                  {valores_where}
        
              </Select>
              )  
              :
              (
                <Input onChange={this.handleCambiarValorInput(where.index)} style={{width:'100%'}} />
              )
            }

            
          </Col>
          <Col span={2}>
          <CloseSquareOutlined  onClick={() => this.borrarWhere(where.index)} style={{fontSize:'20px', color:'red'}} />
          </Col>
          </Row>
          
          
        );
      });

      

    }

    handleCambiarCampo(index){
      return valor => {
        for(let i=0; i < datos_where.length; i++){
          if(datos_where[i]['index'] === index){
            datos_where[i]['campo']= valor;
            this.pintarWhere();
            this.setState({cambiar_campo: !(this.state.cambiar_campo)});
            break;
          }
        }
      }
    }

    handleCambiarValorCampo(index){
      return valor => {
        for(let i=0; i < datos_where.length; i++){
          if(datos_where[i]['index'] === index){
            datos_where[i]['valor']= valor;
            this.pintarWhere();
            this.setState({cambiar_campo: !(this.state.cambiar_campo)});
          }
        }
      }
    }

    handleCambiarValorInput(index){
      
      return valor => {
        for(let i=0; i < datos_where.length; i++){
          if(datos_where[i]['index'] === index){
            datos_where[i]['valor']= valor.target.value;
            this.pintarWhere();
            this.setState({cambiar_campo: !(this.state.cambiar_campo)});
          }
        }
      }
    }
    
    fechasRegistradas(value, dateString){
      datos_linea_de_tiempo[0] = dateString[0];
      datos_linea_de_tiempo[1] = dateString[1];
    }

    asignarPeriodo(value) {
      datos_linea_de_tiempo[2] = value;
    }

    habilitarLineaDelTiempo(e) {
      this.setState({linea_de_tiempo:e.target.checked});
    }

    reporte(){

      if(datos_operaciones.length === 0){
        this.openNotification(<FrownOutlined />,'red','Falta operacion','Ingresa por lo menos una operación');
        return;
      }
      for(let i=0; i < datos_operaciones.length; i++){
        if(datos_operaciones[i]['valor'] === -1){
          this.openNotification(<FrownOutlined />,'red','Valor inválido en la operación','Selecciona un valor para todas las operaciones');
        }
      }

      for(let i=0; i < datos_where.length; i++){
        if(datos_where[i]['campo'] === -1){
          this.openNotification(<FrownOutlined />,'red','Falta filtro','Selecciona un catalogo para todos los filtros');
          return;
        }

        if(datos_where[i]['valor'] === -1){
          this.openNotification(<FrownOutlined />,'red','Valor inválido en el filtro','Selecciona un valor para todos los filtros');
          return;
        }
      }

      let datos_reporte = {
        operacion: datos_operaciones,
        campo: datos_campos,
        where: datos_where,
        linea_de_tiempo: datos_linea_de_tiempo
      }

      datos_guardar = Object.assign({}, datos_reporte);

      if(this.state.linea_de_tiempo){
        reporte_linea_tiempo(datos_reporte).then(data => {
          switch(data.status){
            case 0:
              this.setState({
                datos_reporte: data.data,
                periodo: datos_linea_de_tiempo
              });
              this.ingresoMenosEgreso();
              this.periodoTiempo();
              this.pintarTabla(1);
            break;
            case 5:
              this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
              this.setState({login:true});
            break;
            default:
              this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
              this.setState({login:true});
            break;
          }
        });
      }else{
        datos_guardar['linea_de_tiempo'] = [];
        reporte_tabla(datos_reporte).then(data => {
          switch(data.status){
            case 0:
              this.setState({
                datos_reporte: data.data
              });
              this.ingresoMenosEgreso();
              this.pintarTabla(0);
            break;
            case 5:
              this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
              this.setState({login:true});
            break;
            default:
              this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
              this.setState({login:true});
            break;
          }
        });
      }
    }

    ingresoMenosEgreso(){

      let num_grafica=0;
      let nombre_campos = [];
      datos_consulta = [];

      for(let campo in this.state.datos_reporte[0]){
        if(campo !== 'tipo_movimiento' && campo !== 'operacion'){
          nombre_campos.push(campo);
        }
      }

      let comparar_obj = this.state.datos_reporte[0];

      let obj_row = {};
      obj_row['ingreso'] = 0;
      obj_row['egreso'] = 0;
      obj_row['proyectado'] = 0;
      obj_row['ingreso-egreso'] = 0;
      obj_row['ingreso-proyectado'] = 0;
      obj_row['proyectado-egreso'] = 0;


      console.log('los campos: ');
      console.log(nombre_campos);

      for(let i=0; i < this.state.datos_reporte.length; i++){
        
        let campos_iguales = true;

      
          for(let j=0; j < nombre_campos.length; j++){
            if(this.state.datos_reporte[i][nombre_campos[j]] === comparar_obj[nombre_campos[j]]){
              campos_iguales = true;
            }else{
              campos_iguales = false;
              comparar_obj = this.state.datos_reporte[i];
              break;
            }
          }

        if(!campos_iguales){

          obj_row['ingreso-egreso'] = obj_row['ingreso'] - obj_row['egreso'];
          obj_row['ingreso-proyectado'] = obj_row['ingreso'] - obj_row['proyectado'];
          obj_row['proyectado-egreso'] = obj_row['proyectado'] - obj_row['egreso'];

          for(let j=0; j < nombre_campos.length; j++){
            obj_row[nombre_campos[j]] = this.state.datos_reporte[i-1][nombre_campos[j]];
          }

          obj_row['color'] = colors[num_grafica++];

          datos_consulta.push(obj_row);

          obj_row = {};
          obj_row['ingreso'] = 0;
          obj_row['egreso'] = 0;
          obj_row['proyectado'] = 0;
          obj_row['ingreso-egreso'] = 0;
          obj_row['ingreso-proyectado'] = 0;
          obj_row['proyectado-egreso'] = 0;
        }

        switch(this.state.datos_reporte[i].tipo_movimiento){
          case 'INGRESO':
            obj_row['ingreso'] = this.state.datos_reporte[i].operacion;
          break;
          case 'EGRESO':
            obj_row['egreso'] = this.state.datos_reporte[i].operacion;
          break;
          case 'PROYECTADO':
            obj_row['proyectado'] = this.state.datos_reporte[i].operacion;
          break;
          default:
          break;
        }

      }

      obj_row['ingreso-egreso'] = obj_row['ingreso'] - obj_row['egreso'];
          obj_row['ingreso-proyectado'] = obj_row['ingreso'] - obj_row['proyectado'];
          obj_row['proyectado-egreso'] = obj_row['proyectado'] - obj_row['egreso'];

          for(let j=0; j < nombre_campos.length; j++){
            obj_row[nombre_campos[j]] = this.state.datos_reporte[this.state.datos_reporte.length-1][nombre_campos[j]];
          }

          obj_row['color'] = colors[num_grafica++];

          datos_consulta.push(obj_row);

    }

    periodoTiempo(){


      let datos_para_grafica = [];
      let datos_para_tabla = []
      let nombre_campos = [];

      for(let campo in datos_consulta[0]){
        if(campo !== 'ingreso' && campo !== 'egreso' && campo !== 'ingreso-egreso'
            && campo !== 'anio' && campo !== 'mes' && campo !== 'color'){
          nombre_campos.push(campo);
        }
      }

      let datos_con_intervalo = [];

      let fecha_intervalo = this.siguienteFechaPeriodo(parseInt(datos_linea_de_tiempo[0].split('/')[1], 10),
                                                        parseInt(datos_linea_de_tiempo[0].split('/')[2], 10),
                                                        datos_linea_de_tiempo[2]
      );
      
      let sig_row = {};
      let nuevo_row = true;
      let fechas_ingresadas_por_periodo = 0;
      let operaciones = [0,0,0,fecha_intervalo[0]+' - '+fecha_intervalo[1]];
      let campos_iguales = false;

      let it_datos = 0;
      let num_grafica = 0;

      for(let i=0; i < datos_consulta.length; i++){


        if(nuevo_row){
          sig_row = datos_consulta[i];
          nuevo_row = false;
        }

        for(let j=0; j < nombre_campos.length; j++){

          if(datos_consulta[i][nombre_campos[j]] === sig_row[nombre_campos[j]]){
            campos_iguales = true;
          }else{
            campos_iguales = false;
            break;
          }
        }

        if(campos_iguales){


          if(datos_consulta[i]['anio'] < fecha_intervalo[1]){
            operaciones[0]+= datos_consulta[i]['ingreso'];
            operaciones[1]+= datos_consulta[i]['egreso'];
            operaciones[2]+= datos_consulta[i]['ingreso-egreso'];
            fechas_ingresadas_por_periodo++;
          }else{
            if(datos_consulta[i]['anio'] === fecha_intervalo[1]){

              if(datos_consulta[i]['mes'] <= fecha_intervalo[0]){

                
                operaciones[0]+= datos_consulta[i]['ingreso'];
                operaciones[1]+= datos_consulta[i]['egreso'];
                operaciones[2]+= datos_consulta[i]['ingreso-egreso'];
                fechas_ingresadas_por_periodo++;
              }else{

                if(fechas_ingresadas_por_periodo > 0){

                  datos_con_intervalo[it_datos] = Object.assign({} ,sig_row);
                  datos_con_intervalo[it_datos]['ingreso'] = operaciones[0];
                  datos_con_intervalo[it_datos]['egreso'] = operaciones[1];
                  datos_con_intervalo[it_datos]['ingreso-egreso'] = operaciones[2];
                  datos_con_intervalo[it_datos]['anio'] = fecha_intervalo[1];
                  datos_con_intervalo[it_datos]['mes'] = fecha_intervalo[0];
                  datos_con_intervalo[it_datos]['color'] = colors[num_grafica];

                  switch(datos_con_intervalo[it_datos]['mes']){
                    case 1: datos_con_intervalo[it_datos]['mes_nombre'] = 'Enero'; break;
                    case 2: datos_con_intervalo[it_datos]['mes_nombre'] = 'Febrero'; break;
                    case 3: datos_con_intervalo[it_datos]['mes_nombre'] = 'Marzo'; break;
                    case 4: datos_con_intervalo[it_datos]['mes_nombre'] = 'Abril'; break;
                    case 5: datos_con_intervalo[it_datos]['mes_nombre'] = 'Mayo'; break;
                    case 6: datos_con_intervalo[it_datos]['mes_nombre'] = 'Junio'; break;
                    case 7: datos_con_intervalo[it_datos]['mes_nombre'] = 'Julio'; break;
                    case 8: datos_con_intervalo[it_datos]['mes_nombre'] = 'Agosto'; break;
                    case 9: datos_con_intervalo[it_datos]['mes_nombre'] = 'Septiembre'; break;
                    case 10: datos_con_intervalo[it_datos]['mes_nombre'] = 'Octubre'; break;
                    case 11: datos_con_intervalo[it_datos]['mes_nombre'] = 'Noviembre'; break;
                    case 12: datos_con_intervalo[it_datos]['mes_nombre'] = 'Diciembre'; break;
                    default: break;
                  }

                  datos_para_tabla.push(
                    Object.assign({} ,datos_con_intervalo[it_datos])
                  );
                  
                  it_datos++;
  
                  operaciones[0] = 0;
                  operaciones[1] = 0;
                  operaciones[2] = 0;
                  fechas_ingresadas_por_periodo = 0;
  
                }

                let fecha_existe = true;
              while(fecha_existe){

                if(fecha_intervalo[0] === 12){
                  fecha_intervalo = this.siguienteFechaPeriodo(1, (fecha_intervalo[1]+1) ,datos_linea_de_tiempo[2]);
                }else{
                  fecha_intervalo = this.siguienteFechaPeriodo((fecha_intervalo[0]+1), fecha_intervalo[1] ,datos_linea_de_tiempo[2]);
                }

                
                
                if(datos_consulta[i]['anio'] <= fecha_intervalo[1]){
                  
                  if(datos_consulta[i]['mes'] <= fecha_intervalo[0]){
                  
                    fecha_existe = false;
                  }else{
                    if(datos_consulta[i]['anio'] < fecha_intervalo[1]){
                    
                      fecha_existe = false;
                    }else{
                    }
                  }
                  
                }else{
                  
                }


              }

              i--;
                
              }
            }else{
              if(fechas_ingresadas_por_periodo > 0){

                datos_con_intervalo[it_datos] = Object.assign({} ,sig_row);;
                datos_con_intervalo[it_datos]['ingreso'] = operaciones[0];
                datos_con_intervalo[it_datos]['egreso'] = operaciones[1];
                datos_con_intervalo[it_datos]['ingreso-egreso'] = operaciones[2];
                datos_con_intervalo[it_datos]['anio'] = fecha_intervalo[1];
                datos_con_intervalo[it_datos]['mes'] = fecha_intervalo[0];

                switch(datos_con_intervalo[it_datos]['mes']){
                  case 1: datos_con_intervalo[it_datos]['mes_nombre'] = 'Enero'; break;
                  case 2: datos_con_intervalo[it_datos]['mes_nombre'] = 'Febrero'; break;
                  case 3: datos_con_intervalo[it_datos]['mes_nombre'] = 'Marzo'; break;
                  case 4: datos_con_intervalo[it_datos]['mes_nombre'] = 'Abril'; break;
                  case 5: datos_con_intervalo[it_datos]['mes_nombre'] = 'Mayo'; break;
                  case 6: datos_con_intervalo[it_datos]['mes_nombre'] = 'Junio'; break;
                  case 7: datos_con_intervalo[it_datos]['mes_nombre'] = 'Julio'; break;
                  case 8: datos_con_intervalo[it_datos]['mes_nombre'] = 'Agosto'; break;
                  case 9: datos_con_intervalo[it_datos]['mes_nombre'] = 'Septiembre'; break;
                  case 10: datos_con_intervalo[it_datos]['mes_nombre'] = 'Octubre'; break;
                  case 11: datos_con_intervalo[it_datos]['mes_nombre'] = 'Noviembre'; break;
                  case 12: datos_con_intervalo[it_datos]['mes_nombre'] = 'Diciembre'; break;
                  default: break;
                }

                datos_con_intervalo[it_datos]['color'] = colors[num_grafica];
                datos_para_tabla.push(
                  Object.assign({} ,datos_con_intervalo[it_datos])
                );
                
                it_datos++;

                operaciones[0] = 0;
                operaciones[1] = 0;
                operaciones[2] = 0;
                fechas_ingresadas_por_periodo = 0;

              }else{
                
              }

              let fecha_existe = true;
              while(fecha_existe){

                if(fecha_intervalo[0] === 12){
                  fecha_intervalo = this.siguienteFechaPeriodo(1, (fecha_intervalo[1]+1) ,datos_linea_de_tiempo[2]);
                }else{
                  fecha_intervalo = this.siguienteFechaPeriodo((fecha_intervalo[0]+1), fecha_intervalo[1] ,datos_linea_de_tiempo[2]);
                }

                
                
                if(datos_consulta[i]['anio'] <= fecha_intervalo[1]){
                  
                  if(datos_consulta[i]['mes'] <= fecha_intervalo[0]){
                  
                    fecha_existe = false;
                  }else{
                    if(datos_consulta[i]['anio'] < fecha_intervalo[1]){
                    
                      fecha_existe = false;
                    }else{
                    }
                  }
                  
                }else{
                  
                }


              }

              i--;

            }
          }
        }else{


          datos_con_intervalo[it_datos] = Object.assign({} ,sig_row);;
          datos_con_intervalo[it_datos]['ingreso'] = operaciones[0];
          datos_con_intervalo[it_datos]['egreso'] = operaciones[1];
          datos_con_intervalo[it_datos]['ingreso-egreso'] = operaciones[2];
          datos_con_intervalo[it_datos]['anio'] = fecha_intervalo[1];
          datos_con_intervalo[it_datos]['mes'] = fecha_intervalo[0];

          switch(datos_con_intervalo[it_datos]['mes']){
            case 1: datos_con_intervalo[it_datos]['mes_nombre'] = 'Enero'; break;
            case 2: datos_con_intervalo[it_datos]['mes_nombre'] = 'Febrero'; break;
            case 3: datos_con_intervalo[it_datos]['mes_nombre'] = 'Marzo'; break;
            case 4: datos_con_intervalo[it_datos]['mes_nombre'] = 'Abril'; break;
            case 5: datos_con_intervalo[it_datos]['mes_nombre'] = 'Mayo'; break;
            case 6: datos_con_intervalo[it_datos]['mes_nombre'] = 'Junio'; break;
            case 7: datos_con_intervalo[it_datos]['mes_nombre'] = 'Julio'; break;
            case 8: datos_con_intervalo[it_datos]['mes_nombre'] = 'Agosto'; break;
            case 9: datos_con_intervalo[it_datos]['mes_nombre'] = 'Septiembre'; break;
            case 10: datos_con_intervalo[it_datos]['mes_nombre'] = 'Octubre'; break;
            case 11: datos_con_intervalo[it_datos]['mes_nombre'] = 'Noviembre'; break;
            case 12: datos_con_intervalo[it_datos]['mes_nombre'] = 'Diciembre'; break;
            default: break;
          }

          datos_con_intervalo[it_datos]['color'] = colors[num_grafica];
          datos_para_tabla.push(
            Object.assign({} ,datos_con_intervalo[it_datos])
          );

          num_grafica++;
          
          it_datos = 0;

          datos_para_grafica.push(
            datos_con_intervalo
          );

          datos_con_intervalo = [];
          operaciones[0] = 0;
          operaciones[1] = 0;
          operaciones[2] = 0;


          i--;
          nuevo_row = true;
          fechas_ingresadas_por_periodo = 0;

          fecha_intervalo = this.siguienteFechaPeriodo(parseInt(datos_linea_de_tiempo[0].split('/')[1], 10),
                                                        parseInt(datos_linea_de_tiempo[0].split('/')[2], 10),
                                                        datos_linea_de_tiempo[2]);
          
        }

        if(i === datos_consulta.length -1){
          datos_con_intervalo[it_datos] = Object.assign({} ,sig_row);;
          datos_con_intervalo[it_datos]['ingreso'] = operaciones[0];
          datos_con_intervalo[it_datos]['egreso'] = operaciones[1];
          datos_con_intervalo[it_datos]['ingreso-egreso'] = operaciones[2];
          datos_con_intervalo[it_datos]['anio'] = fecha_intervalo[1];
          datos_con_intervalo[it_datos]['mes'] = fecha_intervalo[0];

          switch(datos_con_intervalo[it_datos]['mes']){
            case 1: datos_con_intervalo[it_datos]['mes_nombre'] = 'Enero'; break;
            case 2: datos_con_intervalo[it_datos]['mes_nombre'] = 'Febrero'; break;
            case 3: datos_con_intervalo[it_datos]['mes_nombre'] = 'Marzo'; break;
            case 4: datos_con_intervalo[it_datos]['mes_nombre'] = 'Abril'; break;
            case 5: datos_con_intervalo[it_datos]['mes_nombre'] = 'Mayo'; break;
            case 6: datos_con_intervalo[it_datos]['mes_nombre'] = 'Junio'; break;
            case 7: datos_con_intervalo[it_datos]['mes_nombre'] = 'Julio'; break;
            case 8: datos_con_intervalo[it_datos]['mes_nombre'] = 'Agosto'; break;
            case 9: datos_con_intervalo[it_datos]['mes_nombre'] = 'Septiembre'; break;
            case 10: datos_con_intervalo[it_datos]['mes_nombre'] = 'Octubre'; break;
            case 11: datos_con_intervalo[it_datos]['mes_nombre'] = 'Noviembre'; break;
            case 12: datos_con_intervalo[it_datos]['mes_nombre'] = 'Diciembre'; break;
            default: break;
          }

          datos_con_intervalo[it_datos]['color'] = colors[num_grafica];
          datos_para_tabla.push(
            Object.assign({} ,datos_con_intervalo[it_datos])
          );
          it_datos = 0;

          datos_para_grafica.push(
            datos_con_intervalo
          );
          

          operaciones[0] = 0;
          operaciones[1] = 0;
          operaciones[2] = 0;
        }
        
      }

      datos_consulta = datos_para_tabla;
      this.setState({
        datos_grafica: datos_para_grafica
      });

    }

    siguienteFechaPeriodo(mes, anio, periodo){

      let fecha_siguiente_periodo = [mes,anio];
      for(let i=0; i < (periodo - 1); i++ ){
        
        if(fecha_siguiente_periodo[0] === 12){
          fecha_siguiente_periodo[1]++;
          fecha_siguiente_periodo[0] = 1;
        }else{
          fecha_siguiente_periodo[0]++;
        }
      }


      return fecha_siguiente_periodo;
    }

    pintarTabla(tipo_grafica){
        header_campos_operaciones = [];
        campos_excel = [];
        index_row= 1;
        totales_operaciones = [];
        totales_operaciones[0] = 0;
        totales_operaciones[1] = 0;
        totales_operaciones[2] = 0;
        totales_operaciones[3] = 0;
        totales_operaciones[4] = 0;
        totales_operaciones[5] = 0;

        console.log(' los datos se ve: ',datos_consulta);

        for(let val_data = 0; val_data < datos_consulta.length; val_data++){
          totales_operaciones[0] += datos_consulta[val_data]['ingreso'];
          totales_operaciones[1] += datos_consulta[val_data]['egreso'];
          totales_operaciones[2] += datos_consulta[val_data]['ingreso-egreso'];
          totales_operaciones[3] += datos_consulta[val_data]['proyectado'];
          totales_operaciones[4] += datos_consulta[val_data]['ingreso-proyectado'];
          totales_operaciones[5] += datos_consulta[val_data]['proyectado-egreso'];
        }

        

        for(let i=0; i < datos_operaciones.length; i++){

          switch(datos_operaciones[i]['valor']){
            case 0:
              campos_excel.push({
                title: 'Ingreso', key: 'ingreso'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={0} />Ingresos &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'ingreso', 
                    key: 'ingreso',
                    render: (text,record) => {
                      return(
                        <span >
                            <StockOutlined  style={{color: record['color'], fontSize:'20px'}} /> {formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 1:
              campos_excel.push({
                title: 'Egreso', key: 'egreso'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={1} />Egresos &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'egreso', 
                    key: 'egreso',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined style={{color: record['color'], fontSize:'20px'}} /> {formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 2:
              campos_excel.push({
                title: 'Ingreso - Egreso', key: 'ingreso-egreso'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={2} />Ingresos - Egresos &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'ingreso-egreso', 
                    key: 'ingreso-egreso',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined style={{color: record['color'], fontSize:'20px'}} />{formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 3:
              campos_excel.push({
                title: 'Proyectado', key: 'proyectado'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={3} />Proyectados &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'proyectado', 
                    key: 'proyectado',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined style={{color: record['color'], fontSize:'20px'}} />{formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 4:
              campos_excel.push({
                title: 'Ingresos - Proyectados', key: 'ingreso-proyectado'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={4} />Ingresos - Proyectados &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'ingreso-proyectado', 
                    key: 'ingreso-proyectado',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined style={{color: record['color'], fontSize:'20px'}} />{formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 5:
              campos_excel.push({
                title: 'Proyectado - Egresos', key: 'proyectado-egreso'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={5} />Proyectados - Egresos &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'proyectado-egreso', 
                    key: 'proyectado-egreso',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined style={{color: record['color'], fontSize:'20px'}} />{formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            default:
            break;
          }
        }

        if(tipo_grafica === 1){
          campos_excel.push({
            title: 'Año', key: 'anio'
          },{
            title:'Mes', key: 'mes'
          },{
            title:'Mes Nombre', key: 'mes_nombre'
          });
          header_campos_operaciones.push(
            {
              title:'Año', 
              dataIndex: 'anio', 
              key: 'anio',
            },
            {
              title:'Mes', 
              dataIndex: 'mes_nombre', 
              key: 'mes_nombre',
            }
          );
        }

        for(let col=0; col < campos_header_table.length; col++){
          campos_excel.push(campos_header_table[col]);
          header_campos_operaciones.push(
            campos_header_table[col]
          );
        }

        componentes_tabla = 
        <div>
          <div style={{textAlign:'right'}}>
            <Excel datos={datos_consulta} columnas={campos_excel} nombre="tabla" />
            <Button onClick={() => exportComponentAsPNG(this.exportar_imagen)} alt="Exportar Gráfica"><FileImageOutlined style={{color:'lightblue', fontSize:'30px'}} /></Button>
            <Button onClick={ this.showModalGuardarConsulta } alt="Guardar Consulta"><SaveOutlined  style={{color:'green', fontSize:'30px'}} /></Button>
          </div>
            
          
          <Table dataSource={datos_consulta} columns={header_campos_operaciones} />
        </div>
        ;

        this.setState({datos_reporte: datos_consulta, tipo_grafica: tipo_grafica});
        
    }

    cambiarNombreConsultaGuardada(e){

      this.setState({
        nombre_consulta: e.target.value
      });

    }

    showModalGuardarConsulta = () => {
      this.setState({
        modal_guardar_consulta: true,
      });
    };

    handleOkGuardarConsulta = e => {
    
      this.setState({
        modal_guardar_consulta: false,
      });
    };
  
    handleCancel = e => {
      
      this.setState({
        modal_guardar_consulta: false,
      });
    };

    guardarConsulta(){

      datos_guardar['nombre'] = this.state.nombre_consulta;

      guardar_consulta(datos_guardar).then(data => {
        switch(data.status){
          case 0:
            this.openNotification(<SmileOutlined />,'green','Consulta guardada','Tu consulta ha quedado guardada');
            this.setState({modal_guardar_consulta: false, login:false});
          break;
          case 5:
            this.openNotification(<FrownOutlined />,'red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
            this.setState({login:true});
          break;
          default:
            this.openNotification(<FrownOutlined />,'orange','Sesión Expirada','Vuele a loguearte');
            this.setState({login:true});
          break;
        }
      });

    }

    seleccionarGrafica(e) {

      this.setState({
        num_operacion: e.target.value,
        datos_reporte: datos_consulta,
      });

    }

    render(){

      if(!permiso_consulta){
        return(
          <Redirect to="/usuario" />
        );
      }

        return(
            <Row>
                <Col span={22} offset={1}>
                      <Row>
                        <Col>
                          <p>
                            <span className="nombre_seccion">Operaciones</span>
                            <PlusCircleOutlined  onClick={ this.agregarOperacion } style={{color:'green', fontSize:'20px'}} />
                          </p>
                        </Col>
                        {
                          componentes_operaciones
                        }
                      </Row>
                      <Row>
                        <Col>
                        <p><span className="nombre_seccion">Campos</span></p>
                        </Col>
                        {
                          componentes_campos
                        }
                      </Row>
                      <Row>
                        <Col style={{display:'none'}}>
                        <Checkbox onChange={this.habilitarLineaDelTiempo}><span className="nombre_seccion">Línea del Tiempo</span></Checkbox>
                        </Col>
                        {this.state.linea_de_tiempo &&
                        (
                          <Col>
                        <RangePicker
                          defaultValue={[moment(fecha_linea_de_tiempo_inicio, dateFormat), moment(fecha_linea_de_tiempo_final, dateFormat)]}
                          format={dateFormat} onChange={this.fechasRegistradas}
                        />
                        <Select defaultValue={1} onChange={this.asignarPeriodo}>
                          
                          <Option value={1}>Mes</Option>
                          <Option value={2}>Bimestre</Option>
                          <Option value={3}>Trimestre</Option>
                          <Option value={6}>Semestre</Option>
                          <Option value={12}>Anual</Option>
                        </Select>
                        </Col>
                        )
                        }
                        
                      </Row>
                      <Row>
                        <Col span={20} offset={2}>
                        
                          <p>
                            <span className="nombre_seccion">Filtros</span>
                            <PlusCircleOutlined  onClick={ this.agregarWhere } style={{color:'green', fontSize:'20px'}} />
                          </p>
                        </Col>
                        </Row>
                        {
                          componentes_where
                        }
                      
                      <Row>
                        <Col>
                        <Button type="primary" onClick={this.reporte}>
                            Consultar
                        </Button>
                        </Col>
                        
                      </Row>
                        
                </Col>
                <Col span={22} offset={1}>
                {componentes_tabla}
                  </Col>

                  <Col span={20} offset={2} ref={this.exportar_imagen}>
                    {this.state.tipo_grafica === 0 ?
                    (
                      
                    <Barras nombre={this.state.nombre_consulta} datos={this.state.datos_reporte} operacion={this.state.num_operacion} colores={colors}  />
                    )  
                    :
                    this.state.tipo_grafica === 1 ?
                    (
                      <Linear nombre={this.state.nombre_consulta} datos={this.state.datos_grafica} operacion={this.state.num_operacion} periodo={this.state.periodo} colores={colors} />
                    )
                    :
                    (<div></div>)
                  }
                  
                  </Col>

                  <Modal
                    title="Guardar Consulta"
                    visible={this.state.modal_guardar_consulta}
                    onOk={this.guardarConsulta}
                    onCancel={this.handleCancel}
                  >
                    <Input onChange={ this.cambiarNombreConsultaGuardada } />
                  </Modal>
                  
            </Row>
        );
    }
}

export default ReporteMovimiento;
