import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Input, Button, Row, Col, notification } from 'antd';
import { MailOutlined, LockOutlined, FrownOutlined, SmileOutlined } from '@ant-design/icons';
import '../css/login.css';

import { Header } from './master/Header';
import { Footer } from './master/Footer';

import { login }from '../utils/Jwt';

const openNotification = (icono, titulo,mensaje) => {
    notification.open({
      message: titulo,
      description:
        mensaje,
      icon: icono,
    });
  };

const Login = (props) => {

    const [loged, setLoged] = useState(false);

    const onFinish = (values)=>{

            login(values).then(data => {
                switch(data.status){
                    case 0:
                        openNotification(<FrownOutlined style={{color:'orange'}} />,'Usuario Inválido','Asegúrate de colocar el correo y/o contraseña válido');
                    break;
                    case 1:
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Bienvenido','');
                        setLoged(true);
                    break;
                    case 5:
                        openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                        break;
                    default:
                    break;
                }
            });
    }

    return(
        loged ?
        <Redirect to="/usuario" />
        :
        <Row type="flex" justify="center" align="middle">
                
                <Col span={24} style={{textAlign:'center'}}>
                    <br />
                    <img src="/logo_maf.png" style={{width:'20%'}} alt="Logo Maf"/>
                    <br /><br />
                </Col>
                <Col xs={{span:20}} sm={{span:16}} md={{span:14}} lg={{span:6}} >
                    <p style={{textAlign:'center',fontSize:'32px', marginTop:'10%'}}>
                        Ingresa
                    </p>
                    <Form layout="vertical" onFinish={onFinish} className="login-form">

                    <Form.Item
        label={null}
        name="email"
        rules={[{ required: true, message: 'Por favor utiliza un correo válido' }]}
      >
        <Input
                                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Correo electrónico"
                                />
      </Form.Item>

      <Form.Item
        label={null}
        name="password"
        rules={[{ required: true, message: 'Por favor utiliza un correo válido' }]}
      >
        <Input
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Contraseña"
                                />
      </Form.Item>

                        
                       
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Entrar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Footer />
            </Row>
    );

}

export default Login;