import React, {useEffect, useState} from 'react';
import { Row, Col, Form, Select, Button, notification } from 'antd';
import { personal_area, otorgar_permiso_personal_area } from '../../../utils/Jwt';
import { Redirect } from 'react-router-dom';
import { SmileOutlined } from '@ant-design/icons';

const {Option} = Select;

const openNotification = (icono,titulo,mensaje) => {
  notification.open({
  message: titulo,
  description:
      mensaje,
  icon: icono,
  });
};

const OtorgarPermisoProyecto = (props) => {

    const [personal, setPersonal] = useState([]);
    const {permisos_usuario} = props;
    const [otorgar_proyecto, setOtorgarProyecto] = useState(true);

    useEffect(()=>{
        personal_area().then(({data, status}) => {
            switch(status){
                case 0:
                    setPersonal(data);
                    break;
                default: break;
            }
        });

        let existe = false;
        for(let i=0; i < permisos_usuario.length; i++){
          if(props.permisos_usuario[i].id_permiso === 16){
              existe = true;
            break;
          }
        }

        setOtorgarProyecto(existe);
        
    },[]);

    const onFinish = (values) => {
        console.log('Success:', values);

        otorgar_permiso_personal_area(values).then(({data, status}) => {
            switch(status){
                case 0:
                  openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Usuarios habilitados','');
                break;
                default: break;
            }
        });
      };

    return(
        <Row>
            {otorgar_proyecto ? 
            
          


            <Col span={20} offset={2}>
                <p>Aquí podrás otorgar el permiso "Proyecto" a los usuarios que pertenecen al área. Con esto ellos podrán crear proyectos.</p>
            <Form
      name="basic"
      initialValues={{
      }}
      onFinish={onFinish}
    >
      <Form.Item
        label="Usuarios"
        name="usuarios"
        rules={[
          {
            required: true,
            message: 'Selecciona por lo menos a un usuario',
          },
        ]}
      >
        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Selecciona a los usuarios"
            defaultValue={[]}
            >
            {personal.map(persona => {
                return(
                    <Option value={persona.id_personal}>{persona.nombre+" "+persona.apellidos}</Option>
                );
            })}
            </Select>
      </Form.Item>

      <Form.Item
      >
        <Button type="primary" htmlType="submit">
          Agregar
        </Button>
      </Form.Item>
    </Form>
            </Col>
            :
            <Redirect to="/usuario" />
          }
        </Row>
    );
}

export default OtorgarPermisoProyecto;
