import React from 'react';
import { Row, Col, notification, Button, Select, Drawer } from 'antd';
import MovimientosProyecto from './MovimientosProyecto';
import RegistrarMovimientoN from './RegistrarMovimientoN';
import { lista_mis_proyectos_con_movimientos } from '../../../utils/Jwt';
import { Redirect, Link } from 'react-router-dom';
import { EditOutlined, PlusCircleOutlined   } from '@ant-design/icons';
import MovimientosN from '../movimiento/MovimientosN';

const { Option } = Select;

const openNotification = (icono,titulo,mensaje) => {
    notification.open({
    message: titulo,
    description:
        mensaje,
    icon: icono,
    });
};


let permiso_proyectos = false;

class Proyecto extends React.Component{

    state = {
        login: false,
        proyectos: [],
        movimientos: [],
        acciones: [],
        folios:[],
        proyecto_elegido:0,
        visible_registrar: false,
        presupuesto: 0,
        costo_real: 0,
        tipo_vista_movimientos:'Folio',
        actualizar:0,
    }

    constructor(props){
        super(props);
        permiso_proyectos = false;

        for(let i=0; i < this.props.permisos_usuario.length; i++){
            if(this.props.permisos_usuario[i].id_permiso === 13){
                permiso_proyectos = true;
                break;
            }
        }

        this.movientosProyecto = this.movientosProyecto.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.agregarMovimiento = this.agregarMovimiento.bind(this);
        this.movimientoGuardado = this.movimientoGuardado.bind(this);
        this.actualizarMovimientos = this.actualizarMovimientos.bind(this);

        this.movientosProyecto();
    }

    onChange(value) {

        this.state.proyectos.map(data => {
            if(data.id_proyecto === value){
                this.setState({presupuesto:data.presupuesto, proyecto_elegido: value, costo_real: data.costo_real});
            }
        });
    }

    onSearch(val) {
        
    }

    actualizarMovimientos(response){
        this.movientosProyecto();
    }

    movientosProyecto(){

        lista_mis_proyectos_con_movimientos().then(data => {
            switch(data.status){
                case 0:
                    this.setState({proyectos: data.data[0], movimientos: data.data[1], acciones: data.data[2], folios:data.data[3] });
                    break;
                default:
                    break;
            }
        });

    }

    agregarMovimiento(){
                    this.setState({
                        visible_registrar: true,
                      });

        
    }

    movimientoGuardado(response){
        switch(response){
            case 1:
                this.setState({tipo_vista_movimientos:'Ingreso'});
                break;
            case 2:case 3: case 5:
                this.setState({tipo_vista_movimientos:'Folio'});
                break;
            case 4:
                this.setState({tipo_vista_movimientos:'Proyectado'});
                break;
            default:
                break;
        }
        this.movientosProyecto();
        this.onClose();
    }

    onClose = () => {
        this.setState({
          visible_registrar: false,
        });
      };

    render(){

        if(!permiso_proyectos){
            return(
              <Redirect to="/usuario" />
            );
        }

        let lista_movimientos;
        let lista_submovimientos = [];

        switch(this.state.tipo_vista_movimientos){
            case 'Ingreso':
                lista_movimientos = this.state.movimientos.filter(movimiento => movimiento.tipo_operacion === 'INGRESO' && movimiento.id_proyecto === this.state.proyecto_elegido && movimiento.especificar_tipo_operacion === 'Ingreso');
                break;
            case 'Folio':
                lista_submovimientos = this.state.movimientos.filter(movimiento => movimiento.tipo_operacion === 'EGRESO' ||  movimiento.tipo_operacion === 'SOLICITUD').map((movimiento,index) => { movimiento.key = index; return movimiento });
                lista_movimientos = this.state.folios.filter(folio => folio.id_proyecto === this.state.proyecto_elegido).map((folio,index) => { folio.key = index; return folio });
                break;
            case 'Proyectado':
                lista_movimientos = this.state.movimientos.filter(movimiento => movimiento.tipo_operacion === 'PROYECTADO' && movimiento.id_proyecto === this.state.proyecto_elegido);
                break;
            case 'Solicitud':
                lista_movimientos = this.state.movimientos.filter(movimiento => movimiento.tipo_operacion === 'SOLICITUD' && movimiento.id_proyecto === this.state.proyecto_elegido);
                break;
            default:
                lista_movimientos = this.state.movimientos.filter(movimiento => movimiento.tipo_operacion !== 'EGRESO' && movimiento.id_proyecto === this.state.proyecto_elegido);
                break;
        }

        

        return(
            <Row>
                <Col span={10} offset={6}>
                    <h3>Seleccionar proyecto</h3>
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Selecciona un proyecto"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    onSearch={this.onSearch}
                >
                    { this.state.proyectos.map( proyecto => {
                        return(
                        <Option value={proyecto.id_proyecto}>{proyecto.nombre}</Option>
                        )
                    })}
                </Select>
                </Col>
               
                    { this.state.proyecto_elegido > 0 &&
                     <Col span={22} offset={1}>
                         <p style={{textAlign:'right'}}>
                            <Link to={`/usuario/registrar-proyecto/${this.state.proyecto_elegido}`}><Button type="primary"><EditOutlined /> Editar Proyecto</Button> </Link>
                            <Button type="primary" onClick={this.agregarMovimiento}><PlusCircleOutlined /> Movimiento</Button>
                         </p>
                        <MovimientosProyecto id_proyecto={this.state.proyecto_elegido} movimientos={this.state.movimientos} presupuesto={ this.state.presupuesto } costo_real ={ this.state.costo_real } onResponse={ this.actualizarMovimientos } />
                        <br />
                        <span>Elige el tipo de movimiento</span>
                        <Select defaultValue={this.state.tipo_vista_movimientos} onChange={val => this.setState({tipo_vista_movimientos: val})} style={{width:'100%'}}>
                            <Option value={"Folio"}>Folio</Option>
                            <Option value={"Proyectado"}>Proyecciones</Option>
                        </Select>
                        <MovimientosN onResponse={this.movimientoGuardado} id_proyecto={this.state.proyecto_elegido} lista_movimientos={lista_movimientos} lista_submovimientos={lista_submovimientos} tipo_vista_movimientos={this.state.tipo_vista_movimientos} editar_movimientos={false} aprobar={false} habilitar_deposito={false} />
                    </Col>
                    }
        <Drawer
          title="Registrar Movimiento"
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible_registrar}
          width='600'
        >
            {this.state.visible_registrar &&
                <RegistrarMovimientoN onResponse={this.movimientoGuardado} id_proyecto={this.state.proyecto_elegido} ingreso={false} proyectado={true} comprobar={false} actualizar_operacion={0} id_actualizar={0} />
            }
        </Drawer>
                    
                
            </Row>
        );
    }
    

}

export default Proyecto;