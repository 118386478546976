import React from 'react';
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Button,
    notification,InputNumber, Checkbox
  } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Redirect } from 'react-router-dom';
import { registrar_proyecto, lista_personal, get_proyecto } from '../../../utils/Jwt';
import { MinusOutlined, PlusOutlined, SmileOutlined, DeleteOutlined, FrownOutlined  } from '@ant-design/icons';
  
  const { Option } = Select;

  const openNotification = (icono, titulo,mensaje) => {
    notification.open({
    message: titulo,
    description:
        mensaje,
    icon: icono,
    });
};

let permiso_proyectos = false;
  
class RegistrarProyecto extends React.Component {
    
  state = {
        ir_lista_proyectos: false,
        numero_objetivos_especificos: [],
        personal: [],
        datos_proyecto: [],
        contenedor_objetivos_especificos:[],
        proyecto_default:false
    };

    constructor(props){
        super(props);
       
        for(let i=0; i < this.props.permisos_usuario.length; i++){
            if(this.props.permisos_usuario[i].id_permiso === 13){
                permiso_proyectos = true;
                break;
            }
        }

        if(props.proyecto !== undefined && props.proyecto !== 0){
          get_proyecto(props.proyecto).then(({data,status}) => {

            switch(status){
              case 0:

                for(let i=0; i < data[1].length; i++){

                  this.agregarObjetivo(data[1][i].id_objetivo_especifico, data[1][i].objetivo_especifico, []);

                  for(let j=0; j < data[2].length; j++){
                    if(data[1][i].id_objetivo_especifico === data[2][j].objetivo_especifico_id){
                      this.agregarAccion(i, data[2][j].id_accion, data[2][j].accion);
                    }
                  }
                }

                this.setState({
                  datos_proyecto: data
                },()=>{ this.forceUpdate();});
              break;
              case 1:
                openNotification(<FrownOutlined style={{color:'orange'}} />,'Proyecto no encontrado',data);
                break;
              default:
                openNotification(<FrownOutlined style={{color:'red'}} />,'Error de conexión','');
              break;
            }
          });
        }

        this.agregarObjetivo = this.agregarObjetivo.bind(this);
        this.eliminarObjetivo = this.eliminarObjetivo.bind(this);
        this.agregarAccion = this.agregarAccion.bind(this);
        this.eliminarAccion = this.eliminarAccion.bind(this);
        this.agregarPersonal = this.agregarPersonal.bind(this);
        this.cambiarValorObjetivoEspecifico= this.cambiarValorObjetivoEspecifico.bind(this);
        this.cambiarValorAccion = this.cambiarValorAccion.bind(this);
        this.agregarPersonal();

    }

    handleSubmit = values => {
        

          if(this.state.datos_proyecto[0]){
            values.id_proyecto = this.state.datos_proyecto[0].id_proyecto;
          }else{
            values.id_proyecto = 0;
          }

          values.objetivos_especificos = this.state.contenedor_objetivos_especificos;

            registrar_proyecto(values).then( data => {
              switch(data.status){
                    case 0:
                      if(this.props.proyecto !== undefined && this.props.proyecto !== 0){
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Proyecto Editado','');
                      }else{
                        openNotification(<SmileOutlined style={{color:'#73fc03'}} />,'Proyecto Registrado','');
                      }
                        this.setState({ir_lista_proyectos: true});
                        break;
                    default:
                      openNotification(<FrownOutlined style={{color:'red'}} />,'Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                        this.setState({login:true});
                        break;
                }
            });
            
    };

    agregarPersonal(){
      lista_personal().then(({data})=>{
        this.setState({personal: data});
      });
    }

    agregarObjetivo(id_ = 0, objetivo_especifico = '', acciones = []){
      let contendor_objetivos = this.state.contenedor_objetivos_especificos;
      let nuevo_objetivo_espeficico = {
        index: contendor_objetivos.length,
        id: id_,
        activo: true,
        objetivo_especifico: objetivo_especifico,
        acciones: acciones
      };
      contendor_objetivos.push(nuevo_objetivo_espeficico);

      this.setState({
        contenedor_objetivos_especificos: contendor_objetivos
      });
    }

    eliminarObjetivo(index){

      let contendor_objetivos = this.state.contenedor_objetivos_especificos;

      this.setState({
        contenedor_objetivos_especificos: contendor_objetivos.map(objetivo => {if(objetivo.index === index){objetivo.activo = false;} return objetivo})
      });

    }

    cambiarValorObjetivoEspecifico = (index, valor) => {

      let contendor_objetivos = this.state.contenedor_objetivos_especificos;

      this.setState({
        contenedor_objetivos_especificos: contendor_objetivos.map(objetivo => {if(objetivo.index === index){objetivo.objetivo_especifico = valor.target.value;} return objetivo})
      });

    }

    agregarAccion(index_objetivo, id_ = 0, accion = ''){
      let contendor_objetivos = this.state.contenedor_objetivos_especificos;

      let nueva_accion = {
        index: contendor_objetivos.filter(obj => obj.index === index_objetivo)[0].acciones.length,
        id: id_,
        activo: true,
        accion: accion
      };

      contendor_objetivos.filter(obj => obj.index === index_objetivo)[0].acciones.push(nueva_accion);

      this.setState({
        contenedor_objetivos_especificos: contendor_objetivos
      });
    }

    eliminarAccion(index_objetivo, index_accion){
      
      let contendor_objetivos = this.state.contenedor_objetivos_especificos;

      this.setState({
        contenedor_objetivos_especificos: contendor_objetivos.map(objetivo => {
          
          if(objetivo.index === index_objetivo){
            objetivo.acciones.map(accion => {if(accion.index === index_accion) accion.activo = false; return accion});
          }
          
          return objetivo
        })
      });

    }

    cambiarValorAccion = (index_objetivo, index_accion, valor) => {

      let contendor_objetivos = this.state.contenedor_objetivos_especificos;

      this.setState({
        contenedor_objetivos_especificos: contendor_objetivos.map(objetivo => {
          
          if(objetivo.index === index_objetivo){
            objetivo.acciones.map(accion => {if(accion.index === index_accion) accion.accion = valor.target.value; return accion});
          }
          
          return objetivo
        })
      });

    }

    render() {

      if(!permiso_proyectos){
        return(
          <Redirect to="/usuario" />
        );
      }

      if(this.state.ir_lista_proyectos){
          return(
              <Redirect to="/usuario/mis-proyectos" />
          );
      }

    //const { getFieldDecorator, getFieldValue } = this.props.form;

    const pintar_objetivos = this.state.contenedor_objetivos_especificos.filter(objetivo => objetivo.activo && objetivo.objetivo_especifico !== 'N/A').map(objetivo_esp => {

      const acciones = objetivo_esp.acciones.filter(accion => accion.activo).map(accion => {

        return(
          <Row>
          <Col span={20} offset={2}>
            <Form.Item label="Acción" 
              name={`acciones${objetivo_esp.index}[${accion.index}]`}
              rules={[{ required: true, message: 'Registra un importe' }]}
              initialValue={accion.accion}
              >
              <Input onChange={(valor)=>{this.cambiarValorAccion(objetivo_esp.index, accion.index, valor);}} />
            </Form.Item>

          </Col>
          <Col span={2}>
            <Button type="danger" onClick={()=>{this.eliminarAccion(objetivo_esp.index, accion.index);}}>
            <DeleteOutlined />
            </Button>
          </Col>
        </Row>
        );
      });

      return(
        <Row>
          <Col span={22}>
            <Form.Item label="Objetivo especifico"
            name={`objetivo_especifico[${objetivo_esp.index}]`}
            rules={[{ required: true, message: 'Ingresa el objetivo especifico' }]}
            initialValue={objetivo_esp.objetivo_especifico}
            >
              <Input onChange={(valor)=>{this.cambiarValorObjetivoEspecifico(objetivo_esp.index, valor);}} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button type="primary" style={{backgroundColor:'purple', color:'white'}} onClick={()=>{this.eliminarObjetivo(objetivo_esp.index);}}>
            <DeleteOutlined />
            </Button>
            </Col>
            <Col span={24}>
              {acciones}
            </Col>
            <Col span={8} offset={8} >
              <Button type="dashed" onClick={()=>{this.agregarAccion(objetivo_esp.index)}} style={{ width: '100%' }}>
              <PlusOutlined /> Acción
              </Button>
              <br /><br />
            </Col>
        </Row>
      );



    });
  
      return (
        <Row>
            <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:18, offset:3}} lg={{span:16, offset:4}} >
              <h3>
                {this.props.proyecto !== undefined && this.props.proyecto !== 0 ?
                    <>Editar Proyecto</>
                    :
                    <>Registrar Proyecto</>
                }
                </h3>

                {this.props.proyecto !== undefined && this.props.proyecto !== 0 ?
    this.state.datos_proyecto[0] &&

    <Form onFinish={this.handleSubmit}
            initialValues={{
            }}
            layout="vertical"
            >
            <Row>
              <Col span={24}>
              
              </Col>

              <Col span={24}>
              <Form.Item label="Nombre"
              name={`nombre`}
              rules={[{ required: true, message: 'Ingresa el nombre del proyecto' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].nombre: ''}
              >
           <Input />
          </Form.Item>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:11, offset:0}}>
              <Form.Item label="Estado"
              name={`estado`}
              rules={[{ required: true, message: 'Ingresa el estado' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].estado : ''}
              >
           <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Elige un estado"
                optionFilterProp="children"
                >
                  <Option value="Aguascalientes">Aguascalientes</Option>
                  <Option value="Baja California">Baja California</Option>
                  <Option value="Baja California Sur">Baja California Sur</Option>
                  <Option value="Campeche">Campeche</Option>
                  <Option value="Coahuila de Zaragoza">Coahuila de Zaragoza</Option>
                  <Option value="Colima">Colima</Option>
                  <Option value="Chiapas">Chiapas</Option>
                  <Option value="Chihuahua">Chihuahua</Option>
                  <Option value="Distrito Federal">Distrito Federal</Option>
                  <Option value="Durango">Durango</Option>
                  <Option value="Guanajuato">Guanajuato</Option>
                  <Option value="Guerrero">Guerrero</Option>
                  <Option value="Hidalgo">Hidalgo</Option>
                  <Option value="Jalisco">Jalisco</Option>
                  <Option value="México">México</Option>
                  <Option value="Michoacán de Ocampo">Michoacán de Ocampo</Option>
                  <Option value="Morelos">Morelos</Option>
                  <Option value="Nayarit">Nayarit</Option>
                  <Option value="Nuevo León">Nuevo León</Option>
                  <Option value="Oaxaca">Oaxaca</Option>
                  <Option value="Puebla">Puebla</Option>
                  <Option value="Querétaro">Querétaro</Option>
                  <Option value="Quintana Roo">Quintana Roo</Option>
                  <Option value="San Luis Potosí">San Luis Potosí</Option>
                  <Option value="Sinaloa">Sinaloa</Option>
                  <Option value="Sonora">Sonora</Option>
                  <Option value="Tabasco">Tabasco</Option>
                  <Option value="Tamaulipas">Tamaulipas</Option>
                  <Option value="Tlaxcala">Tlaxcala</Option>
                  <Option value="Veracruz de Ignacio de la Llave">Veracruz de Ignacio de la Llave</Option>
                  <Option value="Yucatán">Yucatán</Option>
                  <Option value="Zacatecas">Zacatecas</Option>
                </Select>
          </Form.Item>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:11, offset:2}}>
              <Form.Item label="Presupuesto con iva"
              name={`presupuesto`}
              rules={[{ required: true, message: 'Ingresa el presupuesto' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].presupuesto : 0}
              >
            <InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{width:'100%'}} />
          </Form.Item>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:11, offset:0}}>
              <Form.Item label="Municipio" 
              name={`municipio`}
              rules={[{ required: false, message: 'Ingresa el municipio' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].municipio : ''}
              >
            <Input />
          </Form.Item>
              </Col>

              <Col xs={{span:20, offset:2}} md={{span:11, offset:2}}>
              <Form.Item label="Costo real sin iva"
              name={`costo_real`}
              rules={[{ required: false, message: 'Ingresa el costo real' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].costo_real : 0}
              >
            <InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{width:'100%'}} />
          </Form.Item>
              </Col>
              <Col span={24}>
              <Form.Item label="Objetivo"
              name={`objetivo`}
              rules={[{ required: false, message: 'Ingresa el objetivo' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].objetivo : ''}
              >
                <TextArea />
              </Form.Item>
              </Col>

            


<Col xs={{span:20, offset:2}} sm={{span:20, offset:2}}>
  <p>Objetivos especificos</p>
</Col>

<Col span={24}>
{pintar_objetivos}
</Col>


<Col></Col>


<Col xs={{span:20, offset:2}} sm={{span:16, offset:4}} md={{span:8, offset:8}}>

          <Button type="dashed" onClick={()=>{this.agregarObjetivo()}} style={{ width: '100%', color:'purple', backgroundColor:'white' }}>
          <PlusOutlined /> Objetivo especifico
          </Button>
</Col>
<Col span={24}>
  <br /><br />
  
    <Form.Item label="Permiso a:"
  name={`personal`}
  rules={[{ required: false, message: '' }]}
  initialValue={this.state.datos_proyecto[3] ? this.state.datos_proyecto[3].map(reg => reg.personal_id) : []}
>
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Agrega acceso a tu proyecto"
                            allowClear={true}
                            autoClearSearchValue={true}
                        >
                            {
                              this.state.personal.map(persona => {
                              return(
                                <Option value={persona.id_personal}>
                                  {
                                    `${persona.nombre} ${persona.apellidos}`
                                  }
                                </Option>
                              );
                            })
                            
                            }
                        </Select>

                    </Form.Item>
  
  


</Col>
<Col span={24}>
<Form.Item style={{textAlign:'center'}}>
            <Button type="primary" htmlType="submit">
              Editar
            </Button>
          </Form.Item>
</Col>

            </Row>

        </Form>
    :

    <Form onFinish={this.handleSubmit}
                            layout="vertical"
            initialValues={{
            }}
            >
            <Row>
              <Col span={24} style={{textAlign:'right'}}>
                <Form.Item label={null}
                name={`proyecto_default`}
                >
                  <Checkbox onChange={val => {this.setState({proyecto_default :val.target.checked});}}>¿Proyecto default?</Checkbox>
                </Form.Item>
              </Col>
              <Col span={24} offset={0}>
              <Form.Item label="Nombre"
              name={`nombre`}
              rules={[{ required: true, message: 'Ingresa el nombre del proyecto' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].nombre: ''}
              >
           <Input />
          </Form.Item>
              </Col>
              {!this.state.proyecto_default &&
                <>
                  <Col xs={{span:20, offset:2}} md={{span:11, offset:0}}>
              <Form.Item label="Estado"
              name={`estado`}
              rules={[{ required: true, message: 'Ingresa el estado' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].estado : ''}
              >
                <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Elige un estado"
                optionFilterProp="children"
                >
                  <Option value="Aguascalientes">Aguascalientes</Option>
                  <Option value="Baja California">Baja California</Option>
                  <Option value="Baja California Sur">Baja California Sur</Option>
                  <Option value="Campeche">Campeche</Option>
                  <Option value="Coahuila de Zaragoza">Coahuila de Zaragoza</Option>
                  <Option value="Colima">Colima</Option>
                  <Option value="Chiapas">Chiapas</Option>
                  <Option value="Chihuahua">Chihuahua</Option>
                  <Option value="Distrito Federal">Distrito Federal</Option>
                  <Option value="Durango">Durango</Option>
                  <Option value="Guanajuato">Guanajuato</Option>
                  <Option value="Guerrero">Guerrero</Option>
                  <Option value="Hidalgo">Hidalgo</Option>
                  <Option value="Jalisco">Jalisco</Option>
                  <Option value="México">México</Option>
                  <Option value="Michoacán de Ocampo">Michoacán de Ocampo</Option>
                  <Option value="Morelos">Morelos</Option>
                  <Option value="Nayarit">Nayarit</Option>
                  <Option value="Nuevo León">Nuevo León</Option>
                  <Option value="Oaxaca">Oaxaca</Option>
                  <Option value="Puebla">Puebla</Option>
                  <Option value="Querétaro">Querétaro</Option>
                  <Option value="Quintana Roo">Quintana Roo</Option>
                  <Option value="San Luis Potosí">San Luis Potosí</Option>
                  <Option value="Sinaloa">Sinaloa</Option>
                  <Option value="Sonora">Sonora</Option>
                  <Option value="Tabasco">Tabasco</Option>
                  <Option value="Tamaulipas">Tamaulipas</Option>
                  <Option value="Tlaxcala">Tlaxcala</Option>
                  <Option value="Veracruz de Ignacio de la Llave">Veracruz de Ignacio de la Llave</Option>
                  <Option value="Yucatán">Yucatán</Option>
                  <Option value="Zacatecas">Zacatecas</Option>
                </Select>
          </Form.Item>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:11, offset:2}}>
              <Form.Item label="Presupuesto con iva"
              name={`presupuesto`}
              rules={[{ required: true, message: 'Ingresa el presupuesto' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].presupuesto : null}
              >
            <InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{width:'100%'}} />
          </Form.Item>
              </Col>
              <Col xs={{span:20, offset:2}} md={{span:11, offset:0}}>
              <Form.Item label="Municipio" 
              name={`municipio`}
              rules={[{ required: false, message: 'Ingresa el municipio' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].municipio : ''}
              >
                <Input />
          </Form.Item>
              </Col>

              <Col xs={{span:20, offset:2}} md={{span:11, offset:2}}>
              <Form.Item label="Costo real sin iva"
              name={`costo_real`}
              rules={[{ required: false, message: 'Ingresa el costo real' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].costo_real : null}
              >
            <InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{width:'100%'}} />
          </Form.Item>
              </Col>
              <Col span={24} offset={0}>
              <Form.Item label="Observaciones"
              name={`objetivo`}
              rules={[{ required: false, message: 'Ingresa el objetivo' }]}
              initialValue={this.state.datos_proyecto[0] ? this.state.datos_proyecto[0].objetivo : ''}
              >
                <TextArea />
              </Form.Item>
              </Col>

            


<Col xs={{span:24, offset:0}}>
  <p>Objetivos especificos</p>
</Col>

<Col span={24}>
{pintar_objetivos}
</Col>


<Col></Col>
<Col xs={{span:20, offset:2}} sm={{span:16, offset:4}} md={{span:8, offset:8}}>

          <Button type="dashed" onClick={()=>{this.agregarObjetivo()}} style={{ width: '100%', color:'purple', backgroundColor:'white' }}>
          <PlusOutlined /> Objetivo especifico
          </Button>
</Col>
                </>
              }
              
<Col span={24}>
  {!this.state.proyecto_default &&
  <>
    <br /><br />
  </>
  }
  
  
    <Form.Item label="Permiso a:"
  name={`personal`}
  rules={[{ required: false, message: '' }]}
  initialValue={this.state.datos_proyecto[3] ? this.state.datos_proyecto[3].map(reg => reg.personal_id) : []}
>
<Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Agrega acceso a tu proyecto"
                            allowClear={true}
                            autoClearSearchValue={true}
                        >
                            {
                              this.state.personal.map(persona => {
                              return(
                                <Option value={persona.id_personal}>
                                  {
                                    `${persona.nombre} ${persona.apellidos}`
                                  }
                                </Option>
                              );
                            })
                            
                            }
                        </Select>

                    </Form.Item>
  
  


</Col>
<Col span={24}>
<Form.Item style={{textAlign:'center'}}>
            <Button type="primary" htmlType="submit">
              Registrar
            </Button>
          </Form.Item>
</Col>

            </Row>

        </Form>

                }


            
      
            </Col>
        </Row>


       
      );
    }
  }
  
  //const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrarProyecto);
  
  export default RegistrarProyecto;