import React from 'react';
import { Row, Col } from 'antd';

export const Footer = () => {
    return(
        <Col span={24} style={{textAlign:'center'}}>
            <br /><br />
            <h6>Maf & Asociados</h6>
            <br />
        </Col>
    );
}