import axios from 'axios';
export const SERVER_URL = "https://APIS.ADMIN.MAFYASOCIADOS.COM";
//export const SERVER_URL = "http://localhost:8000";

const fallo_token = {
    success: false,
    data:{
        status: 5
    }
}

/*const omitir_token = {
    success: true,
    data:{
        status: 10
    }
}*/

export const login = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/login`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data);
        if(response.data.success){
            let jwt = response.data.token;
            localStorage.setItem("token", jwt);
        }
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const whoami = async() => {

    const LOGIN_ENDPOINT = `${SERVER_URL}/api/whoami`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}


// Perfil
export const lista_personal = async() => {

    const LOGIN_ENDPOINT = `${SERVER_URL}/api/personal`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const personal_permisos = async(id_personal) => {

    const LOGIN_ENDPOINT = `${SERVER_URL}/api/perfil/${id_personal}`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const personal_area = async() => {

    const LOGIN_ENDPOINT = `${SERVER_URL}/api/personal-area`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const otorgar_permiso_personal_area = async(data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/otorgar-permiso-personal-area`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const eliminar_personal_permiso = async(id_personal_permiso) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/personal-permisos/${id_personal_permiso}`;
    try {
        let response = await axios.delete(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
} 

export const editar_personal = async(data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/perfil/${data.id}`;
    try {
        let response = await axios.put(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
} 

//Menu Usuarios

export const datos_usuarios = async() => {

    const LOGIN_ENDPOINT = `${SERVER_URL}/api/perfil`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const editar_datos_usuario = async(data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/perfil-editar/`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const agregar_personal_permisos = async(data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/personal-permisos`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const permisos = async() => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/permisos`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const eliminar_personal = async(id) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/perfil/${id}`;
    try {
        let response = await axios.delete(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const registrar_personal = async(data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/perfil`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

// Menu Movimientos

export const movimientos = async () => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/movimientos`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const agregar_movimientos = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/movimientos`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const get_movimientos_editar = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/get-editar-movimientos`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const lista_tickets_reembolsos = async(id) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/ticket-reembolso/${id}`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const mis_movimientos = async () => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/mis-movimientos`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const editar_movimiento = async(data, id) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/movimientos/${id}`;
    try {
        let response = await axios.put(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    } 
}

export const movimiento = async (id) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/movimientos/${id}`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    } 
}

export const eliminar_movimiento = async (id) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/movimientos/${id}`;
    try {
        let response = await axios.delete(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    } 
}

export const eliminar_folio = async (id) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/notas/${id}`;
    try {
        let response = await axios.delete(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    } 
}

export const aprobar_folio = async (data) =>{
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/aprobar-folio`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT,data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    } 
}

export const datos_imprimir = async (data) =>{
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/datos-imprimir`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT,data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    } 
}

export const catalogos = async() => {

    const LOGIN_ENDPOINT = `${SERVER_URL}/api/catalogos`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const catalogos_reporte = async() => {
    
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/catalogos-reporte`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const registrar_movimiento = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/movimientos`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const num_movimientos_pendientes = async () => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/movimientos-pendientes`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const mis_notas = async () => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/mis-notas`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const notas = async () => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/notas`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

//Menú Reportes

export const consultas = async () => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/consulta-guardada`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT,{ headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    }catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const guardar_consulta = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/consulta-guardada`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    }catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

//Menu Catalogos

export const agregar_al_catalogo = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/catalogos`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const borrar_al_catalogo = async (data) => {
    
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/catalogo-borrar`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

//Sección Reportes

export const reporte_tabla = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/reporte-tabla`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const reporte_linea_tiempo = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/reporte-linea-tiempo`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

// Proyectos

export const lista_mis_proyectos_con_movimientos = async () => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/proyectos`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const registrar_proyecto = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/proyectos`;
    try {
        let response = await axios.post(LOGIN_ENDPOINT, data, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

export const get_proyecto = async (proyecto) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}/api/proyectos/${proyecto}`;
    try {
        let response = await axios.get(LOGIN_ENDPOINT, { headers: {"Authorization" : `Bearer ${localStorage.token}`} });
        return response.data;
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}

