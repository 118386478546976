import React from 'react';
import { Form, Input, Radio, Tooltip, notification, Cascader, Select, Row, Col, Checkbox, Button, Table, DatePicker, Modal, Card } from 'antd';
import ReactExport from "react-export-excel";
import Excel from './archivos/Excel';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from "react-component-export-image";
import { Redirect } from 'react-router-dom';
import { StockOutlined,FileImageOutlined   } from '@ant-design/icons';

import { consultas, catalogos, reporte_tabla, reporte_linea_tiempo } from '../../../utils/Jwt';
import Barras from './graficas/Barras';
import Linear from './graficas/Linear';

const { Option } = Select;

const colors = 
        ['#ff6960','#00bfff','#90ee90','#964b00','#e3b1ff','#2a1506','#b1aba1','#ffab82','#add8e6','#8b4513'];

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

const fecha_linea_de_tiempo = '01/01/2000';
const fecha_linea_de_tiempo_inicio = '01/01/2019';
const fecha_linea_de_tiempo_final = '31/12/2020';


const dateFormat = 'DD/MM/YYYY';

let datos_operaciones = [], datos_campos = [], datos_where = [], datos_catalogo= [], datos_linea_de_tiempo = [fecha_linea_de_tiempo_inicio,fecha_linea_de_tiempo_final,1], datos_consulta = [];
let componentes_operaciones, componentes_campos, componentes_where, componentes_tabla;
let index_operacion=0, index_where=0, index_row=1, campos_excel= [], datos_guardar = {};
let totales_operaciones = [], datos_grafica = [], datos_ingreso_egreso = [];
let componentes_consultas_guardadas, datos_consultas_guardadas = [];
let nombre_campos = [], permiso_consultas_guardadas= false;

const campos = [
    ['conceptos.nombre','Concepto',{title: 'Concepto', dataIndex: 'concepto', key: 'concepto'}],
    ['empresas.nombre','Empresas',{title: 'Empresa', dataIndex: 'empresa', key: 'empresa'}],
    ['forma_de_pagos.tipo','Formas de Pago',{title: 'Forma de Pago', dataIndex: 'forma_de_pago', key: 'forma_de_pago'}],
    ['gasto_conceptos.nombre','Gasto Concepto',{title: 'Gasto Concepto', dataIndex: 'gasto_concepto', key: 'gasto_concepto'}],
    ['personals.nombre','Personal',{title: 'Personal', dataIndex: 'personal', key: 'personal'}],
    ['proyectos.nombre','Proyecto',{title: 'Proyecto', dataIndex: 'proyecto', key: 'proyecto'}],
    ['movimientos.fecha','Fecha',{title: 'Fecha', dataIndex: 'fecha', key: 'fecha'}]
    ,['movimientos.factura','Factura',{title: 'Factura', dataIndex: 'factura', key: 'factura'}],
    ['movimientos.status','Status',{title: 'Status', dataIndex: 'status', key: 'status'}]
];

let campos_header_table = [], header_campos_operaciones = [];

class ConsultasGuardadas extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            login: false,
            num_operaciones:0,
            num_where: 0,
            cambiar_campo: false,
            datos_reporte: [],
            linea_de_tiempo: false,
            tipo_grafica: -1,
            datos_grafica: [],
            nombre_consulta:'',
            num_operacion:0,
            periodo: [],
            modal_guardar_consulta: false,
            datos_consultas_guardadas: [],
            dato_buscado : undefined
        };

        permiso_consultas_guardadas= false;

        this.exportar_imagen = React.createRef();

        this.reporte = this.reporte.bind(this);
        //this.habilitarLineaDelTiempo = this.habilitarLineaDelTiempo.bind(this);
        this.seleccionarGrafica = this.seleccionarGrafica.bind(this);

        for(let i=0; i < this.props.permisos_usuario.length; i++){

            if(this.props.permisos_usuario[i].id_permiso === 11){
                permiso_consultas_guardadas = true;
                break;
            }
        }

        if(permiso_consultas_guardadas){
            this.obtenerConsultas();
        }
        


    }

    openNotification = (icono,color_icono, titulo,mensaje) => {
        notification.open({
        message: titulo,
        description:
            mensaje,
        icon: icono,
        });
    };


    reporte(){

        let datos_reporte = this.state.datos_consultas_guardadas[this.state.dato_buscado];

        if(datos_reporte['linea_de_tiempo'].length === 3){
            reporte_linea_tiempo(datos_reporte).then(data => {

                switch(data.status){
                    case 0:
                        this.setState({
                            datos_reporte: data.data,
                            periodo: datos_linea_de_tiempo
                        }, () => {
                            datos_operaciones = this.state.datos_consultas_guardadas[this.state.dato_buscado]['operacion'];
                        });
                        this.ingresoMenosEgreso();
                        this.periodoTiempo();
                        this.pintarTabla(1);
                    break;
                    case 5:
                        this.openNotification('frown','red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                        this.setState({login:true});
                    break;
                    default:
                        this.openNotification('frown','orange','Sesión Expirada','Vuele a loguearte');
                        this.setState({login:true});
                    break;
                }
            });
        }else{

            reporte_tabla(datos_reporte).then(data => {
                switch(data.status){
                case 0:
                    this.setState({
                    datos_reporte: data.data
                    }, () => {
                        datos_operaciones = this.state.datos_consultas_guardadas[this.state.dato_buscado]['operacion'];
                    });
                    this.ingresoMenosEgreso();
                    this.pintarTabla(0);
                break;
                case 5:
                    this.openNotification('frown','red','Error de Conexión','Problemas para conectarse, inténtalo más tarde');
                    this.setState({login:true});
                break;
                default:
                    this.openNotification('frown','orange','Sesión Expirada','Vuele a loguearte');
                    this.setState({login:true});
                break;
                }
            });
        }
    }

    ingresoMenosEgreso(){

        let num_grafica=0;
        datos_consulta = [];
        nombre_campos = [];

        for(let campo in this.state.datos_reporte[0]){
            if(campo !== 'tipo_movimiento' && campo !== 'operacion'){
                nombre_campos.push(campo);
            }
        }

        let comparar_obj = this.state.datos_reporte[0];

      let obj_row = {};
      obj_row['ingreso'] = 0;
      obj_row['egreso'] = 0;
      obj_row['proyectado'] = 0;
      obj_row['ingreso-egreso'] = 0;
      obj_row['ingreso-proyectado'] = 0;
      obj_row['proyectado-egreso'] = 0;


      console.log('los campos: ');
      console.log(nombre_campos);

      for(let i=0; i < this.state.datos_reporte.length; i++){
        
        let campos_iguales = true;

      
          for(let j=0; j < nombre_campos.length; j++){
            if(this.state.datos_reporte[i][nombre_campos[j]] === comparar_obj[nombre_campos[j]]){
              campos_iguales = true;
            }else{
              campos_iguales = false;
              comparar_obj = this.state.datos_reporte[i];
              break;
            }
          }

        if(!campos_iguales){

          obj_row['ingreso-egreso'] = obj_row['ingreso'] - obj_row['egreso'];
          obj_row['ingreso-proyectado'] = obj_row['ingreso'] - obj_row['proyectado'];
          obj_row['proyectado-egreso'] = obj_row['proyectado'] - obj_row['egreso'];

          for(let j=0; j < nombre_campos.length; j++){
            obj_row[nombre_campos[j]] = this.state.datos_reporte[i-1][nombre_campos[j]];
          }

          obj_row['color'] = colors[num_grafica++];

          datos_consulta.push(obj_row);

          obj_row = {};
          obj_row['ingreso'] = 0;
          obj_row['egreso'] = 0;
          obj_row['proyectado'] = 0;
          obj_row['ingreso-egreso'] = 0;
          obj_row['ingreso-proyectado'] = 0;
          obj_row['proyectado-egreso'] = 0;
        }

        switch(this.state.datos_reporte[i].tipo_movimiento){
          case 'INGRESO':
            obj_row['ingreso'] = this.state.datos_reporte[i].operacion;
          break;
          case 'EGRESO':
            obj_row['egreso'] = this.state.datos_reporte[i].operacion;
          break;
          case 'PROYECTADO':
            obj_row['proyectado'] = this.state.datos_reporte[i].operacion;
          break;
          default:
          break;
        }

      }

      obj_row['ingreso-egreso'] = obj_row['ingreso'] - obj_row['egreso'];
          obj_row['ingreso-proyectado'] = obj_row['ingreso'] - obj_row['proyectado'];
          obj_row['proyectado-egreso'] = obj_row['proyectado'] - obj_row['egreso'];

          for(let j=0; j < nombre_campos.length; j++){
            obj_row[nombre_campos[j]] = this.state.datos_reporte[this.state.datos_reporte.length-1][nombre_campos[j]];
          }

          obj_row['color'] = colors[num_grafica++];

          datos_consulta.push(obj_row);

    }

    periodoTiempo(){

        let datos_para_grafica = [];
        let datos_para_tabla = []
        let nombre_campos = [];

        for(let campo in datos_consulta[0]){
            if(campo !== 'ingreso' && campo !== 'egreso' && campo !== 'ingreso-egreso'
                && campo !== 'anio' && campo !== 'mes' && campo !== 'color'){
                nombre_campos.push(campo);
            }
        }

        let datos_con_intervalo = [];

        let fecha_intervalo = this.siguienteFechaPeriodo(
                parseInt(datos_linea_de_tiempo[0].split('/')[1], 10),
                parseInt(datos_linea_de_tiempo[0].split('/')[2], 10),
                datos_linea_de_tiempo[2]
        );
        
        let sig_row = {};
        let nuevo_row = true;
        let fechas_ingresadas_por_periodo = 0;
        let operaciones = [0,0,0,fecha_intervalo[0]+' - '+fecha_intervalo[1]];
        let campos_iguales = false;

        let it_datos = 0;
        let num_grafica = 0;

        for(let i=0; i < datos_consulta.length; i++){
            if(nuevo_row){
                sig_row = datos_consulta[i];
                nuevo_row = false;
            }

        for(let j=0; j < nombre_campos.length; j++){
            if(datos_consulta[i][nombre_campos[j]] === sig_row[nombre_campos[j]]){
                campos_iguales = true;
            }else{
                campos_iguales = false;
                break;
            }
        }

        if(campos_iguales){

            if(datos_consulta[i]['anio'] < fecha_intervalo[1]){
                operaciones[0]+= datos_consulta[i]['ingreso'];
                operaciones[1]+= datos_consulta[i]['egreso'];
                operaciones[2]+= datos_consulta[i]['ingreso-egreso'];
                fechas_ingresadas_por_periodo++;
            }else{
                if(datos_consulta[i]['anio'] === fecha_intervalo[1]){

                    if(datos_consulta[i]['mes'] <= fecha_intervalo[0]){
    
                    
                    operaciones[0]+= datos_consulta[i]['ingreso'];
                    operaciones[1]+= datos_consulta[i]['egreso'];
                    operaciones[2]+= datos_consulta[i]['ingreso-egreso'];
                    fechas_ingresadas_por_periodo++;
                    }else{
    
                    if(fechas_ingresadas_por_periodo > 0){
    
                        datos_con_intervalo[it_datos] = Object.assign({} ,sig_row);
                        datos_con_intervalo[it_datos]['ingreso'] = operaciones[0];
                        datos_con_intervalo[it_datos]['egreso'] = operaciones[1];
                        datos_con_intervalo[it_datos]['ingreso-egreso'] = operaciones[2];
                        datos_con_intervalo[it_datos]['anio'] = fecha_intervalo[1];
                        datos_con_intervalo[it_datos]['mes'] = fecha_intervalo[0];

                        switch(datos_con_intervalo[it_datos]['mes']){
                            case 1: datos_con_intervalo[it_datos]['mes_nombre'] = 'Enero'; break;
                            case 2: datos_con_intervalo[it_datos]['mes_nombre'] = 'Febrero'; break;
                            case 3: datos_con_intervalo[it_datos]['mes_nombre'] = 'Marzo'; break;
                            case 4: datos_con_intervalo[it_datos]['mes_nombre'] = 'Abril'; break;
                            case 5: datos_con_intervalo[it_datos]['mes_nombre'] = 'Mayo'; break;
                            case 6: datos_con_intervalo[it_datos]['mes_nombre'] = 'Junio'; break;
                            case 7: datos_con_intervalo[it_datos]['mes_nombre'] = 'Julio'; break;
                            case 8: datos_con_intervalo[it_datos]['mes_nombre'] = 'Agosto'; break;
                            case 9: datos_con_intervalo[it_datos]['mes_nombre'] = 'Septiembre'; break;
                            case 10: datos_con_intervalo[it_datos]['mes_nombre'] = 'Octubre'; break;
                            case 11: datos_con_intervalo[it_datos]['mes_nombre'] = 'Noviembre'; break;
                            case 12: datos_con_intervalo[it_datos]['mes_nombre'] = 'Diciembre'; break;
                            default: break;
                        }

                        datos_con_intervalo[it_datos]['color'] = colors[num_grafica];
                        datos_para_tabla.push(
                        Object.assign({} ,datos_con_intervalo[it_datos])
                        );
                        
                        it_datos++;
        
                        operaciones[0] = 0;
                        operaciones[1] = 0;
                        operaciones[2] = 0;
                        fechas_ingresadas_por_periodo = 0;
        
                    }

                    let fecha_existe = true;
                        while(fecha_existe){

                            if(fecha_intervalo[0] === 12){
                                fecha_intervalo = this.siguienteFechaPeriodo(1, (fecha_intervalo[1]+1) ,datos_linea_de_tiempo[2]);
                            }else{
                                fecha_intervalo = this.siguienteFechaPeriodo((fecha_intervalo[0]+1), fecha_intervalo[1] ,datos_linea_de_tiempo[2]);
                            }

                            if(datos_consulta[i]['anio'] <= fecha_intervalo[1]){
                                
                                if(datos_consulta[i]['mes'] <= fecha_intervalo[0]){
                                
                                fecha_existe = false;
                                }else{
                                if(datos_consulta[i]['anio'] < fecha_intervalo[1]){
                                
                                    fecha_existe = false;
                                }else{
                                }
                                }
                                
                            }else{
                                
                            }
                    }
                    i--;
                    }
                }else{
                    if(fechas_ingresadas_por_periodo > 0){
    
                    datos_con_intervalo[it_datos] = Object.assign({} ,sig_row);;
                    datos_con_intervalo[it_datos]['ingreso'] = operaciones[0];
                    datos_con_intervalo[it_datos]['egreso'] = operaciones[1];
                    datos_con_intervalo[it_datos]['ingreso-egreso'] = operaciones[2];
                    datos_con_intervalo[it_datos]['anio'] = fecha_intervalo[1];
                    datos_con_intervalo[it_datos]['mes'] = fecha_intervalo[0];

                    switch(datos_con_intervalo[it_datos]['mes']){
                        case 1: datos_con_intervalo[it_datos]['mes_nombre'] = 'Enero'; break;
                        case 2: datos_con_intervalo[it_datos]['mes_nombre'] = 'Febrero'; break;
                        case 3: datos_con_intervalo[it_datos]['mes_nombre'] = 'Marzo'; break;
                        case 4: datos_con_intervalo[it_datos]['mes_nombre'] = 'Abril'; break;
                        case 5: datos_con_intervalo[it_datos]['mes_nombre'] = 'Mayo'; break;
                        case 6: datos_con_intervalo[it_datos]['mes_nombre'] = 'Junio'; break;
                        case 7: datos_con_intervalo[it_datos]['mes_nombre'] = 'Julio'; break;
                        case 8: datos_con_intervalo[it_datos]['mes_nombre'] = 'Agosto'; break;
                        case 9: datos_con_intervalo[it_datos]['mes_nombre'] = 'Septiembre'; break;
                        case 10: datos_con_intervalo[it_datos]['mes_nombre'] = 'Octubre'; break;
                        case 11: datos_con_intervalo[it_datos]['mes_nombre'] = 'Noviembre'; break;
                        case 12: datos_con_intervalo[it_datos]['mes_nombre'] = 'Diciembre'; break;
                        default: break;
                    }

                    datos_con_intervalo[it_datos]['color'] = colors[num_grafica];
                    datos_para_tabla.push(
                        Object.assign({} ,datos_con_intervalo[it_datos])
                    );
                    
                    it_datos++;
    
                    operaciones[0] = 0;
                    operaciones[1] = 0;
                    operaciones[2] = 0;
                    fechas_ingresadas_por_periodo = 0;
    
                    }else{
                    
                    }
    
                    let fecha_existe = true;
                    while(fecha_existe){
    
                    if(fecha_intervalo[0] === 12){
                        fecha_intervalo = this.siguienteFechaPeriodo(1, (fecha_intervalo[1]+1) ,datos_linea_de_tiempo[2]);
                    }else{
                        fecha_intervalo = this.siguienteFechaPeriodo((fecha_intervalo[0]+1), fecha_intervalo[1] ,datos_linea_de_tiempo[2]);
                    }
    
                    
                    
                    if(datos_consulta[i]['anio'] <= fecha_intervalo[1]){
                        
                        if(datos_consulta[i]['mes'] <= fecha_intervalo[0]){
                        
                        fecha_existe = false;
                        }else{
                        if(datos_consulta[i]['anio'] < fecha_intervalo[1]){
                        
                            fecha_existe = false;
                        }else{
                        }
                        }
                        
                    }else{
                        
                    }
    
    
                    }
    
                    i--;
    
                }
            }
            }else{

                datos_con_intervalo[it_datos] = Object.assign({} ,sig_row);;
                datos_con_intervalo[it_datos]['ingreso'] = operaciones[0];
                datos_con_intervalo[it_datos]['egreso'] = operaciones[1];
                datos_con_intervalo[it_datos]['ingreso-egreso'] = operaciones[2];
                datos_con_intervalo[it_datos]['anio'] = fecha_intervalo[1];
                datos_con_intervalo[it_datos]['mes'] = fecha_intervalo[0];

                switch(datos_con_intervalo[it_datos]['mes']){
                    case 1: datos_con_intervalo[it_datos]['mes_nombre'] = 'Enero'; break;
                    case 2: datos_con_intervalo[it_datos]['mes_nombre'] = 'Febrero'; break;
                    case 3: datos_con_intervalo[it_datos]['mes_nombre'] = 'Marzo'; break;
                    case 4: datos_con_intervalo[it_datos]['mes_nombre'] = 'Abril'; break;
                    case 5: datos_con_intervalo[it_datos]['mes_nombre'] = 'Mayo'; break;
                    case 6: datos_con_intervalo[it_datos]['mes_nombre'] = 'Junio'; break;
                    case 7: datos_con_intervalo[it_datos]['mes_nombre'] = 'Julio'; break;
                    case 8: datos_con_intervalo[it_datos]['mes_nombre'] = 'Agosto'; break;
                    case 9: datos_con_intervalo[it_datos]['mes_nombre'] = 'Septiembre'; break;
                    case 10: datos_con_intervalo[it_datos]['mes_nombre'] = 'Octubre'; break;
                    case 11: datos_con_intervalo[it_datos]['mes_nombre'] = 'Noviembre'; break;
                    case 12: datos_con_intervalo[it_datos]['mes_nombre'] = 'Diciembre'; break;
                    default: break;
                }

                datos_con_intervalo[it_datos]['color'] = colors[num_grafica];
                datos_para_tabla.push(
                    Object.assign({} ,datos_con_intervalo[it_datos])
                );

                num_grafica++;
                
                it_datos = 0;

                datos_para_grafica.push(
                    datos_con_intervalo
                );

                datos_con_intervalo = [];
                operaciones[0] = 0;
                operaciones[1] = 0;
                operaciones[2] = 0;
                i--;
                nuevo_row = true;
                fechas_ingresadas_por_periodo = 0;
                fecha_intervalo = this.siguienteFechaPeriodo(
                                                parseInt(datos_linea_de_tiempo[0].split('/')[1], 10),
                                                parseInt(datos_linea_de_tiempo[0].split('/')[2], 10),
                                                datos_linea_de_tiempo[2]);
                
            }

                if(i === datos_consulta.length -1){
                    datos_con_intervalo[it_datos] = Object.assign({} ,sig_row);;
                    datos_con_intervalo[it_datos]['ingreso'] = operaciones[0];
                    datos_con_intervalo[it_datos]['egreso'] = operaciones[1];
                    datos_con_intervalo[it_datos]['ingreso-egreso'] = operaciones[2];
                    datos_con_intervalo[it_datos]['anio'] = fecha_intervalo[1];
                    datos_con_intervalo[it_datos]['mes'] = fecha_intervalo[0];

                    switch(datos_con_intervalo[it_datos]['mes']){
                        case 1: datos_con_intervalo[it_datos]['mes_nombre'] = 'Enero'; break;
                        case 2: datos_con_intervalo[it_datos]['mes_nombre'] = 'Febrero'; break;
                        case 3: datos_con_intervalo[it_datos]['mes_nombre'] = 'Marzo'; break;
                        case 4: datos_con_intervalo[it_datos]['mes_nombre'] = 'Abril'; break;
                        case 5: datos_con_intervalo[it_datos]['mes_nombre'] = 'Mayo'; break;
                        case 6: datos_con_intervalo[it_datos]['mes_nombre'] = 'Junio'; break;
                        case 7: datos_con_intervalo[it_datos]['mes_nombre'] = 'Julio'; break;
                        case 8: datos_con_intervalo[it_datos]['mes_nombre'] = 'Agosto'; break;
                        case 9: datos_con_intervalo[it_datos]['mes_nombre'] = 'Septiembre'; break;
                        case 10: datos_con_intervalo[it_datos]['mes_nombre'] = 'Octubre'; break;
                        case 11: datos_con_intervalo[it_datos]['mes_nombre'] = 'Noviembre'; break;
                        case 12: datos_con_intervalo[it_datos]['mes_nombre'] = 'Diciembre'; break;
                        default: break;
                    }
                    
                    datos_con_intervalo[it_datos]['color'] = colors[num_grafica];
                    datos_para_tabla.push(
                        Object.assign({} ,datos_con_intervalo[it_datos])
                    );
                    it_datos = 0;

                    datos_para_grafica.push(
                        datos_con_intervalo
                    );
                    
                    operaciones[0] = 0;
                    operaciones[1] = 0;
                    operaciones[2] = 0;
                }
            }

        datos_consulta = datos_para_tabla;
        this.setState({
            datos_grafica: datos_para_grafica
        });

    }

    siguienteFechaPeriodo(mes, anio, periodo){

        let fecha_siguiente_periodo = [mes,anio];
        for(let i=0; i < (periodo - 1); i++ ){
          
          if(fecha_siguiente_periodo[0] === 12){
            fecha_siguiente_periodo[1]++;
            fecha_siguiente_periodo[0] = 1;
          }else{
            fecha_siguiente_periodo[0]++;
          }
        }
  
  
        return fecha_siguiente_periodo;
    }

    pintarTabla(tipo_grafica){
        header_campos_operaciones = [];
        campos_excel = [];
        index_row= 1;
        totales_operaciones = [];
        totales_operaciones[0] = 0;
        totales_operaciones[1] = 0;
        totales_operaciones[2] = 0;
        totales_operaciones[3] = 0;
        totales_operaciones[4] = 0;
        totales_operaciones[5] = 0;

        console.log(' los datos se ve: ',datos_consulta);

        for(let val_data = 0; val_data < datos_consulta.length; val_data++){
          totales_operaciones[0] += datos_consulta[val_data]['ingreso'];
          totales_operaciones[1] += datos_consulta[val_data]['egreso'];
          totales_operaciones[2] += datos_consulta[val_data]['ingreso-egreso'];
          totales_operaciones[3] += datos_consulta[val_data]['proyectado'];
          totales_operaciones[4] += datos_consulta[val_data]['ingreso-proyectado'];
          totales_operaciones[5] += datos_consulta[val_data]['proyectado-egreso'];
        }

        

        for(let i=0; i < datos_operaciones.length; i++){

          switch(datos_operaciones[i]['valor']){
            case 0:
              campos_excel.push({
                title: 'Ingreso', key: 'ingreso'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={0} />Ingresos &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'ingreso', 
                    key: 'ingreso',
                    render: (text,record) => {
                      return(
                        <span >
                            <StockOutlined  style={{color: record['color'], fontSize:'20px'}} /> {formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 1:
              campos_excel.push({
                title: 'Egreso', key: 'egreso'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={1} />Egresos &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'egreso', 
                    key: 'egreso',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined  style={{color: record['color'], fontSize:'20px'}} /> {formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 2:
              campos_excel.push({
                title: 'Ingreso - Egreso', key: 'ingreso-egreso'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={2} />Ingresos - Egresos &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'ingreso-egreso', 
                    key: 'ingreso-egreso',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined  style={{color: record['color'], fontSize:'20px'}} />{formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 3:
              campos_excel.push({
                title: 'Proyectado', key: 'proyectado'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={3} />Proyectados &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'proyectado', 
                    key: 'proyectado',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined  style={{color: record['color'], fontSize:'20px'}} />{formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 4:
              campos_excel.push({
                title: 'Ingresos - Proyectados', key: 'ingreso-proyectado'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={4} />Ingresos - Proyectados &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'ingreso-proyectado', 
                    key: 'ingreso-proyectado',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined  style={{color: record['color'], fontSize:'20px'}} />{formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            case 5:
              campos_excel.push({
                title: 'Proyectado - Egresos', key: 'proyectado-egreso'
              });
                header_campos_operaciones.push(
                  {
                    title:<span>
                            <input type="radio" onChange={this.seleccionarGrafica} name="grafica" value={5} />Proyectados - Egresos &nbsp;
                            <strong>{formatter.format(totales_operaciones[datos_operaciones[i]['valor']])}</strong>
                          </span>, 
                    dataIndex: 'proyectado-egreso', 
                    key: 'proyectado-egreso',
                    render: (text,record) => {
                      return(
                        <span>
                          <StockOutlined  style={{color: record['color'], fontSize:'20px'}} />{formatter.format(text)}
                        </span>
                      );
                    }
                  });
            break;
            default:
            break;
          }
        }

        if(tipo_grafica === 1){
          campos_excel.push({
            title: 'Año', key: 'anio'
          },{
            title:'Mes', key: 'mes'
          },{
            title:'Mes Nombre', key: 'mes_nombre'
          });
          header_campos_operaciones.push(
            {
              title:'Año', 
              dataIndex: 'anio', 
              key: 'anio',
            },
            {
              title:'Mes', 
              dataIndex: 'mes_nombre', 
              key: 'mes_nombre',
            }
          );
        }

        for(let col=0; col < campos_header_table.length; col++){
          campos_excel.push(campos_header_table[col]);
          header_campos_operaciones.push(
            campos_header_table[col]
          );
        }

        componentes_tabla = 
        <div>
          <div style={{textAlign:'right'}}>
            <Excel datos={datos_consulta} columnas={campos_excel} nombre="tabla" />
            <Button onClick={() => exportComponentAsPNG(this.exportar_imagen)} alt="Exportar Gráfica"><FileImageOutlined  style={{color:'lightblue', fontSize:'30px'}} /></Button>
            
          </div>
            
          
          <Table dataSource={datos_consulta} columns={header_campos_operaciones} />
        </div>
        ;

        this.setState({datos_reporte: datos_consulta, tipo_grafica: tipo_grafica});
        
    }

    seleccionarGrafica(e) {
        this.setState({
            num_operacion: e.target.value,
            datos_reporte: datos_consulta,
        });
    }

    obtenerConsultas(){

        consultas().then( data => {
            switch(data.status){
                case 0:
                    datos_consultas_guardadas = data.data;
                    this.pintarConsultasGuardadas();
                break;
                case 1:
                break;
                case 5:
                break;
                default:
                break;
            }
        });

    }

    pintarConsultasGuardadas(){

        let it_consulta = -1;
        componentes_consultas_guardadas = datos_consultas_guardadas.map(consulta => {
            it_consulta++;
            return(
                <Option key={it_consulta} value={it_consulta}>{ consulta['nombre'] }</Option>
            );
        });

        this.setState({
            datos_consultas_guardadas : datos_consultas_guardadas
        });
    }

    handleSearchConsultasGuardadas = value => {
        if (value) {
            fetch(value, data => this.setState({ data }));
        }else {
            this.setState({ data: [] });
        }
    };
    
    handleChangeConsultasGuardadas = value => {
        this.setState({ dato_buscado : value }, () => {
            this.llenarCamposTabla(this.state.datos_consultas_guardadas[this.state.dato_buscado]['campo']);
            this.reporte();
        });

    };

    llenarCamposTabla(campos_data){

        campos_header_table = [];
        for(let i=0; i < campos_data.length; i++){
            for(let j=0; j < campos.length; j++){
                if(campos_data[i] === campos[j][0]){
                    campos_header_table.push(campos[j][2]);
                    break;
                }
            }
        }
    }


    render(){

        if(!permiso_consultas_guardadas){
            return(
                <Redirect to="/usuario" />
            );
        }

        return(
            <Row>
                <Col span={22} offset={1}>
                    <h3> CONSULTAS </h3>
                <Select
                    showSearch
                    value={this.state.dato_buscado}
                    placeholder={this.props.placeholder}
                    style={this.props.style}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    onChange={this.handleChangeConsultasGuardadas}
                    notFoundContent={null}
                    style={{width:'60%'}}
                >
                    { componentes_consultas_guardadas }
                </Select>
                </Col>
                <Col span={22} offset={1}>
                    {componentes_tabla}
                </Col>

                <Col span={20} offset={2} ref={this.exportar_imagen}>
                    {this.state.tipo_grafica === 0 ?
                    (
                        <Barras nombre={this.state.nombre_consulta} datos={this.state.datos_reporte} operacion={this.state.num_operacion} colores={colors}  />
                    )  
                    :
                    this.state.tipo_grafica === 1 ?
                    (
                        <Linear nombre={this.state.nombre_consulta} datos={this.state.datos_grafica} operacion={this.state.num_operacion} periodo={this.state.periodo} colores={colors} />
                    )
                    :
                    (<div></div>)
                    }
                </Col>

                <Modal
                    title="Guardar Consulta"
                    visible={this.state.modal_guardar_consulta}
                    onOk={this.guardarConsulta}
                    onCancel={this.handleCancel}
                >
                    <Input onChange={ this.cambiarNombreConsultaGuardada } />
                </Modal>
            </Row>
        );
    }
}

export default ConsultasGuardadas;
