import React from 'react';
import { Col } from 'antd';
import { Route, Switch } from 'react-router-dom';

import Usuario from './perfil/Usuario';
import ListaUsuario from './usuario/ListaUsuario';
import RegistrarUsuario from './usuario/RegistrarUsuario';
import ListaMovimiento from './movimiento/ListaMovimiento';
import RegistrarMovimiento from './movimiento/RegistrarMovimiento';
import ReporteMovimiento from './consultas/ReporteMovimiento';
import Reporte from './consultas/Reporte';
import ConsultasGuardadas from './consultas/ConsultasGuardadas';
import ListaCatalogo from './catalogo/ListaCatalogo';
import Proyecto from './proyectos/Proyecto';
import RegistrarProyecto from './proyectos/RegistrarProyecto';
import OtorgarPermisoProyecto from './proyectos/OtorgarPermisoProyecto';

class Contenido extends React.Component{
    
    constructor(props){
        super(props);

        this.state = {
            contenido: true
        }

        console.log(props.permisos_usuario);
    }

    render(){
        return(
            <Col span={24} style={{borderTop:'5px solid gray'}}>
                <br />
                    <Switch>
                        <Route exact path="/usuario" component={Usuario} />

                        <Route exact path="/usuario/lista-usuarios"
                            render= { () => 
                                <ListaUsuario permisos_usuario={this.props.permisos_usuario} />
                                
                            } />
    
                        <Route exact path="/usuario/lista-movimientos" 
                        render= { () => 
                            <ListaMovimiento permisos_usuario={this.props.permisos_usuario} /> 
                            
                        } />
                        
                        <Route exact path="/usuario/reporte-consultas" 
                        render= { () => 
                            <Reporte permisos_usuario={this.props.permisos_usuario} /> 
                        }/>

                        <Route exact path="/usuario/reporte-consultas-guardadas" 
                            render= { () => 
                                <ConsultasGuardadas permisos_usuario={this.props.permisos_usuario} /> 
                                
                            }/> 
    
                        <Route exact path="/usuario/lista-catalogos" 
                            render= { () => 
                                <ListaCatalogo permisos_usuario={this.props.permisos_usuario} /> 
                                
                            } />
                        <Route exact path="/usuario/mis-proyectos" 
                            render= { () => 
                                <Proyecto permisos_usuario={this.props.permisos_usuario} /> 
                                
                            } />

                        <Route exact path="/usuario/registrar-proyecto/:id?"
                            render= { (props) => {
                                
                                return(
                                    <RegistrarProyecto proyecto={props.match.params.id ? props.match.params.id : 0} permisos_usuario={this.props.permisos_usuario} /> 
                                );
                            }
                            } />
                            
                            <Route exact path="/usuario/otorgar-proyectos" 
                            render= { () =>
                                <OtorgarPermisoProyecto permisos_usuario={this.props.permisos_usuario} /> 
                            } />
                    </Switch>
            </Col>
        );
    }
}

export default Contenido;
